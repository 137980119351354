<template>
    <div class="my-3 mx-8 md:mx-24 items-center">
        <div class="mb-12">
            <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-2xl">My Wallet</h1>
        </div>

        <!-- Balances -->
        <div class="flex flex-col md:flex-row gap-x-4">
            <div class="w-full md:w-1/2">
                <div class="bg-gray-100 shadow-md rounded-lg p-6">
                    <h2 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-lg">Wallet Balance</h2>
                    <p class="text-2xl font-bold leading-relaxed tracking-light text-gray-900 md:text-3xl">KSHS. {{ customerDetails.wallet_amount }}</p>
                </div>
            </div>
            <div class="w-full md:w-1/2 mt-4 md:mt-0">
                <div class="bg-gray-100 shadow-md rounded-lg p-6">
                    <h2 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-lg">Total COD Earnings</h2>
                    <p class="text-2xl font-bold leading-relaxed tracking-light text-gray-900 md:text-3xl">KSHS. {{ customerDetails.Total_earned }}</p>
                </div>
            </div>
            <div class="w-full md:w-1/2 mt-4 md:mt-0">
                <div class="bg-gray-100 shadow-md rounded-lg p-6">
                    <h2 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-lg">Total COD Balance</h2>
                    <p class="text-2xl font-bold leading-relaxed tracking-light text-gray-900 md:text-3xl">KSHS. {{ customerDetails.COD_balance }}</p>
                </div>
            </div>
        </div>

        <!-- Withdrawal requests -->
        <div class="mt-8">
            <h2 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-lg">Withdrawal Requests</h2>
            <div class=" relative overflow-x-auto bg-gray-100 shadow-md rounded-lg p-6 mt-4">
                <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                        <tr>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Amount
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-if="customerWithdrawals.length" v-for="(request, index) in customerWithdrawals" :key="request.request_id">
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="text-sm text-gray-900">{{ index + +1 }}</div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                                <div class="text-sm text-gray-900">KSHS. {{ request.amount }}</div>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap">
                                <span class="">
                                    <span v-if="request.disbursment_status == 'PENDING'" class="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-yellow-200 text-yellow-800">{{ request.disbursment_status }}</span>
                                    <span v-else-if="request.disbursment_status == 'REQUEST ACCEPTED'" class="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-200 text-green-800">{{ request.disbursment_status }}</span>
                                    <span v-else-if="request.disbursment_status == 'REQUEST DENIED'" class="px-4 py-1 inline-flex text-sm leading-5 font-semibold rounded-full bg-red-200 text-red-800">{{ request.disbursment_status }}</span>
                                </span>
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{ request.created_at.split('T')[0] }}
                            </td>
                        </tr>

                        <div v-else>No requests yet</div>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colspan="4" class="px-6 py-4 whitespace-nowrap">
                                <nav class="block">
                                    <ul class="flex pl-0 list-none rounded my-2">
                                        <li v-for="page in totalPages" :key="page" class="">
                                            <a @click="changePage(page)" class="relative block py-2 px-3 leading-tight bg-white border border-gray-300 text-blue-700 border-r-0 ml-0 rounded-l hover:bg-blue-100 page-link">{{ page }}</a>
                                        </li>
                                    </ul>
                                </nav>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <!-- withdrawal request form -->
        <div class="mt-8">
            <h2 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-lg">Withdrawal Request Form</h2>
            <form @submit.prevent="requestPayment" class="bg-gray-100 shadow-md rounded-lg p-6 mt-4">
                <div class="flex justify-start gap-x-12 gap-y-5 mb-4">
                    <div class="block w-full">
                        <label for="source" class="block text-sm font-medium text-gray-700">From</label>
                         <select name="mode" id="mode" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" v-model="source" required>
                            <option value="" disabled>From</option>
                            <option value="WALLET">Wallet</option>
                            <option value="COD_BALANCE">COD Earnings</option>
                         </select>
                    </div>
                    <div class=" place-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>

                    </div>
                    <div class="block w-full">
                        <label for="source" class="block text-sm font-medium text-gray-700">To</label>
                        <select name="mode" id="mode" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" v-model="disburseTo" required>
                            <option value="" disabled>To</option>
                            <option value="MPESA">Mpesa</option>
                            <option v-if="source == 'COD_BALANCE'" value="WALLET">Wallet</option>
                        </select>
                    </div>
                </div>
                <div v-if="disburseTo == 'MPESA'" class="mb-4">
                    <label for="account" class="block text-sm font-medium text-gray-700">Mpesa Number</label>
                    <input type="number" id="account" name="account" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" v-model="mpesaNumber" required>
                </div>
                <div class="mb-4">
                    <label for="amount" class="block text-sm font-medium text-gray-700">Amount</label>
                    <input type="number" id="amount" name="amount" class="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" v-model="amount" required>
                    <p v-if="errors.amountError" class="text-red-500 text-sm font-semibold mt-2">{{ errors.amountError }}</p>
                </div>
                <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-400" :disabled="errors.amountError != ''">
                    <span v-if="!$store.state.isLoading">Request Payment</span>
                    <span v-else><LoadingIcon /></span>
                </button>
            </form>
        </div>

    </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'MyWallet',
    data() {
        return {
            source: '',
            mode: '',
            amount: null,
            mpesaNumber: null,
            disburseTo: '',

            // errors
            errors: [],

            // pagination
            currentPage: 1,
            perPage: 5,
        };
    },
    created () {
        this.$store.dispatch('fetchWithdrawals');
    },
    computed: {
        ...mapGetters(['getCustomerDetails', 'getWithdrawalsByCustomerId']),

        customerDetails() {
            return this.getCustomerDetails;
        },

        customerWithdrawals() {
            const start = (this.currentPage - 1) * this.perPage
            const end = start + this.perPage
            return this.getWithdrawalsByCustomerId(this.customerDetails.customer_id)
                .sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at)
                })
                .slice(start, end)
        },

        totalPages() {
            return Math.ceil(this.getWithdrawalsByCustomerId(this.customerDetails.customer_id).length / this.perPage)
        }
    },
    components: {
        LoadingIcon,
    },
    watch: {
        amount(newValue) {
            if (this.source === 'WALLET') {
                if (newValue > this.customerDetails.wallet_amount) {
                    this.errors.amountError = "Insufficient funds in wallet";
                } else {
                    this.errors.amountError = "";
                }
            } else if (this.source === 'COD_BALANCE') {
                if (newValue > this.customerDetails.COD_balance) {
                    this.errors.amountError = "Amount exceeds total COD balance";
                } else {
                    this.errors.amountError = "";
                }
            }
        }
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        changePage(page) {
            this.currentPage = page;
        },

        async requestPayment() {
            /**
             * Send a withdrawal/payment request
             * TODO: Details to send:
             *  * amount: amount,
             *  * disburse_from: source,
             *  * disburse_to: disburseTo,
             *  * phone_number: mpesaNumber,
             *  * customer: customerDetails.customer_id
             * 
             */

            this.setLoadingStatus(true)

            const data = {
                amount: this.amount,
                disburse_from: this.source,
                disburse_to: this.disburseTo,
                phone_number: this.mpesaNumber,
                customer: this.customerDetails.customer_id
            }

            await axios
                .post('/withdrawal/make/', data)
                .then((response) => {
                    this.$toast.success(
                        "Withdrawal request sent successfully",
                        {
                            position: 'bottom-right',
                            duration: 3000,
                        }
                    )

                    this.$store.state.withdrawalsLastFetched = null
                    this.$store.dispatch('fetchWithdrawals')

                    this.$router.push('/my-wallet/')
                })
                .catch((error) => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.$toast.error(
                                `${error.response.data[property]}`,
                                {
                                    position: 'bottom-right',
                                    duration: 3000,
                                }
                            )
                        }
                    } else if (error.message) {
                        this.$toast.error(
                            `${error.message}`,
                            {
                                position: 'bottom-right',
                                duration: 3000,
                            }
                        )
                    }
                })

            this.setLoadingStatus(false)
        }
    },
};
</script>
