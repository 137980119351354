<template>

    <!-- Hero section -->
    <section class="bg-center bg-no-repeat bg-cover bg-[url('../assets/homepage/homepage3.jpg')] bg-blend-multiply">
        <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
            <div class="mb-8 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl"><span class="text-gray-800 p-2 bg-center bg-no-repeat bg-[url('../assets/deliver-bg.svg')]">Deliver</span> Happiness,</div>
            
            <div class="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">All
                <span class="p-2 bg-center bg-no-repeat bg-[url('../assets/through-bg.svg')]"> through!</span>
            </div>
        </div>

        <div class="bg-gradient-to-r from-[#172A4C99] to-transparent top-0 left-0 z-0 w-full h-full"></div>
    </section>

    <div class="mx-auto text-center text-black font-bold p-6 text-3xl">
        <span class="p-2 bg-right bg-no-repeat bg-[url('../assets/bg-1.svg')]">Select Your Service</span>
    </div>

    <!-- service selection -->
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-5 my-3.5 md:mx-24 md:my-6">
        <!-- CBD To CBD -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow relative">
            <img src="../assets/homepage/service1.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px] ">

            <div class="p-5">
                <p class="mb-3 font-normal text-gray-700">You need your parcel picked from anywhere in the CBD and be delivered within the CBD again? For example picking from your courier company and deliver to a client in CBD?</p>
                                                                
                <div class="mb-2 md:absolute md:inset-x-0 md:bottom-0 md:py-5 md:px-3.5">
                    <div class="mb-2">
                        <span class="bg-orange-400 text-black text-base font-medium me-2 px-2.5 py-0.5 rounded">Ksh. 80</span>
                    </div>

                    <router-link to="/cbd-to-cbd" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">CBD To CBD &rarr;</router-link>

                </div>
                
            </div>
            
        </div>

        <!-- CBD To Mtaani -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow relative">
            <img src="../assets/homepage/service2.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px]">

            <div class="p-5">
                <p class="mb-3 font-normal text-gray-700">When you need your parcel sent from Nairobi CBD to your estate, you just drop it at our office or request it to be picked from your location</p>

                <div class="mb-2">
                    <span class="bg-green-400 text-black text-md font-medium me-2 px-2.5 py-0.5 rounded ">
                        Ksh. 100
                    </span>
                </div>
                
                <router-link to="/cbd-to-mtaani" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">CBD To Mtaani &rarr;</router-link>

            </div>
        </div>

        <!-- Mtaani To CBD -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow relative">
            <img src="../assets/homepage/service3.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px] ">
            <div class="p-5 ">
                <p class="mb-3 font-normal text-gray-700">Drop your parcel to your nearest agent and lets deliver for you in town.</p>

                <span class="mb-2 md:absolute md:inset-x-0 md:bottom-0 md:py-5 md:px-3.5">
                    <div class="mb-2">
                        <span class="bg-yellow-400 text-black text-md font-medium me-2 px-2.5 py-0.5 rounded">Ksh. 100</span>
                    </div>

                    <router-link to="/mtaani-to-cbd" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Mtaani To CBD &rarr;</router-link>
                </span>

            </div>
        </div>

        <!-- Mtaani To Mtaani -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow relative">
            <img src="../assets/homepage/service3.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px] ">

            <div class="p-5">
                <p class="mb-3 font-normal text-gray-700">You can send your package from one estate to another estate directly from your nearest agent. I.e from one part of Nairobi to another</p>

                <span class="mb-2 md:absolute md:inset-x-0 md:bottom-0 md:py-5 md:px-3.5">
                    <div class="mb-2">
                        <span class="bg-green-300 text-black text-md font-medium me-2 px-2.5 py-0.5 rounded">Ksh. 180</span>
                    </div>

                    <router-link to="/mtaani-to-mtaani" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Mtaani To Mtaani &rarr;</router-link>
                </span>
                
            </div>
        </div>

        <!-- Mtaani To Upcountry -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow relative">
            <img src="../assets/homepage/service1.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px] ">

            <div class="p-5">
                <p class="mb-3 font-normal text-gray-700">Send your parcel from your estate to any location in Kenya through our agent near you.</p>


                <span class="mb-2 md:absolute md:inset-x-0 md:bottom-0 md:py-5 md:px-3.5">
                    <div class="mb-2">
                        <span class="bg-blue-300 text-black text-md font-medium me-2 px-2.5 py-0.5 rounded">Ksh. 280</span>
                    </div>

                    <router-link to="/mtaani-to-upcountry" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Mtaani To Upcountry &rarr;</router-link>
                </span>

            </div>
        </div>

        <!-- CBD To Upcountry -->
        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow">
            <img src="../assets/homepage/service2.jpg" alt="placeholder" class="rounded-t-lg object-center object-cover w-full h-[200px] ">

            <div class="p-5">
                <p class="mb-3 font-normal text-gray-700">Send your parcel from Nairobi CBD to anywhere in the country. You can request it to be picked from your location or drop it to any of our branches in town</p>

                <div class="mb-2">
                    <span class="bg-pink-400 text-black text-md font-medium me-2 px-2.5 py-0.5 rounded">Ksh. 280</span>
                </div>

                <router-link to="/cbd-to-upcountry" class="block w-full rounded-md bg-[#2ACBA4] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">CBD To Upcountry &rarr;</router-link>
            </div>
        </div>

        
    </div>


    <!-- Sub footer -->
    <div class="flex grid grid-cols-1 md:grid-cols-2 justify-between mx-auto text-center text-white font-bold p-6 text-3xl bg-blue-800">
        <div class="mx-5 my-5 md:mx-24 md:my-12">
            <p class="text-xl md:text-2xl font-bold text-justify tracking-normal">A simple, cost-effective, and speedy method to deliver your parcel</p>
            <p class="text-base font-light text-justify my-2 leading-relaxed tracking-normal">For packages weighing between 0 and 5 kilograms, our fee is from Kshs. 100, with an extra charge of Kshs. 50 per each additional kilogram.</p>
        </div>

        <div class="">
            <img src="../assets/td-001.png" alt="placeholder" class="rounded-lg">
        </div>

    </div>

</template>

<script>
    export default {
        name: 'Homepage'
    }
</script>