<template>
  <div class="my-8 md:mx-24 w-auto">
    <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-500 md:text-2xl">Set New Password</h1>
    <p class="mt-2 text-sm font-semibold leading-relaxed tracking-tight text-gray-900 md:text-xl">
      Please create a strong new password.
    </p>

    <form @submit.prevent="changePassword" class="mt-8">
      <div class="mb-4">
        <label for="newPassword" class="block text-sm font-medium text-gray-700">New Password</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          v-model="newPassword"
          class="w-full mt-2 p-2 border border-gray-300 rounded-md"
          placeholder="Enter new password"
          required
        />
      </div>
      <div class="mb-4">
        <label for="confirmPassword" class="block text-sm font-medium text-gray-700">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          v-model="confirmPassword"
          class="w-full mt-2 p-2 border border-gray-300 rounded-md"
          placeholder="Confirm new password"
          required
        />
      </div>
      <p v-if="passwordMismatch" class="text-red-500 text-sm font-semibold mt-2">Passwords do not match!</p>

      <button type="submit" class="w-full mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-orange-500">
        <span v-if="!$store.state.isLoading">Change Password</span>
        <span v-else><LoadingIcon /></span>
      </button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue';

export default {
  name: 'ResetPassword',
  components: {
    LoadingIcon,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      passwordMismatch: false,
    };
  },
  methods: {
    ...mapMutations(['setLoadingStatus']),

    async changePassword() {
      this.passwordMismatch = false;

      if (this.newPassword !== this.confirmPassword) {
        this.passwordMismatch = true;
        return;
      }

      this.setLoadingStatus(true);

      const email = localStorage.getItem('email');

      try {
        const response = await axios.post('/change-password/', {
          email: email,
          new_password: this.newPassword,
        });

        if (response.status === 200) {
          this.showSuccessNotification('Password changed successfully!');
          localStorage.removeItem('email'); // Remove email from localStorage
          this.$router.push('/login'); // Redirect to login
        } else {
          this.showErrorNotification('Error changing password. Please try again.');
        }
      } catch (error) {
        this.showErrorNotification('Error changing password. Please try again.');
      }

      this.setLoadingStatus(false);
    },
    showSuccessNotification(message) {
      this.$toast.success(message, {
        position: 'bottom-right',
        duration: 3000,
      });
    },
    showErrorNotification(message) {
      this.$toast.error(message, {
        position: 'bottom-right',
        duration: 3000,
      });
    },
  },
};
</script>
