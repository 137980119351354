<template>
    <div class="container mx-auto bg-white">
        <!-- header (Back button & Order ID) -->
        <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
            <div>
                <button @click="$router.back()"
                    class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                            fill="currentColor" />
                    </svg>

                    <span>All orders</span>
                </button>

            </div>

            <div>
                <div class="flex justify-end">
                    <span v-if="orderDetails.status == 'PACKAGE CANCELLED'" class="font-semibold rounded-full bg-red-500 px-12 py-1 text-white text-center">CANCELLED</span>
                    <span v-else-if="orderDetails.payment_status == 'UNPAID'" class="font-semibold rounded-full bg-red-500 px-12 py-1 text-white text-center">UNPAID</span>
                    <span v-else-if="orderDetails.payment_status == 'PAID'" class="font-semibold rounded-full bg-green-500 px-12 py-1 text-white text-center">PAID</span>
                </div>
                <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Order ID: 
                    <span class="text-gray-600 font-bold tracking-normal text-base">{{ orderDetails.invoice_id }}</span>
                </div>
            </div>

        </div>

        <!-- details -->
        <!-- Loading indicator -->
        <div v-if="$store.state.isLoading">
            <div>
                <div>
                    <div class="flex items-center justify-center px-6 py-3">
                        <div class="flex justify-center items-center">
                            <div class="mr-2">Loading...</div>
                            <LoadingIcon />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div id ="order-details" v-if="orderDetails">
            <!-- Receiver Details -->
            <h2 class="font-bold text-2xl leading-7 mx-24 mb-5">Customer Details:</h2>
            
            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.first_name }} {{ customerDetails.last_name }}</dd>
                    </div>
                    
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Customer Email</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.email }}</dd>
                    </div>
                    
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Customer Phone Number</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.phone_number }}</dd>
                    </div>
                    
                </dl>
            </div>
            
            <!-- Receiver Details -->
            <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Receiver Details:</h2>
            
            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Receiver Name</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.receiver }}</dd>
                    </div>
                    
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Receiver Phone Number</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.receiver_phone_number }}</dd>
                    </div>
                    
                </dl>
            </div>
            
            <!-- Package details -->
            <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Order Details:</h2>
    
            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <!-- Description -->
                <dl class="divide-y divide-gray-100">
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Description</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.parcel_description }}</dd>
                    </div>
                    
                    <!-- quantity -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Quantity</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.parcel_quantity }}</dd>
                    </div>
                    
                    <!-- Value -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Value</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ orderDetails.parcel_value }}</dd>
                    </div>
    
                    <!-- Parcel type -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Parcel type</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.parcel_type }}</dd>
                    </div>
    
                    <!-- Service -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Service</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.route_type }}</dd>
                    </div>
    
                    <!-- Parcel Delivery date -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Delivery Date</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.parcel_delivery_date }}</dd>
                    </div>
    
                    <!-- Parcel Delivery time -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Delivery time</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.parcel_delivery_time }}</dd>
                    </div>
    
                    <!-- Parcel Status -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Status</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.status }}</dd>
                    </div>
    
                </dl>
    
            </div>

            <!-- Pickup details (if collection) -->
            <div v-if="orderDetails.pickup_location">
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Pickup Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Location -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Location</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.pickup_location }}</dd>
                        </div>
                        
                        <!-- building -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Building</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.pickup_building }}</dd>
                        </div>
                        
                        <!-- shop number -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Shop Number</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.pickup_shop_no }}</dd>
                        </div>

                        <!-- Collector -->
                        <div v-if="orderDetails.collector" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Collector</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ collectorDetails.first_name }} {{ collectorDetails.last_name }}</dd>
                        </div>

                    </dl>
                </div>

            </div>
            
            <!-- Dropoff details (if dropoff) -->
            <div v-if="orderDetails.dropoff_destination">
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Dropoff Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Location -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Location</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.dropoff_destination }}</dd>
                        </div>
                        
                        <!-- agent -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Agent</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ dropoffAgentDetails.collection_shop_name }}</dd>
                        </div>
                        
                        <!-- Zone -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Zone</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ dropoffAgentDetails.zone }}</dd>
                        </div>

                        <!-- Driver -->
                        <div v-if="orderDetails.dropoff_driver" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Dropoff Driver</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ dropoffDriverDetails.first_name }} {{ dropoffDriverDetails.last_name }}</dd>
                        </div>

                    </dl>
                </div>

            </div>
            
            <!-- Destination details -->
            <div>
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Destination Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Parcel Destination -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Destination</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.destination }}</dd>
                        </div>
                        
                        <!-- Agent -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Agent</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.collection_shop_name }}</dd>
                        </div>
                        
                        <!-- Zone -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Zone</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.zone }}</dd>
                        </div>
                        
                        <!-- Driver -->
                        <div v-if="orderDetails.driver" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Driver</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ driverDetails.first_name }} {{ driverDetails.last_name }}</dd>
                        </div>
                        
                    </dl>
                </div>

            </div>

            <div v-if="orderDetails.status == 'RETURN PACKAGE' || orderDetails.days_stayed_at_agent > 3">
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Return Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Return Reason -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Return Reason</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{  }}</dd>
                        </div>

                        <!-- Return driver -->
                        <div v-if="returnDriverDetails" class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Return Driver</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ returnDriverDetails.first_name }} {{ returnDriverDetails.last_name }}</dd>
                        </div>
                        
                        <!-- Return Date -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Return Date</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.date_returned_by_agent.split('T')[0] }}</dd>
                        </div>
                        
                        <!-- Return Time -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Return Time</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ orderDetails.date_returned_by_agent.split('T')[1].split('.')[0] }}</dd>
                        </div>
                        
                    </dl>
                </div>
            </div>

            <div v-if="orderDetails.payment_status == 'PAID'">
                <!-- assign collector -->
                <div v-if="orderDetails.status === 'PACKAGE CREATED' && orderDetails.pickup_location && !orderDetails.collector">
                    <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Assign Collector:</h2>
    
                    <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Collector</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <select name="collector" id="collector" class="w-full mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-model="collector" :disabled="!collectors.length" required >
                                        <option disabled value="">Select Collector</option>
                                        <option v-for="collector in collectors" :key="collector.collector_id" :value="collector.collector_id"> {{ collector.first_name }} {{ collector.last_name }}</option>
                                    </select>
                                </dd>
                            </div>
                        </dl>
                    </div>
    
                    <div class="flex justify-center mt-6">
                        <button v-if="!orderDetails.collector" @click="assignCollector" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                            <span v-if="!$store.state.isLoading">Assign Collector</span>
                            <span v-else ><LoadingIcon /></span>
    
                        </button>
                    </div>
                </div>
                
                
                <!-- Assign driver -->
                <div v-else-if="orderDetails.status === 'PACKAGE IN OFFICE' && !orderDetails.driver">
    
                    <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Assign Driver:</h2>
    
                    <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Driver</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <select name="driver" id="driver" class="w-full mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-model="driver" :disabled="!drivers.length" required >
                                        <option disabled value="">Select driver</option>
                                        <option v-for="driver in drivers" :key="driver.driver_id" :value="driver.driver_id"> {{ driver.first_name }} {{ driver.last_name }} - {{ driver.zone }}</option>
                                    </select>
                                </dd>
                            </div>
                        </dl>
                    </div>
    
                    <div class="flex gap-4 justify-center mt-6">
                        <button v-if="!orderDetails.driver" @click="assignDriver" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                            <span v-if="!$store.state.isLoading">Assign Driver</span>
                            <span v-else ><LoadingIcon /></span>
                        </button>
                    </div>
                </div>
                
                <!-- Assign dropoff driver -->
                <div v-else-if="orderDetails.status === 'DELIVERED TO DROPOFF AGENT' && !orderDetails.dropoff_driver">
    
                    <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Assign Dropoff Driver:</h2>
    
                    <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Driver</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <select name="driver" id="driver" class="w-full mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-model="dropoff_driver" :disabled="!drivers.length" required >
                                        <option disabled value="">Select driver</option>
                                        <option v-for="driver in drivers" :key="driver.driver_id" :value="driver.driver_id"> {{ driver.first_name }} {{ driver.last_name }} - {{ driver.zone }}</option>
                                    </select>
                                </dd>
                            </div>
                        </dl>
                    </div>
    
                    <div class="flex gap-4 justify-center mt-6">
                        <button v-if="!orderDetails.dropoff_driver" @click="assignDropoffDriver" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                            <span v-if="!$store.state.isLoading">Assign Dropoff Driver</span>
                            <span v-else ><LoadingIcon /></span>
                        </button>
                    </div>
                </div>
                
                <!-- Assign return driver -->
                <div v-else-if="orderDetails.days_stayed_at_agent > 3 && !orderDetails.returning_driver">
    
                    <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Assign Dropoff Driver:</h2>
    
                    <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                        <dl class="divide-y divide-gray-100">
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Driver</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    <select name="driver" id="driver" class="w-full mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-model="returning_driver" :disabled="!drivers.length" required >
                                        <option disabled value="">Select driver</option>
                                        <option v-for="driver in drivers" :key="driver.driver_id" :value="driver.driver_id"> {{ driver.first_name }} {{ driver.last_name }} - {{ driver.zone }}</option>
                                    </select>
                                </dd>
                            </div>
                        </dl>
                    </div>
    
                    <div class="flex gap-4 justify-center mt-6">
                        <button v-if="!orderDetails.returning_driver" @click="assignReturningDriver" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" >
                            <span v-if="!$store.state.isLoading">Assign Dropoff Driver</span>
                            <span v-else ><LoadingIcon /></span>
                        </button>
                    </div>
                </div>

            </div>
            



        </div>
            <div class="flex justify-end items-center mt-8 -mb-8 gap-x-4">
                <div  class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                    type="button" @click="downloadPDF">Download PDF</div>
                <button @click="printReceipt" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Print Receipt
                </button>
            </div> 
        </div>
</template>

<script>
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { mapGetters, mapMutations } from 'vuex';
import html2pdf from 'html2pdf.js';
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'OrderPage',
        data() {
            return {
                id: this.$route.params.id,

                collector: '',
                driver: '',
                dropoff_driver: '',
                returning_driver: ''
            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters([
                'getParcelById',
                'getCustomerById',
                'getAgentById',
                'getCollectors',
                'getDrivers',
                'getCollectorById',
                'getDriverById'
            ]),

            orderDetails() {
                return this.getParcelById(this.id)
            },

            customerDetails() {
                return this.getCustomerById(this.orderDetails.customer)
            },

            agentDetails() {
                return this.getAgentById(this.orderDetails.agent)
            },
            
            dropoffAgentDetails() {
                return this.getAgentById(this.orderDetails.dropoff_agent)
            },

            collectors() {
                return this.getCollectors
            },

            drivers() {
                return this.getDrivers.filter(driver => driver.active)
            },

            collectorDetails() {
                return this.getCollectorById(this.orderDetails.collector)
            },

            driverDetails() {
                return this.getDriverById(this.orderDetails.driver)
            },

            returnDriverDetails() {
                return this.getDriverById(this.orderDetails.returning_driver)
            },

            dropoffDriverDetails() {
                return this.getDriverById(this.orderDetails.dropoff_driver)
            },
            
            printReceipt() {
                const doc = new jsPDF();
        
                // Set font styles
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(16);
        
                // Add title
                doc.text('Timely Delivery Receipt', 105, 20, null, null, 'center');
        
                // Reset font styles for content
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
        
                // Add order details
                const orderDetails = [
                { label: 'Invoice ID:', value: this.orderDetails.invoice_id },
                { label: 'Customer Name:', value: `${this.customerDetails.first_name} ${this.customerDetails.last_name}` },
                { label: 'Customer Phone:', value: this.customerDetails.phone_number },
                { label: 'Receiver Name:', value: this.orderDetails.receiver },
                { label: 'Receiver Phone:', value: this.orderDetails.receiver_phone_number },
                { label: 'Parcel Type:', value: this.orderDetails.parcel_type },
                { label: 'Service:', value: this.orderDetails.route_type },
                { label: 'Destination:', value: this.orderDetails.destination },
                { label: 'Agent:', value: this.agentDetails.collection_shop_name },
                ];
        
                // Add order details to the PDF using autoTable (First Receipt)
                doc.autoTable({
                    startY: 40,
                    head: [['Label', 'Value']],
                    body: orderDetails.map(item => [item.label, item.value]),
                });
        
                // Draw a broken line for the page break
                const lineStartY = doc.autoTable.previous.finalY + 10; // 10 for spacing
                doc.setLineWidth(0.5);
                doc.setLineDash([5, 5]); // Set line dash pattern
                doc.line(20, lineStartY, doc.internal.pageSize.getWidth() - 20, lineStartY);
        
                // Add title (Second Receipt - Duplicate)
                doc.text('Timely Delivery Receipt', 105, lineStartY + 20, null, null, 'center');
        
                // Add order details to the PDF using autoTable (Second Receipt - Duplicate)
                doc.autoTable({
                    startY: lineStartY + 40,
                    head: [['Label', 'Value']],
                    body: orderDetails.map(item => [item.label, item.value]),
                });
        
                // Open print dialog
                doc.autoPrint();
                doc.output('dataurlnewwindow'); // Open in new window
            },
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            // assign collector
            async assignCollector() {
                this.setLoadingStatus(true)

                try {
                    const data = {
                        collector: this.collector
                    }

                    await axios
                        .put(`/packages/${this.id}/`, data)
                        .then((response) => {
                            
                            this.$toast.success(
                                "Successfully assigned collector!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )
                            
                            this.$store.state.parcelsLastFetched = null
                            this.$store.dispatch('fetchAllParcels')

                            this.$router.push('/admin/orders/')
                        })
                        .catch((error) => {

                            this.$toast.error(
                                "Error assigning collector!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )
                        })



                } catch (error) {

                    this.$toast.error(
                        "Error assigning collector!",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                }

                this.setLoadingStatus(false)
            },

            // assign driver
            async assignDriver() {
                this.setLoadingStatus(true)

                try {
                    const data = {
                        driver: this.driver
                    }

                    await axios
                        .put(`/packages/${this.id}/`, data)
                        .then((response) => {

                            this.$toast.success(
                                "Successfully assigned driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )

                            this.$store.state.parcelsLastFetched = null
                            this.$store.dispatch('fetchAllParcels')

                            this.$router.push('/admin/orders/')
                        })
                        .catch((error) => {

                            this.$toast.error(
                                "Error assigning driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )
                        })



                } catch (error) {
                    this.$toast.error(
                        "Error assigning driver!",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                }

                this.setLoadingStatus(false)
            },

            // assign dropoff driver
            async assignDropoffDriver() {
                this.setLoadingStatus(true)

                try {
                    const data = {
                        dropoff_driver: this.dropoff_driver
                    }

                    await axios
                        .put(`/packages/${this.id}/`, data)
                        .then((response) => {

                            this.$toast.success(
                                "Successfully assigned driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )

                            this.$store.state.parcelsLastFetched = null
                            this.$store.dispatch('fetchAllParcels')

                            this.$router.push('/admin/orders/')
                        })
                        .catch((error) => {

                            this.$toast.error(
                                "Error assigning driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )
                        })



                } catch (error) {
                    this.$toast.error(
                        "Error assigning driver!",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                }

                this.setLoadingStatus(false)
            },

            // assign returning driver
            async assignReturningDriver() {
                this.setLoadingStatus(true)

                try {
                    const data = {
                        returning_driver: this.returning_driver
                    }

                    await axios
                        .put(`/packages/${this.id}/`, data)
                        .then((response) => {

                            this.$toast.success(
                                "Successfully assigned returning driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )

                            this.$store.state.parcelsLastFetched = null
                            this.$store.dispatch('fetchAllParcels')

                            this.$router.push('/admin/orders/')
                        })
                        .catch((error) => {

                            this.$toast.error(
                                "Error assigning returning driver driver!",
                                {
                                    position: "bottom-right",
                                    duration: 3000
                                }
                            )
                        })



                } catch (error) {
                    this.$toast.error(
                        "Error assigning returning driver!",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                }

                this.setLoadingStatus(false)
            },

            downloadPDF() {
                const element = document.getElementById('order-details');
                const opt = {
                    margin: 1,
                    filename: `order-${this.id}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                };

                html2pdf().set(opt).from(element).save();
            },
        }
    }
</script>
