<template>
    <div class=" pb-10">

        <div class="mx-5 md:mx-24 px-8 pt-6">
            <button @click="$router.back()"
                class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                        fill="currentColor" />
                </svg>

                <span>Back</span>
            </button>

        </div>



        <div class="items-center pb-12">
            <h1 class="text-center text-2xl text-black font-bold">New Agent</h1>
        </div>


        <div>
            <form @submit.prevent="createAgent" class="mb-8 md:mx-6 w-auto items-center px-4 md:px-24">
                <!-- Business Details -->
                <div>
                    <h2 class="font-bold text-2xl mb-5">Business Profile</h2>

                    <!-- Zone -->
                    <div class="flex gap-3 items-center mb-5">
                        <label for="zone" class="block mb-2 text-base font-medium text-gray-900 ">Zone:</label>
                        <select name="zone" id="zone"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 "
                            v-model="zone" required>
                            <option disabled value="">Select Zone</option>
                            <option v-for="(zone, index) in zones" :value="index" :key="index">{{ zone.split('-')[1] }}</option>
                        </select>
                    </div>



                    <h2 class="font-semibold text-xl mb-5">Business Details</h2>

                    <!-- Business Name -->
                    <div class="mb-7">
                        <label for="bsnName" class="block mb-2 text-sm font-medium text-gray-900 ">Business Name</label>
                        <input type="text" id="bsnName"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Enter Business Name" v-model="businessName" required />
                    </div>



                    <!-- Building Name -->
                    <div class="mb-7">
                        <label for="buildingName" class="block mb-2 text-sm font-medium text-gray-900 ">Building
                            Name</label>
                        <input type="text" id="buildingName"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Enter Building Name" v-model="buildingName" required />
                    </div>

                    <!-- Floor / Room Number -->
                    <div class="grid md:grid-cols-2 md:gap-6">
                        <!-- Building floor number -->
                        <div class="relative z-0 w-full mb-5 group">
                            <label for="floorNumber"
                                class="block mb-2 text-sm font-medium text-gray-900 ">Floor:</label>
                            <input type="text" id="floorNumber"
                                class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Enter Floor Number" v-model="floorNo" required />
                        </div>

                        <!-- Room Number -->
                        <div class="relative z-0 w-full mb-5 group">
                            <label for="roomNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Room
                                No.:</label>
                            <input type="text" id="roomNumber"
                                class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Enter Room Number" v-model="roomNo" required />
                        </div>

                    </div>

                    <!-- Location -->
                    <div class="mb-7">
                        <label for="location" class="block mb-2 text-sm font-medium text-gray-900 ">Location:</label>
                        <input type="text" id="location"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Enter Location" v-model="location" required />
                    </div>

                    <!-- Location description -->
                    <div class="mb-7">
                        <label for="locationDescription" class="block mb-2 text-sm font-medium text-gray-900 ">Location
                            Description:</label>
                        <input type="text" id="locationDescription"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Enter Location Description" v-model="locationDescription" required />
                    </div>

                    <!--Pin Location -->
                    <div class="mb-7">
                        <label for="location" class="block mb-2 text-sm font-medium text-gray-900 ">Pin
                            Location:</label>
                        <input type="text" id="locationPin"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Enter Pin Location" v-model="locationPin" required />
                    </div>


                    <!-- Business hours -->
                    <h2 class="font-semibold text-xl mb-5">Business Hours</h2>

                    <h2 class="font-semibold text-lg mb-5">Days</h2>

                    <!-- Days checkboxes -->
                    <div class="flex mb-7">

                        <div class="flex items-center justify-between me-4">
                            <input type="checkbox" name="mon" id="mon"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Monday">
                            <label for="mon" class="ms-2 text-sm font-medium text-gray-900">Monday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="tue" id="tue"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Tuesday">
                            <label for="tue" class="ms-2 text-sm font-medium text-gray-900">Tuesday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="wed" id="wed"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Wednesday">
                            <label for="wed" class="ms-2 text-sm font-medium text-gray-900">Wednesday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="thur" id="thur"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Thursday">
                            <label for="thur" class="ms-2 text-sm font-medium text-gray-900">Thursday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="fri" id="fri"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Friday">
                            <label for="fri" class="ms-2 text-sm font-medium text-gray-900">Friday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="sat" id="sat"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Saturday">
                            <label for="sat" class="ms-2 text-sm font-medium text-gray-900">Saturday</label>
                        </div>

                        <div class="flex items-center me-4">
                            <input type="checkbox" name="sun" id="sun"
                                class="w-4 h-4 text-blue-600 bg-gray-100 boder-gray-300 rounded-lg focus:ring-blue-600 focus:ring-2"
                                v-model="availableDays" value="Sunday">
                            <label for="sun" class="ms-2 text-sm font-medium text-gray-900">Sunday</label>
                        </div>

                    </div>

                    <!-- Time picker -->
                    <h2 class="font-semibold text-lg mb-5">Time</h2>


                    <div class=" mx-auto grid grid-cols-2 gap-4 mb-7">
                        <div>
                            <label for="openTime" class="block mb-2 text-sm font-medium text-gray-900">Open:</label>
                            <div class="relative">
                                <div
                                    class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 " aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input type="time" id="openTime"
                                    class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    min="06:00" max="20:00" value="08:00" required />
                            </div>
                        </div>

                        <div>
                            <label for="closeTime" class="block mb-2 text-sm font-medium text-gray-900 ">Close:</label>
                            <div class="relative">
                                <div
                                    class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-500 " aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd"
                                            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                                            clip-rule="evenodd" />
                                    </svg>
                                </div>
                                <input type="time" id="closeTime"
                                    class="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                    min="08:00" max="20:00" value="16:00" required />
                            </div>
                        </div>
                    </div>


                    <!-- Business Documents -->
                    <h2 class="font-semibold text-xl mb-5">Business Documents</h2>

                    <div class="mb-7 grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-7">
                        <!-- Registration certificate -->
                        <div>
                            <label for="closeTime" class="block mb-2 text-base font-medium text-gray-900 ">Registration Certificate:</label>

                            <div class="flex items-center justify-center">
                                <label for="registrationCertificate"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="registrationCertificate">
                                        <!-- <img :src="registrationCertificate" alt="Driver license"> -->
                                        {{ registrationCertificate.name }}
                                    </div>

                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="registrationCertificate" type="file" ref="registrationCertificate"
                                        v-on:change="uploadFiles()" class="hidden" />
                                </label>
                            </div>

                        </div>

                        <!-- Business permit -->
                        <div>
                            <label for="closeTime" class="block mb-2 text-base font-medium text-gray-900 ">Business
                                permit:</label>

                            <div class="flex items-center justify-center">
                                <label for="businessPermit"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="businessPermit">
                                        <!-- <img :src="businessPermit" alt="Driver license"> -->
                                        {{ businessPermit.name }}
                                    </div>

                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="businessPermit" type="file" ref="businessPermit"
                                        v-on:change="uploadFiles()" class="hidden" />
                                </label>
                            </div>

                        </div>


                    </div>

                    <!-- Business Picture -->
                    <h2 class="font-semibold text-xl mb-5">Business Picture</h2>

                    <div class="mb-7 grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-7">
                        <!-- Front -->
                        <div>
                            <label for="closeTime"
                                class="block mb-2 text-base font-medium text-gray-900 ">Front:</label>

                            <div class="flex items-center justify-center">
                                <label for="frontPicture"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="frontPicture">
                                        {{ frontPicture.name }}
                                    </div>
                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="frontPicture" type="file" ref="frontPicture" v-on:change="uploadFiles()"
                                        class="hidden" />
                                </label>
                            </div>

                        </div>

                        <!-- Inside -->
                        <div>
                            <label for="closeTime"
                                class="block mb-2 text-base font-medium text-gray-900 ">Inside:</label>

                            <div class="flex items-center justify-center">
                                <label for="insidePicture"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="insidePicture">
                                        {{ insidePicture.name }}
                                    </div>
                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="insidePicture" type="file" ref="insidePicture"
                                        v-on:change="uploadFiles()" class="hidden" />
                                </label>
                            </div>

                        </div>


                    </div>

                </div>

                <!-- Owner Profile -->
                <div>
                    <h2 class="font-bold text-2xl mb-5">Owner Profile</h2>

                    <h2 class="font-semibold text-xl mb-5">Owner Details:</h2>

                    <!-- Name -->
                    <div class="grid md:grid-cols-2 md:gap-6">
                        <!-- First Name -->
                        <div class="relative z-0 w-full mb-5 group">
                            <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900 ">First
                                Name:</label>
                            <input type="text" id="firstName"
                                class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="First Name" v-model="ownerFirstName" required />

                        </div>

                        <!-- Last Name -->
                        <div class="relative z-0 w-full mb-5 group">
                            <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900 ">Last
                                Name</label>
                            <input type="text" id="lastName"
                                class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                                placeholder="Last Name" v-model="ownerLastName" required />
                        </div>

                    </div>

                    <!-- Email -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Email:</label>
                        <input type="email" id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Email" v-model="ownerEmail" required />
                    </div>

                    <!-- Password -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password:</label>
                        <input type="password" id="password"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="******" v-model="password" required />
                    </div>

                    <!-- Confirm Password -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="confirmPassword" class="block mb-2 text-sm font-medium text-gray-900 ">Confirm Password:</label>
                        <input type="password" id="confirmPassword"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="******" v-model="confirmPassword" required />

                        <p v-if="errors.length" class="text-red-500 text-base font-bold">Passwords do not match</p>
                    </div>

                    <!-- Phone Number -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Phone
                            Number:</label>
                        <input type="tel" id="phoneNumber"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Phone Number" v-model="ownerPhoneNumber" required />
                    </div>

                    <!-- ID Number -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="idNumber" class="block mb-2 text-sm font-medium text-gray-900 ">ID Number:</label>
                        <input type="number" id="idNumber"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="ID Number" v-model="ownerIdNumber" required />
                    </div>

                    <!-- ID Image -->
                    <h2 class="font-semibold text-xl mb-5">ID Images</h2>

                    <div class="mb-7 grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-7">
                        <!-- ID front image -->
                        <div>
                            <p class="block mb-2 text-base font-medium text-gray-900 ">Front:</p>

                            <div class="flex items-center justify-center">
                                <label for="ownerIdFrontImage"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="ownerIdFrontImage">
                                        {{ ownerIdFrontImage.name }}
                                    </div>
                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="ownerIdFrontImage" type="file" v-on:change="uploadFiles()"
                                        ref="ownerIdFrontImage" class="hidden" required>
                                </label>
                            </div>

                        </div>

                        <!-- ID back image -->
                        <div>
                            <label for="ownerIdBackImage"
                                class="block mb-2 text-base font-medium text-gray-900 ">Back:</label>

                            <div class="flex items-center justify-center">
                                <label for="ownerIdBackImage"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="ownerIdBackImage">
                                        {{ ownerIdBackImage.name }}
                                    </div>
                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="ownerIdBackImage" type="file" v-on:change="uploadFiles()"
                                        ref="ownerIdBackImage" class="hidden" required />
                                </label>
                            </div>

                        </div>
                    </div>

                    <!-- Passport Photo -->
                    <div class="relative z-0 w-full mb-5 group">
                        <div>
                            <label for="passport" class="block mb-2 text-base font-medium text-gray-900 ">Passport
                                Photo:</label>

                            <div class="flex items-center justify-center">
                                <label for="passportPhoto"
                                    class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                    <div v-if="passportPhoto">
                                        {{ passportPhoto.name }}
                                    </div>
                                    <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                        </svg>
                                        <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to
                                                upload</span> or drag and drop</p>
                                        <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input id="passportPhoto" type="file" ref="passportPhoto"
                                        v-on:change="uploadFiles()" class="hidden" />
                                </label>
                            </div>

                        </div>
                    </div>

                    <h2 class="font-semibold text-xl mb-5">Payment Details:</h2>
                    <!-- Mpesa Number -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="mpesaNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Mpesa
                            Number:</label>
                        <input type="tel" id="mpesaNumber"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Mpesa Number" v-model="ownerMpesaNumber" required />
                    </div>

                    <!-- Registered Names -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="registeredNames" class="block mb-2 text-sm font-medium text-gray-900 ">Registered
                            Names:</label>
                        <input type="text" id="registeredNames"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                            placeholder="Registered Names" v-model="ownerRegisteredNames" required />
                    </div>

                </div>


                <button type="submit"
                    class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                    <span v-if="!$store.state.isLoading">Complete</span>
                    <span v-else><LoadingIcon /></span>
                </button>
            </form>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import Validations from '@/services';
import LoadingIcon from '@/components/LoadingIcon.vue';
import imageCompression from 'browser-image-compression';

import { sendMessage } from '@/services/messagingService';
import { MESSAGE_TEMPLATES } from '@/services/messageTemplates';

export default {
    name: 'NewAgent',
    data() {
        return {
            zone: '',
            businessName: '',
            buildingName: '',
            floorNo: '',
            roomNo: '',
            location: '',
            locationDescription: '',
            locationPin: '',


            // availableDays: '',
            availableDays: [],
            Monday: '',
            Tuesday: '',
            Wednesday: '',
            Thursday: '',
            Friday: '',
            Saturday: '',
            Sunday: '',

            // business files and images
            registrationCertificate: '',
            businessPermit: '',
            frontPicture: '',
            insidePicture: '',
            passportPhoto: '',

            // Owner details
            ownerFirstName: '',
            ownerLastName: '',
            ownerEmail: '',
            password: '',
            confirmPassword: '',
            ownerPhoneNumber: '',
            ownerIdNumber: '',
            ownerIdFrontImage: '',
            ownerIdBackImage: '',

            ownerMpesaNumber: '',
            ownerRegisteredNames: '',
            ownerRegisteredNames: '',

            errors: [],

        }
    },
    components: {
        LoadingIcon,
    },
    computed: {
        ...mapGetters(['getZones']),

        zones() {
            return this.getZones
        }
    },
    created () {
        this.$store.dispatch('fetchZones')
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        async createAgent() {
            this.setLoadingStatus(true)

            this.errors = []

            let username = this.businessName.split(' ')

            let validPassword = new Validations(this.ownerEmail, this.password)
            const isValid = validPassword.checkValidations()

            if (isValid !== true) {
                for (const error in isValid) {
                    this.$toast.error(
                        isValid[error],
                        {
                            position: 'bottom-right',
                            duration: 3000,
                        }
                    )
                }

                this.setLoadingStatus(false)

                return
            }

            if (this.password !== this.confirmPassword) {
                this.errors.push("Passwords do not match")
                this.$toast.error(
                    "Passwords do not match",
                    {
                        position: 'bottom-right',
                        duration: 3000,
                    }
                )

                this.setLoadingStatus(false)
                
                return
            }

            const data = new FormData();

            // Append text data
            data.append('collection_shop_name', this.businessName);
            data.append('agent_building', this.buildingName);
            data.append('agent_floor', this.floorNo);
            data.append('agent_room_no', this.roomNo);
            data.append('pinned_location', this.locationPin);
            data.append('location_name', this.location);
            data.append('location_description', this.locationDescription);
            data.append('zone', this.zone);
            data.append('available_days', JSON.stringify(this.availableDays));
            data.append('is_active', true);
            data.append('username', username.join(''));
            data.append('password', this.password);
            data.append('agent_name', `${this.ownerFirstName} ${this.ownerLastName}`);
            data.append('first_name', this.ownerFirstName);
            data.append('last_name', this.ownerLastName);
            data.append('email', this.ownerEmail);
            data.append('phone_number', this.ownerPhoneNumber);
            data.append('mpesa_number', this.ownerMpesaNumber);
            data.append('mpesa_name', this.ownerRegisteredNames);
            data.append('idcard_number', this.ownerIdNumber);

            // files
            data.append('idcard_front_image', this.ownerIdFrontImage);
            data.append('idcard_back_image', this.ownerIdBackImage);
            data.append('registration_certificate', this.registrationCertificate);
            data.append('business_permit', this.businessPermit);
            data.append('front_location_image', this.frontPicture);
            data.append('inside_location_image', this.insidePicture);
            data.append('passport_photo', this.passportPhoto);

            await axios
                .post('/agent/register/', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(async (response) => {
                    
                    this.$toast.success(
                        "Successfully created agent!",
                        {
                            position: 'bottom-right',
                            duration: 3000,
                        }
                    )

                    await this.sendCreatedMessage()
                    
                    if (this.$store.state.isAdminAuthenticated || this.$store.state.isAuthenticated) {
                        this.$store.state.agentsLastFetched = null
                        this.$store.dispatch('fetchAgents')
                        this.$router.push('/admin/agents')

                    } else {
                        // clear all data
                        this.zone = ''
                        this.businessName = ''
                        this.buildingName = ''
                        this.floorNo = ''
                        this.roomNo = ''
                        this.location = ''
                        this.locationDescription = ''
                        this.locationPin = ''
                        this.availableDays = []
                        this.Monday = ''
                        this.Tuesday = ''
                        this.Wednesday = ''
                        this.Thursday = ''
                        this.Friday = ''
                        this.Saturday = ''
                        this.Sunday = ''
                        this.registrationCertificate = ''
                        this.businessPermit = ''
                        this.frontPicture = ''
                        this.insidePicture = ''
                        this.passportPhoto = ''
                        this.ownerIdFrontImage = ''
                        this.ownerIdBackImage = ''
                        this.ownerFirstName = ''
                        this.ownerLastName = ''
                        this.ownerEmail = ''
                        this.password = ''
                        this.confirmPassword = ''
                        this.ownerPhoneNumber = ''
                        this.ownerIdNumber = ''
                        this.ownerMpesaNumber = ''
                        this.ownerRegisteredNames = ''
                        this.errors = []
                        this.setLoadingStatus(false)

                        this.$router.push('/create-agent')
                    }

                })
                .catch((error) => {
                    console.error("Error creating agent (NewAgent): ", error);

                    if (error.response.data) {
                        for (const property in error.response.data) {
                            this.$toast.error(
                                `${error.response.data[property]}`,
                                {
                                    position: 'bottom-right',
                                    duration: 3000,
                                }
                            )
                        }
                    } else {
                        this.$toast.error(
                            "Error creating agent. Please try again!",
                            {
                                position: 'bottom-right',
                                duration: 3000,
                            }
                        )
                    }

                })

            this.setLoadingStatus(false)
        },

        async compressImage(file) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            
            try {
                const compressedFile = await imageCompression(file, options);
                return compressedFile;
            } catch (error) {
                console.error('Error compressing image:', error);
                return file;  // Return original file if compression fails
            }
        },

        async uploadFiles() {
            const registrationCertificate = this.$refs.registrationCertificate.files[0]
            const businessPermit = this.$refs.businessPermit.files[0]
            const frontPicture = this.$refs.frontPicture.files[0]
            const insidePicture = this.$refs.insidePicture.files[0]
            const passportPhoto = this.$refs.passportPhoto.files[0]
            const ownerIdFrontImage = this.$refs.ownerIdFrontImage.files[0]
            const ownerIdBackImage = this.$refs.ownerIdBackImage.files[0]

            if (registrationCertificate) {
                const compressedRegistrationCertificate = await this.compressImage(registrationCertificate);
                const registrationCertificateName = `${this.businessName}_registration_certificate.${registrationCertificate.name.split('.').pop()}`;
                this.registrationCertificate = new File([compressedRegistrationCertificate], registrationCertificateName, { type: compressedRegistrationCertificate.type });
            }

            if (businessPermit) {
                const compressedBusinessPermit = await this.compressImage(businessPermit);
                const businessPermitName = `${this.businessName}_business_permit.${businessPermit.name.split('.').pop()}`;
                this.businessPermit = new File([compressedBusinessPermit], businessPermitName, { type: compressedBusinessPermit.type });
            }

            if (frontPicture) {
                const businessFrontPicture = await this.compressImage(frontPicture);
                const frontPictureName = `${this.businessName}_front_picture.${frontPicture.name.split('.').pop()}`;
                this.frontPicture = new File([businessFrontPicture], frontPictureName, { type: businessFrontPicture.type });
            }

            if (insidePicture) {
                const businessInsidePicture = await this.compressImage(insidePicture);
                const insidePictureName = `${this.businessName}_inside_picture.${insidePicture.name.split('.').pop()}`;
                this.insidePicture = new File([businessInsidePicture], insidePictureName, { type: businessInsidePicture.type });
            }

            if (passportPhoto) {
                const businessOwnerPassportPhoto = await this.compressImage(passportPhoto);
                const passportPhotoName = `${this.ownerFirstName}_${this.ownerLastName}_passport_photo.${passportPhoto.name.split('.').pop()}`;
                this.passportPhoto = new File([businessOwnerPassportPhoto], passportPhotoName, { type: businessOwnerPassportPhoto.type });
            }

            if (ownerIdFrontImage) {
                const businessOwnerIdFrontImage = await this.compressImage(ownerIdFrontImage);
                const ownerIdFrontImageName = `${this.ownerFirstName}_${this.ownerLastName}_idcard_front_image.${ownerIdFrontImage.name.split('.').pop()}`;
                this.ownerIdFrontImage = new File([businessOwnerIdFrontImage], ownerIdFrontImageName, { type: businessOwnerIdFrontImage.type });
            }

            if (ownerIdBackImage) {
                const businessOwnerIdBackImage = await this.compressImage(ownerIdBackImage);
                const ownerIdBackImageName = `${this.ownerFirstName}_${this.ownerLastName}_idcard_back_image.${ownerIdBackImage.name.split('.').pop()}`;
                this.ownerIdBackImage = new File([businessOwnerIdBackImage], ownerIdBackImageName, { type: businessOwnerIdBackImage.type });
            }

        },

        async sendCreatedMessage() {
            /**
             * Send message to customer
             * * TEMPLATE: AGENT_CREATED
             * @data agent_name: ownerFirstName + ownerLastName
             * 
             * @data phoneNumber: ownerPhoneNumber
             */

             let messageTemplate = MESSAGE_TEMPLATES.AGENT_CREATED

            let messageData = {
                agent_name: `${this.ownerFirstName} ${this.ownerLastName}`,
            }

            let phoneNumber = this.ownerPhoneNumber

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
            } catch (error) {
                console.error("Something went wrong while sending message: ", error);
                
            }
        }
    }
}
</script>