<template>

    <!-- Header -->
    <div class="flex justify-between mt-6 md:mt-3 mx-8 md:mx-24 items-center">
        <div>
            <button @click="$router.push('/register')" class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-base hover:text-blue-500">
                
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z" fill="currentColor"/>
                </svg>
    
                <span>Back</span> 
            </button>

        </div>

        <div>
            <div class="text-gray-500 font-light tracking-normal text-right text-xs md:text-sm">Step 01/02</div>
            <div class="text-gray-600 font-medium tracking-normal text-right text-sm">Company Info</div>

        </div>

    </div>

    <form @submit.prevent="submitForm" class="my-8 mx-8 md:mx-24 w-auto">
        <div class="mb-12">
            <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-2xl">Register Business Account!</h1>

            <h4 class="mt-2 text-sm font-semibold leading-relaxed tracking-tight text-gray-500 md:text-xl">
                Please fill in the required details about your business
            </h4>
        </div>


        <!-- Organization name -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="text" name="name" id="name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="name" required />
            <label for="name" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Organization Name</label>
        </div>
        
        <!-- Organization email -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="email" name="email" id="email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="email" required />
            <label for="email" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Organization Email</label>
        </div>

        <!-- Organization phone number -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="tel" name="phone" id="phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="phone_number" required />
            <label for="phone" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone Number</label>
        </div>

        <!-- Location -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="text" name="location" id="location" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="location" required />
            <label for="location" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Location</label>
        </div>
        
        <!-- Building -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="text" name="building" id="building" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="building" required />
            <label for="building" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Building</label>
        </div>

        <!-- Shop Number -->
        <div class="relative z-0 w-full mb-5 group">
            <input type="text" name="shop_number" id="shop_number" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " v-model="shop_number" required />
            <label for="shop_number" class="peer-focus:font-medium absolute text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Shop Number/ Office Number</label>
        </div>


        <!-- Register button -->
        <button type="submit" class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-base font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            <span v-if="$store.state.isLoading"><LoadingIcon /></span>
            <span v-else>Next <span>&rarr;</span></span>
        </button>

    </form>
</template>

<script>
    import { mapMutations } from 'vuex';
    import axios from 'axios';
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'CompanyInfo',
        data() {
            return {
                name: '',
                email: '',
                phone_number: '',
                location: '',
                building: '',
                shop_number: ''
            }
        },
        components: {
            LoadingIcon,
        },
        methods: {
            ...mapMutations(['setCustomerRegistrationDetails', 'setLoadingStatus']),

            async submitForm() {
                this.setLoadingStatus(true)

                let username = this.name.split(' ')

                // validate email
                const validEmail = await this.checkEmail()

                if (!validEmail) {
                    this.$toast.error('Email already exists. Please use another email address',
                        { duration: 3000, position: 'bottom-right' }
                    )
                    this.setLoadingStatus(false)
                    return
                }

                const details = {
                    name: this.name,
                    username: username.join(''),
                    first_name: username[0],
                    last_name: username.slice(1).join(' '),
                    email: this.email,
                    phone_number: this.phone_number,
                    location: this.location,
                    building: this.building,
                    shop_number: this.shop_number
                }

                // console.log('Business details: ', details)

                this.setCustomerRegistrationDetails(details)

                this.setLoadingStatus(false)

                this.$router.push('step-2')
            },

            // Check if email exists
            async checkEmail() {
                let isValid = null

                const data = {
                    email: this.email
                }

                await axios
                    .post('/checkemail/', data)
                    .then((response) => {
                        isValid = true
                    })
                    .catch((error) => {
                        isValid = false
                    })

                return isValid

            },

        }
    }
</script>
