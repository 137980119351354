<template>
    <div class="items-center bg-gray-200 pb-10">

        <div class="items-center py-12">
            <h1 class="text-center text-2xl text-black font-bold">Parcel Details</h1>
        </div>


        <div>
            <form @submit.prevent="submitForm" class="mb-8 md:mx-24 w-auto items-center px-8 md:px-52">
                <!-- Location -->
                
                <!-- Parcel type -->
                <h2 class="font-bold text-2xl mb-5">Parcel Type</h2>

                <div class="flex">
                    <span v-if="this.$store.state.isLoading" ><LoadingIcon /></span>
                    <div v-for="(parcelType, index) in parcelTypes" :key="index" class="flex items-center me-4 mb-7">
                        <input id="delivery" type="radio" :value="index" name="parcelType" v-model="parcel_type" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2" required/>
                        <label for="delivery" class="ms-2 text-sm font-medium text-gray-900">{{ parcelType }}</label>
                    </div>
                </div>

                <!-- Destination Details (Route) -->
                <div class="mb-7">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Destination Details</label>
                    <input type="text" id="route" class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="destination" placeholder="Enter Destination" required />
                </div>

                <!-- Agent -->
                <div class="mb-7">
                    <label for="agent" class="block mb-2 text-sm font-medium text-gray-900 ">Choose Agent</label>
                    <select name="agent" id="agent" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="agent" :disabled="!destination" required >
                        <option v-if="!filteredAgents.length" value="" disabled>No Agents Available in this area</option>
                        <option v-else-if="!agent" :disabled="!agent" value="">Select agent</option>
                        <option v-for="agent in filteredAgents" :key="agent" :value="agent">{{ agent.collection_shop_name }} - {{ agent.location_name }}</option>
                    </select>

                </div>


                <h2 class="font-bold text-2xl mb-5">Parcel Details</h2>

                <!-- Description -->
                <div class="mb-7">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 ">Description</label>
                    <input type="text" id="description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="parcel_description" placeholder="Enter Parcel Description" required />
                </div>

                <!-- Quantity -->
                <div class="mb-7">
                    <label for="quantity" class="block mb-2 text-sm font-medium text-gray-900 ">Quantity</label>
                    <input type="number" id="quantity" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="parcel_quantity" placeholder="Enter Parcel Quantity" required />
                </div>

                <!-- Value -->
                <div class="mb-7">
                    <label for="value" class="block mb-2 text-sm font-medium text-gray-900 ">Value</label>
                    <input type="number" id="value" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="parcel_value" placeholder="Enter Parcel Value" required />
                </div>
                
                <!-- Time and Date -->
                <div class="grid md:grid-cols-2 md:gap-6">
                    <!-- Date -->
                    <div class="mb-7">
                        <label for="date" class="block mb-2 text-sm font-medium text-gray-900 ">Delivery Date</label>
                        <input type="date" id="date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="selectedDate" placeholder="" required />
    
                    </div>

                    <!-- Time -->
                    <div class="mb-7">
                        <label for="time" class="block mb-2 text-sm font-medium text-gray-900">Delivery Time:</label>
                        <select
                            id="time"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            v-model="selectedTime"
                        >
                            <option value="" disabled>Select time</option>
                            <option v-for="(time, index) in deliveryTimes" :key="index" :value="index">{{ time }}</option>
                        </select>
                    </div>

                </div>
                

                <!-- <div class="block w-full rounded-md flex justify-center">
                    <button type="button" class=" bg-green-400 px-3.5 py-2.5 mb-7 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">+ Add parcel</button>

                </div> -->
                
                <button type="submit" class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                    <span v-if="!$store.state.isLoading">Complete</span>
                    <span v-else ><LoadingIcon /></span>
                </button>
            </form>
        </div>
        
    </div>
</template>

<script>

    import store from '@/store';
    import axios from 'axios'
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import LoadingIcon from './LoadingIcon.vue';

    export default {
        name: 'ParcelDetails',
        data() {
            return {
                currentRoute: this.$route.path,

                // form data
                parcel_type: '',
                route_type: '',
                agent: '',
                destination: '',
                parcel_description: '',
                parcel_quantity: '',
                parcel_value: '',
                parcel_delivery_date: '',
                errors: [],

                selectedDate: '',
                selectedTime: '',

                // fetched data
                // agents: [],
                // deliveryTimes: [],
                // parcel_types: []
            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getAgents', 'getDeliveryTimes', 'getParcelTypes', 'getCustomerDetails']),

            customerDetails() {
                return this.getCustomerDetails
            },

            deliveryTimes() {
                return this.getDeliveryTimes
            },

            parcelTypes() {
                return this.getParcelTypes
            },
            
            agents() {
                return this.getAgents.filter(agent => agent.active)
            },

            filteredAgents() {
                let agentsData = this.agents

                agentsData = agentsData.filter(agent => {
                    const locationMatch = this.destination.trim() === '' || agent.location_name.toLowerCase().includes(this.destination.toLowerCase());

                    return locationMatch
                })

                return agentsData
            }
        },
        methods: {
            ...mapMutations(['setNewParcelDetails', 'setLoadingStatus']),
            ...mapState(['parcelDetails']),
            ...mapActions(['createPackage']),

            async submitForm() {
                this.setLoadingStatus(true)

                if (this.agent === '') {
                    this.errors.push('Please select an agent')
                }

                if (!this.errors.length) {

                    const parcelDetails = {
                        customer: this.customerDetails.customer_id,
                        parcel_type: this.parcel_type,
                        destination: this.agent.location_name,
                        agent: this.agent.agent_id,
                        route_type: localStorage.getItem('route_type'),
                        parcel_description: this.parcel_description,
                        parcel_quantity: this.parcel_quantity,
                        parcel_value: this.parcel_value,
                        parcel_delivery_date: this.selectedDate,
                        parcel_delivery_time: this.selectedTime,
                    }
    
                    await this.setNewParcelDetails(parcelDetails)
    
                    // const response = await this.createPackage()
                    const response = await this.$store.dispatch('createPackage');
    
                    if (response.status === 201) {

                        this.$toast.success(
                            "Package created successfully. Please complete payment to confirm order.",
                            {
                                position: "bottom-right",
                                duration: 3000,
                            }
                        )

                        this.$store.state.parcelsLastFetched = null
                        this.$store.dispatch('fetchNewParcelDetails', response.data.id)

                        setTimeout(() => {
                            this.$router.push(`parcel-details/invoice/${response.data.id}/`)
                        }, 2000);

                    } else {
                        for (const property in response.data) {
                            this.errors.push(`${property} : ${response.data[property]}`)

                            this.$toast.error(
                                `${property}: ${response.data[property]}`,
                                {
                                    position: "bottom-right",
                                    duration: 3000,
                                }
                            )
                        }
                    }

                }

                this.setLoadingStatus(false)
                
            },
        }
    }

</script>