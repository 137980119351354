import PasswordValidation from "./PasswordValidation";

export default class Validations {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    checkValidations() {
        let errors = []

        // check password length
        if (!PasswordValidation.minLength(this.password, 6)) {
            errors.push('Password should be 6 characters')
        }

        // Password has at least 1 uppercase
        if (!PasswordValidation.uppercaseCheck(this.password)) {
            errors.push('Password should have at least 1 uppercase letter')
        }

        // Password has at least 1 lowercase
        if (!PasswordValidation.lowercaseCheck(this.password)) {
            errors.push('Password should have at least 1 lowercase letter')
        }

        // Password has at least 1 number
        if (!PasswordValidation.numberCheck(this.password)) {
            errors.push('Password should have at least 1 number')
        }

        // Password has at least 1 special character
        // if (!PasswordValidation.specialCharacterCheck(this.password)) {
        //     errors.push('Password should have at least 1 special character')
        // }

        if (errors.length) {
            return errors
        } else {
            return true
        }
    }
}