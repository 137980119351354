import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './assets/tailwind.css'
import store from './store'
import axios from 'axios'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css'

axios.defaults.baseURL = 'https://sub.timelydelivery.net'

createApp(App)
  .use(store)
  .use(router, axios)
  .use(ToastPlugin)
  .mount('#app')