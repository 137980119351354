<template>
    
    <router-view />
  
</template>
  
<script>
    import AdminLayout from './components/AdminLayout.vue';

    export default {
        // ...mapMutations(['initializeStore']),

        name: 'Admin',
        components: {
            AdminLayout
        },
    }

</script>


<style lang="scss">
</style>
  