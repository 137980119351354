<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Pending</h2>
                        <p class="text-gray-600">You have <span class="font-semibold">{{ pendingOrders.length }}</span> pending order<span v-if="pendingOrders.length > 1 || pendingOrders.length == 0">s</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="pendingOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Type</th>
                                    <th scope="col" class="px-6 py-3">Receiver</th>
                                    <th scope="col" class="px-6 py-3">Date Received</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in pendingOrders" :key="order.id" class="text-gray-700 divide-y divide-gray-100" :class="order.days_stayed_at_agent > 3 ? 'bg-red-300 hover:bg-red-500' : 'bg-white hover:bg-gray-50'">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/agent/pending/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">{{ order.parcel_type }}</td>
                                    <!-- <td class="px-6 py-3">{{ order.receiver }}</td> -->
                                    <td class="px-6 py-3">
                                        <span v-if="order.status == 'DELIVERED TO DROPOFF AGENT'">DRIVER</span>
                                        <span v-else>CUSTOMER</span>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.status == 'DELIVERED TO DROPOFF AGENT'">{{ order.date_received_at_dropoff_agent.split('T')[0] }}</span>
                                        <span v-else>{{ order.date_delivered_to_agent.split('T')[0] }}</span>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Pending",
    computed: {
        ...mapGetters(['getParcelsByAgentId', 'getAgentDetails']),

        agentDetails() {
            return this.getAgentDetails
        },

        agentParcels() {
            return this.getParcelsByAgentId(this.agentDetails.agent_id);
        },

        pendingOrders() {
            return this.agentParcels.filter(parcel => (parcel.status === 'DELIVERED TO DROPOFF AGENT' && parcel.dropoff_agent === this.agentDetails.agent_id) || (parcel.status === 'DELIVERED TO AGENT' && parcel.agent === this.agentDetails.agent_id));
        }
    },
    async created () {
        await this.$store.dispatch('fetchParcels');
    },
}
</script>