<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-5 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Dashboard</h2>
                        <p class="text-gray-600">Welcome to your dashboard, <span class="font-semibold">{{
                                driverDetails.first_name }} {{ driverDetails.last_name }}</span>!</p>
                    </div>

                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-3 py-4">
                        <router-link to="/users/driver/pending" class="col-span-1 lg:col-span-1">
                            <div class="bg-orange-400 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Pending</h2>
                                <p class="text-white text-xl font-bold">{{ pendingOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/driver/in-transit" class="col-span-1 lg:col-span-1">
                            <div class="bg-orange-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">In Transit</h2>
                                <p class="text-white text-xl font-bold">{{ inTransitOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/driver/completed" class="col-span-1 lg:col-span-1">
                            <div class="bg-green-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Completed</h2>
                                <p class="text-white text-xl font-bold">{{ completedOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/driver/return" class="col-span-1 lg:col-span-1">
                            <div class="bg-red-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Return</h2>
                                <p class="text-white text-xl font-bold">{{ returnOrders.length }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-span-1 lg:col-span-1">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Quick Stats:</h2>
                        <ul class="space-y-2">
                            <li>
                                <router-link to="/users/driver/" class="text-sm text-gray-600">
                                    Total Orders: <span class="font-semibold">{{ driverParcels.length }}</span>
                                </router-link>
                            </li>

                            <hr>

                            <li>
                                <button type="button" @click="logout"
                                    class="font-semibold text-gray-900 hover:text-blue-500">Log out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';

export default {
    name: 'DriverDashboard',
    computed: {
        ...mapGetters(['getParcelsByDriverId', 'getDriverDetails']),

        driverDetails() {
            return this.getDriverDetails
        },

        driverParcels() {
            return this.getParcelsByDriverId(this.driverDetails.driver_id);
        },

        pendingOrders() {
            return this.driverParcels.filter(parcel => (parcel.status === 'PACKAGE IN OFFICE' && parcel.driver === this.driverDetails.driver_id) || (parcel.status === 'DELIVERED TO DROPOFF AGENT' && parcel.dropoff_driver === this.driverDetails.driver_id));
        },

        inTransitOrders() {
            return this.driverParcels.filter(parcel => (parcel.status === 'PACKAGE IN TRANSIT TO OFFICE' && parcel.dropoff_driver === this.driverDetails.driver_id) || (parcel.status === 'PACKAGE IN TRANSIT TO AGENT' && parcel.driver === this.driverDetails.driver_id));
        },

        completedOrders() {
            return this.driverParcels.filter(parcel => (parcel.date_delivered_to_agent && parcel.driver === this.driverDetails.driver_id) || (parcel.status == 'RETURN PACKAGE' && parcel.returning_driver === this.driverDetails.driver_id && parcel.package_return) || (parcel.date_received_at_office && parcel.dropoff_driver === this.driverDetails.driver_id));
        },

        returnOrders() {
            return this.driverParcels.filter(parcel => parcel.status === 'RETURN PACKAGE' && parcel.returning_driver === this.driverDetails.driver_id);
        },
    },
    async created() {
        await this.$store.dispatch('fetchParcels');
    },
    methods: {
        ...mapMutations(['removeDriver']),

        logout() {
            axios.defaults.headers.common['Authorization'] = ''
            this.removeDriver()

            this.$toast.success(
                "Successfully logged out!",
                {
                    position: "bottom-right",
                    duration: 3000,
                }
            )

            this.$router.push('/users/')
        }
    },
}
</script>