<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Completed</h2>
                        <p class="text-gray-600">You have completed <span class="font-semibold">{{ completedOrders.length }}</span> order<span v-if="completedOrders.length > 1 || completedOrders.length == 0">s</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="completedOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Receiver</th>
                                    <th scope="col" class="px-6 py-3">Date Completed</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in completedOrders" :key="order.id" class="text-gray-700 divide-y divide-gray- bg-white">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/driver/pending/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.date_delivered_to_agent">AGENT</span>
                                        <span v-else-if="order.date_received_at_office">OFFICE</span>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.date_delivered_to_agent">{{ order.date_delivered_to_agent.split('T')[0] }}</span>
                                        <span v-else-if="order.date_received_at_office">{{ order.date_received_at_office.split('T')[0] }}</span>
                                    </td>
                                </tr>

                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'DriverCompleted',
    computed: {
        ...mapGetters(['getParcelsByDriverId', 'getDriverDetails', 'getCustomerDetails']),

        driverDetails() {
            return this.getDriverDetails
        },

        driverOrders() {
            return this.getParcelsByDriverId(this.driverDetails.driver_id);
        },

        completedOrders() {
            return this.driverOrders.filter(parcel => (parcel.date_delivered_to_agent && parcel.driver === this.driverDetails.driver_id) || (parcel.status == 'RETURN PACKAGE' && parcel.returning_driver === this.driverDetails.driver_id && parcel.package_return) || (parcel.date_received_at_office && parcel.dropoff_driver === this.driverDetails.driver_id));
        },

    },
}
</script>