<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-5 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Dashboard</h2>
                        <p class="text-gray-600">Welcome to your dashboard, <span class="font-semibold">{{ agentDetails.first_name }} {{ agentDetails.last_name }}</span>!</p>
                    </div>

                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-3 py-4">
                        <router-link to="/users/agent/incoming" class="col-span-1 lg:col-span-1">
                            <div class="bg-orange-400 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Incoming</h2>
                                <p class="text-white text-xl font-bold">{{ incomingOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/agent/pending" class="col-span-1 lg:col-span-1">
                            <div class="bg-orange-400 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Pending</h2>
                                <p class="text-white text-xl font-bold">{{ pendingOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/agent/completed" class="col-span-1 lg:col-span-1">
                            <div class="bg-green-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Completed</h2>
                                <p class="text-white text-xl font-bold">{{ completedOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/agent/return" class="col-span-1 lg:col-span-1">
                            <div class="bg-red-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Overstayed</h2>
                                <p class="text-white text-xl font-bold">{{ overstayedOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/agent/return" class="col-span-1 lg:col-span-1">
                            <div class="bg-gray-700 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Returned</h2>
                                <p class="text-white text-xl font-bold">{{ returnedOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/agent/report" class="col-span-1 lg:col-span-1">
                            <div class="bg-gray-500 shadow-md rounded-md p-4 text-center hover:shadow-xl items-center">
                                <h2 class="text-xl font-semibold text-white mb-4">Report</h2>
                                <div class="items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                    </svg>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-span-1 lg:col-span-1">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Quick Stats:</h2>
                        <ul class="space-y-2">
                            <li>
                                <router-link to="/users/agent/" class="text-sm text-gray-600">
                                    Total Orders: <span class="font-semibold">{{ totalOrders }}</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/users/agent/" class=" text-sm text-gray-600">
                                    Total Earned: <span class="font-semibold">KSHS. {{ agentDetails.total_earned }}</span>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="/users/agent/" class="text-sm text-gray-600">
                                    Total Balance: <span class="font-semibold">KSHS. {{ agentDetails.agent_commission }}</span>
                                </router-link>
                            </li>

                            <hr>

                            <li>
                                <button type="button" @click="logout" class="font-semibold text-gray-900 hover:text-blue-500">Log out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "AgentsDashboard",
    data() {
        return {
            // agentID: agentDetails.agent_id,

            incoming: null
        }
    },
    computed: {
        ...mapGetters(['getParcelsByAgentId', 'getAgentDetails']),

        agentDetails() {
            return this.getAgentDetails
        },

        agentParcels() {
            return this.getParcelsByAgentId(this.agentDetails.agent_id)
        },

        incomingOrders() {
            return this.agentParcels.filter(parcel => parcel.status === 'PACKAGE IN TRANSIT TO AGENT' && parcel.agent === this.agentDetails.agent_id || parcel.dropoff_agent === this.agentDetails.agent_id && parcel.status === 'PACKAGE CREATED')
        },

        pendingOrders() {
            return this.agentParcels.filter(parcel => (parcel.status === 'DELIVERED TO DROPOFF AGENT' && parcel.dropoff_agent === this.agentDetails.agent_id) || (parcel.status === 'DELIVERED TO AGENT' && parcel.agent === this.agentDetails.agent_id));
        },

        completedOrders() {
            return this.agentParcels.filter(parcel => (parcel.status === 'RECEIVED BY RECIPIENT' && parcel.agent === this.agentDetails.agent_id) || (parcel.dropoff_agent === this.agentDetails.agent_id && parcel.date_leaving_for_office))
        },

        overstayedOrders() {
            return this.agentParcels.filter(parcel => parcel.agent === this.agentDetails.agent_id && parcel.days_stayed_at_agent > 3 && parcel.status == 'DELIVERED TO AGENT')
        },

        returnedOrders() {
            return this.agentParcels.filter(parcel => parcel.status === 'RETURN PACKAGE')
        },

        totalOrders() {
            return this.incomingOrders.length + this.pendingOrders.length + this.completedOrders.length + this.returnedOrders.length;
        }
    },
    async created () {
        await this.$store.dispatch('fetchParcels');
        await this.$store.dispatch('fetchAgentDetails', this.agentDetails.agent_id);

    },
    methods: {
        ...mapMutations(['removeAgent']),

        logout() {
            axios.defaults.headers.common['Authorization'] = ''
            this.removeAgent()

            this.$toast.success(
                "Successfully logged out!",
                {
                    position: "bottom-right",
                    duration: 3000,
                }
            )
            
            this.$router.push('/users/')
        }
    },
}
</script>
