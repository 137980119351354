<template>
    <CollectorNavbar />

    <router-view />
</template>

<script>
import CollectorNavbar from '@/users/components/layout/CollectorNavbar.vue'

export default {
    name: "CollectorLayout",
    components: {
        CollectorNavbar,
    },
}
</script>