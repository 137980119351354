<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Return</h2>
                        <p class="text-gray-600">Packages returned: <span class="font-semibold">{{ returnOrders.length }}</span></p>
                        <p class="text-gray-600">Packages pending return: <span class="font-semibold">{{ returnOrders.length }}</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="returnOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Type</th>
                                    <th scope="col" class="px-6 py-3">Receiver</th>
                                    <th scope="col" class="px-6 py-3">Receiver Phone</th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in returnOrders" :key="order.id" class="bg-white hover:bg-gray-100 text-gray-700 divide-y divide-gray-100">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/agent/return/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">{{ order.parcel_type }}</td>
                                    <td class="px-6 py-3">{{ order.receiver }}</td>
                                    <td class="px-6 py-3">0{{ order.receiver_phone_number }}</td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.status == 'RETURN PACKAGE'">RETURNED</span>
                                        <span v-else>PENDING</span>    
                                    </td>
                                </tr>

                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "Return",
    computed: {
        ...mapGetters(['getParcelsByAgentId', 'getAgentDetails']),

        agentDetails() {
            return this.getAgentDetails
        },

        agentParcels() {
            return this.getParcelsByAgentId(this.agentDetails.agent_id);
        },

        returnOrders() {
            // console.log("Overstayed orders: ", this.agentParcels.filter(parcel => parcel.agent === this.agentDetails.agent_id && parcel.days_stayed_at_agent > 3));
            return this.agentParcels.filter(parcel => (parcel.agent === this.agentDetails.agent_id && parcel.days_stayed_at_agent > 3) || parcel.status === 'RETURN PACKAGE')
        },
    },
    async created () {
        await this.$store.dispatch('fetchParcels');
    },
}
</script>