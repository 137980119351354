<template>
    <DriverNavbar />

    <router-view />
</template>

<script>
import DriverNavbar from '@/users/components/layout/DriverNavbar.vue'

export default {
    name: "DriverLayout",
    components: {
        DriverNavbar,
    },
}
</script>