<template>

    <!-- Header -->
    <div class="flex justify-between mt-3 mx-8 md:mx-24 mb-8 items-center">
        <div>
            <button @click="$router.go(-1)"
                class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                        fill="currentColor" />
                </svg>

                <span>Back</span>

            </button>

        </div>

        <div>
            <div class="text-gray-500 font-light tracking-normal text-right text-sm">Step 02/03</div>
            <div class="text-gray-600 font-medium tracking-normal text-right text-sm">Residency Info</div>

        </div>

    </div>

    <form @submit.prevent="submitForm" class="mb-8 mx-8 md:mx-24 w-auto">
        <div class="mb-12">
            <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-2xl">Complete Your
                Profile!</h1>

            <h4 class="mt-2 text-sm font-semibold leading-relaxed tracking-tight text-gray-500 md:text-xl">
                For the purpose of industry regulation, your details are required.
            </h4>
        </div>


        <!-- Phone number -->
        <div class="flex relative z-0 w-full mb-5 group">
            <div
                class="w-1/4 block py-2 px-0 text-xs md:text-base text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none">
                Kenya (+254)
            </div>

            <div class="w-3/4">
                <input type="tel" name="phoneNo" id="phoneNo"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model.number="phone_number" required />

                <label for="phoneNo"
                    class="peer-focus:font-medium absolute text-sm md:text-base text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                    Number</label>
            </div>
        </div>

        <!-- Location -->
        <div class="relative z-0 w-full mb-5 group">

            <input type="text" name="address" id="address"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" " v-model="location" required />
            <label for="address"
                class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Location</label>
        </div>

        <!-- Building -->
        <div class="relative z-0 w-full mb-5 group">

            <input type="text" name="building" id="building"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" " v-model="building" required />
            <label for="building"
                class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Building</label>
        </div>

        <!-- Shop Number -->
        <div class="relative z-0 w-full mb-5 group">

            <input type="text" name="shopNumber" id="shopNumber"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" " v-model="shopNumber" required />
            <label for="shopNumber"
                class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Shop
                Number/House Number</label>
        </div>


        <!-- Register button -->
        <button type="submit"
            class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
            <span v-if="!$store.state.isLoading">Save &amp; Continue</span>
            <span v-else><svg aria-hidden="true" class="mx-3 w-5 h-5 text-gray-200 animate-spin fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg></span>
        </button>

    </form>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
    name: 'ContactInfo',
    data() {
        return {
            phone_number: null,
            location: '',
            building: '',
            shopNumber: '',
            errors: []
        }
    },
    methods: {
        ...mapMutations(['setCustomerRegistrationDetails', 'setLoadingStatus']),
        ...mapActions(['createCustomer']),

        async submitForm() {
            this.setLoadingStatus(true);
            this.errors = [];

            const details = {
                phone_number: this.phone_number,
                location: this.location,
                building: this.building,
                shop_number: this.shopNumber
            };

            this.setCustomerRegistrationDetails(details);

            try {
                const response = await this.createCustomer();

                // Check if response is defined and has the status property
                if (response && response.status) {
                    if (response.status === 200 || response.status === 201) {
                        // Successfully created user
                        this.showSuccessNotification('Registration successful. Please verify your email to activate your account.');
                        this.$router.push('/register/email-verification');
                    } else {
                        // Handle non-201 status codes
                        if (response.data) {
                            for (const property in response.data) {
                                this.showErrorNotification(`${property} : ${response.data[property]}`);
                            }
                        } else {
                            this.showErrorNotification('Unexpected error occurred.');
                        }
                    }
                } else {
                    this.showErrorNotification('Something went wrong. Please try again!');
                }
            } catch (error) {
                this.showErrorNotification('Something went wrong. Please try again!');
            }
            this.setLoadingStatus(false);
        },
        showSuccessNotification(message) {
            this.$toast.success(message, {
                position: 'bottom-right',
                duration: 3000,
            });
        },
        showErrorNotification(message) {
            this.$toast.error(message, {
                position: 'bottom-right',
                duration: 3000,
            });
        },

    }
}
</script>