<template>
    <div class="items-center bg-gray-200 pb-10">

        <div class="items-center py-12">
            <h1 class="text-center text-2xl text-black font-bold">Select Mode</h1>
        </div>

        <div class="border-b border-gray-200">
            <div class="flex md:mx-24 w-auto justify-around">
                <ul class="flex pb-8 justify-between">
                    <li class="mx-2.5 my-2" v-for="(tab, index) in tabs" :key="index">
                        <button class="text-base font-medium text-center inline-block px-4 pb-2.5 rounded-t-lg"
                            :class="{ 'text-blue-600 border-b-4 border-blue-600': tab === activeTab, 'text-gray-500 hover:text-gray-800 hover:border-gray-500': tab !== activeTab }"
                            @click="activeTab = tab">
                                {{ tab }}
                        </button>
                    </li>
                </ul>
            </div>


            <div v-if="activeTab === 'Collection'">
                <!-- {{ activeTab }} tab active -->
                <form @submit.prevent="submitForm" class="mb-8 md:mx-24 w-auto items-center px-8 md:px-52">
                    <p class="text-sm text-gray-500 mb-5">Have your order collected at your shop/office</p>
                    <!-- Location -->
                    <h2 class="font-bold text-2xl mb-5">Location Details</h2>

                    <!-- Pickup location -->
                    <div class="mb-7">
                        <label for="location" class="block mb-2 text-sm font-medium text-gray-900 ">Pick up location</label>
                        <input type="text" id="location" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter your location" v-model="pickup_location" required />
                    </div>

                    <!-- Building -->
                    <div class="mb-7">
                        <label for="building" class="block mb-2 text-sm font-medium text-gray-900 ">Building</label>
                        <input type="text" id="building" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Building Name" v-model="pickup_building" required />
                    </div>

                    <!-- Shop/office no. -->
                    <div class="mb-7">
                        <label for="shop" class="block mb-2 text-sm font-medium text-gray-900 ">Shop / Office No</label>
                        <input type="text" id="shop" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Shop or Office Number" v-model="pickup_shop_no" required />
                    </div>


                    <h2 class="font-bold text-2xl mb-5">Receiver's Details</h2>

                    <!-- Receiver's Name -->
                    <div class="mb-7">
                        <label for="receiverName" class="block mb-2 text-sm font-medium text-gray-900 ">Receiver's Name</label>
                        <input type="text" id="receiverName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Name" v-model="receiver" required />
                    </div>

                    <!-- Phone Number -->
                    <div class="mb-7">
                        <label for="receiverPhoneNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                        <input type="phone" id="receiverPhoneNumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Phone Number" v-model="receiver_phone_number" required />
                    </div>

                    <!-- email (Optional) -->
                    <div class="mb-7">
                        <label for="receiverEmail" class="block mb-2 text-sm font-medium text-gray-900 ">Email (Optional)</label>
                        <input type="email" id="receiverEmail" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter email" v-model="receiver_email" />
                    </div>

                    <button type="submit" class="block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Proceed</button>
                </form>
            </div>

            <div v-if="activeTab === 'Drop Off'">
                <!-- {{ activeTab }} tab active -->
                <form @submit.prevent="submitForm" class="mb-8 md:mx-24 w-auto items-center px-8 md:px-52">
                    <p class="text-sm text-gray-500 mb-5">Drop off your order at one of our offices</p>
                    <!-- Location -->
                    <h2 class="font-bold text-2xl mb-5">Select Location</h2>

                    <div class="mb-7">
                        <!-- Agents -->

                        <div v-for="agent in agents" :key="agent.agent_id" class="flex items-center mb-4">
                            <input :id="agent.agent_id" type="radio" :value="agent.agent_id" name="dropOffLocation" v-model="dropoff_agent" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2">
                            <label :for="dropoff_agent" class="ms-2 text-sm font-medium text-gray-900">{{ agent.collection_shop_name }}</label>

                        </div>
                        
                    </div>


                    <!-- Receiver Details -->
                    <h2 class="font-bold text-2xl mb-3">Receiver's Details</h2>

                    <!-- Receiver's Name -->
                    <div class="mb-7">
                        <label for="receiverName" class="block mb-2 text-sm font-medium text-gray-900 ">Receiver's Name</label>
                        <input type="text" id="receiverName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Name" v-model="receiver" required />
                    </div>

                    <!-- Phone Number -->
                    <div class="mb-7">
                        <label for="receiverPhoneNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                        <input type="phone" id="receiverPhoneNumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Phone Number" v-model="receiver_phone_number" required />
                    </div>

                    <!-- email (Optional) -->
                    <div class="mb-7">
                        <label for="receiverEmail" class="block mb-2 text-sm font-medium text-gray-900 ">Email (Optional)</label>
                        <input type="email" id="receiverEmail" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Shop or Office Number" />
                    </div>

                    <button type="submit" class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        <span v-if="!$store.state.isLoading">Proceed</span>
                        <span v-else ><LoadingIcon /></span>
                    </button>
                </form>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import axios from 'axios';
import LoadingIcon from './LoadingIcon.vue';
import Agents from '@/admin/views/agents/Agents.vue';

    export default {
        name: 'ModeSelection',
        data() {
            return {
                currentRoute: this.$route.path,
                errors: [],

                tabs: ['Collection', 'Drop Off'],
                activeTab: 'Collection',
                dropoff_agent: '',
                dropoff_destination: '',

                // Collection details
                pickup_location: '',
                pickup_building: '',
                pickup_shop_no: '',
                
                // Receiver details
                receiver: '',
                receiver_phone_number: '',
                receiver_email: null


            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getAgents']),

            agents() {
                return this.getAgents.filter(agent => agent.location_name === 'Office')
            }
        },
        methods: {
            ...mapMutations(['setNewParcelDetails', 'setLoadingStatus']),

            async submitForm() {
                this.setLoadingStatus(true)

                if (this.activeTab === 'Collection') {
                    const parcelPickUpDetails = {
                        pickup_location: this.pickup_location,
                        pickup_building: this.pickup_building,
                        pickup_shop_no: this.pickup_shop_no,
                        receiver: this.receiver,
                        receiver_phone_number: this.receiver_phone_number,
                        receiver_email: this.receiver_email
                    }
    
                    this.setNewParcelDetails(parcelPickUpDetails)

                } else if (this.activeTab === 'Drop Off') {
                    const parcelDropoffDetails = {
                        pickup_location: '',
                        pickup_building: '',
                        pickup_shop_no: '',
                        dropoff_agent: this.dropoff_agent,
                        dropoff_destination: 'Office',
                        receiver: this.receiver,
                        receiver_phone_number: this.receiver_phone_number,
                        receiver_email: this.receiver_email
                    }
    
                    this.setNewParcelDetails(parcelDropoffDetails)

                }

                this.setLoadingStatus(false)
                this.$router.push(this.currentRoute + '/parcel-details')
            }
        }
    }
</script>