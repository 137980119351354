<template>
    <div class="text-justify bg-gray-200 px-9 md:px-24 py-9 md:py-20">
        <div class="font-bold text-lg md:text-4xl text-gray-900 text-center mb-5">Frequently Asked Questions (FAQ) - Timely Delivery Services</div>
        
        
        <ol class="list-decimal">
            <li class="font-bold text-xl mb-5">What advantages does Timely Delivery's parcel delivery service offer?</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery's parcel delivery service to designated agents for client pickup offers unparalleled convenience, flexibility, reliability, and affordability. Clients can conveniently collect their parcels from strategically located agents, choose pickup locations and times that suit them best, and benefit from upfront payment security and reduced delivery costs.</div>
            
            <li class="font-bold text-xl mb-5">Where are Timely Delivery's CBD offices located, and what are their operational hours?</li>
            <div class="leading-loose font-gray-700 mb-5">Our town offices are situated at Dynamic Mall, adjacent to National Archives, 4th Floor. Operational hours are Monday to Friday, 8:30 AM to 7:30 PM, and Saturday, 9:00 AM to 7:00 PM. We are closed on Sundays.</div>
            
            <li class="font-bold text-xl mb-5">What items are excluded from Timely Delivery's services?</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery does not handle the delivery of money, food, flowers, groceries, explosives, ammunition, flammable items, pets, ashes, human remains, certain fragile products, or bulky and heavy packages.</div>
            
            <li class="font-bold text-xl mb-5">What are the packaging guidelines for parcels?</li>
            <div class="leading-loose font-gray-700 mb-5">Packages must be properly sealed and labelled with sender and receiver details. Cosmetics products made of glass packaging should be appropriately protected. Non-compliance may result in refusal of delivery.</div>
            
            <li class="font-bold text-xl mb-5">What are the delivery policies for Timely Delivery's agents?</li>
            <div class="leading-loose font-gray-700 mb-5">Same-day delivery is available to agents within Nairobi through specified delivery schedules. Delivery to out-of-county agents is the next day for packages dropped before specific times. Same-day delivery within Nairobi is also available for packages dropped before a certain time.</div>
            
            <li class="font-bold text-xl mb-5">How can I send a package through Timely Delivery?</li>
            <div class="leading-loose font-gray-700 mb-5">Packages must be documented and labelled accurately using the TIMELYDELIVERY.NET (WEB) or APP. A digital receipt will be generated for tracking. Notification via Instagram DM or WhatsApp is required for delivery, with payments processed through the WEB/APP (TIMELYDELIVERY.NET).</div>
            
            <li class="font-bold text-xl mb-5">What is the package collection period and return policy?</li>
            <div class="leading-loose font-gray-700 mb-5">Packages sent to Pickup Points outside Nairobi CBD remain for three days. Timely Delivery is not liable for uncollected returns after this period.</div>
            
            <li class="font-bold text-xl mb-5">How do I report a claim for loss or damage?</li>
            <div class="leading-loose font-gray-700 mb-5">Claims must be reported within 3 days of delivery. Timely Delivery shall not be held liable for claims made after this period. The maximum payable amount for any claim is Kshs. 2000.</div>
            
            <li class="font-bold text-xl mb-5">What is the refund procedure for damaged or missing deliveries?</li>
            <div class="leading-loose font-gray-700 mb-5">Report damaged or tampered packages immediately to customer service for necessary documentation and refunds. Timely Delivery refrains from delivering fragile products and shall not be held liable for damage claims.</div>
            
            <li class="font-bold text-xl mb-5">What are the requirements for package collection?</li>
            <div class="leading-loose font-gray-700 mb-5">Recipients must present either the notification message from Timely Delivery or valid identification for package collection. Agent locations and operational hours are available on TIMELYDELIVERY.NET.</div>
            
            <li class="font-bold text-xl mb-5">What are the home/office delivery policies?</li>
            <div class="leading-loose font-gray-700 mb-5">Same-day doorstep delivery is available for packages dropped before specified times. Delivery payments are made to Paybill number 4130605. Specific delivery times are not guaranteed.</div>
            
            <li class="font-bold text-xl mb-5">Can the terms and conditions be modified?</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery reserves the right to modify these terms without prior notice. Continued use of Timely Delivery's services constitutes acceptance of the modified terms.</div>
            
            <div class="leading-loose font-gray-700 mb-5">For further inquiries or assistance, please contact our customer service team.</div>
        </ol>

    </div>    
    

</template>

<script>
    export default {
        name: 'FAQ'
    }
</script>