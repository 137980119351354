<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-5 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Dashboard</h2>
                        <p class="text-gray-600">Welcome to your dashboard, <span class="font-semibold">{{ collectorDetails.first_name }} {{ collectorDetails.last_name }}</span>!</p>
                    </div>

                    <div class="grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2 lg:grid-cols-3 py-4">
                        <router-link to="/users/collector/pending" class="col-span-1 lg:col-span-1">
                            <div class="bg-orange-400 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Pending</h2>
                                <p class="text-white text-xl font-bold">{{ pendingOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/collector/collected" class="col-span-1 lg:col-span-1">
                            <div class="bg-green-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Collected</h2>
                                <p class="text-white text-xl font-bold">{{ collectedOrders.length }}</p>
                            </div>
                        </router-link>
                        <router-link to="/users/collector/completed" class="col-span-1 lg:col-span-1">
                            <div class="bg-red-500 shadow-md rounded-md p-4 text-center hover:shadow-xl">
                                <h2 class="text-xl font-semibold text-white mb-4">Completed</h2>
                                <p class="text-white text-xl font-bold">{{ completedOrders.length }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-span-1 lg:col-span-1">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Quick Stats:</h2>
                        <ul class="space-y-2">
                            <li>
                                <router-link to="/users/collector/" class="text-sm text-gray-600">
                                    Total Orders: <span class="font-semibold">{{ collectorParcels.length }}</span>
                                </router-link>
                            </li>

                            <hr>

                            <li>
                                <button type="button" @click="logout"
                                    class="font-semibold text-gray-900 hover:text-blue-500">Log out</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios';

export default {
    name: 'CollectorDashboard',
    computed: {
        ...mapGetters(['getParcelsByCollectorId', 'getCollectorDetails']),

        collectorDetails() {
            return this.getCollectorDetails
        },

        collectorParcels() {
            return this.getParcelsByCollectorId(this.collectorDetails.collector_id);
        },

        pendingOrders() {
            return this.collectorParcels.filter(parcel => parcel.status === 'PACKAGE CREATED');
        },

        collectedOrders() {
            return this.collectorParcels.filter(parcel => parcel.status === 'COLLECTED BY COLLECTOR');
        },

        completedOrders() {
            return this.collectorParcels.filter(parcel => parcel.status !== 'COLLECTED BY COLLECTOR' && parcel.status !== 'PACKAGE CREATED');
        },
    },
    async created () {
        await this.$store.dispatch('fetchParcels');
    },
    methods: {
        ...mapMutations(['removeCollector']),

        logout() {
            axios.defaults.headers.common['Authorization'] = ''
            this.removeCollector()

            this.$toast.success(
                "Successfully logged out!",
                {
                    position: "bottom-right",
                    duration: 3000,
                }
            )
            
            this.$router.push('/users/')
        }
    },
}
</script>