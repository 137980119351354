<template>  
  <router-view/>
  <!-- <Notification ref="notification" /> -->
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
import Navbar from '@/components/layout/Navbar';
import Footer from '@/components/layout/Footer';
import Notification from '@/components/Notification.vue';
import store from './store';

export default {
  ...mapMutations(['initializeStore']),
  name: 'App',
  components: {
    Navbar,
    Footer,
    Notification,
  },
  beforeCreate() {
    this.$store.commit('initializeStore');

    if (this.$store.state.token) {
      axios.defaults.headers.common['Authorization'] = "Bearer " + store.state.token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }

    
  },
  methods: {
    showSuccessNotification(message) {
      this.$refs.notification.showNotification(message, true);
    },
    showErrorNotification(message) {
      this.$refs.notification.showNotification(message, false);
    },
  },
};
</script>