<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Collected</h2>
                        <p class="text-gray-600">You have collected <span class="font-semibold">{{ collectedOrders.length }}</span> order<span v-if="collectedOrders.length > 1 || collectedOrders.length == 0">s</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="collectedOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Building</th>
                                    <th scope="col" class="px-6 py-3">Shop No.</th>
                                    <th scope="col" class="px-6 py-3">Date Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in collectedOrders" :key="order.id" class="text-gray-700 divide-y divide-gray-100" :class="order.days_stayed_at_agent > 3 ? 'bg-red-300 hover:bg-red-500' : 'bg-white hover:bg-gray-50'">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/collector/pending/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">{{ order.pickup_building }}</td>
                                    <td class="px-6 py-3">{{ order.pickup_shop_no }}</td>
                                    <td class="px-6 py-3">{{ order.date_collected.split('T')[0] }}</td>
                                </tr>

                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Collected',
    computed: {
        ...mapGetters(['getParcelsByCollectorId', 'getCollectorDetails', 'getCustomerDetails']),

        collectorDetails() {
            return this.getCollectorDetails
        },

        collectorOrders() {
            return this.getParcelsByCollectorId(this.collectorDetails.collector_id);
        },

        collectedOrders() {
            return this.collectorOrders.filter(parcel => parcel.status === 'COLLECTED BY COLLECTOR');
        },

    },
}
</script>