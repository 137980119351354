<template>
    <!-- mobile menu -->
    <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" @click="openSidebar">
        <span class="sr-only">Open sidebar</span>
        <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        </svg>
    </button>

    <!-- Mobile menu -->
    <div v-if="isSidebarOpen" class="absolute h-full left-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="h-full px-3 py-4 overflow-y-auto bg-blue-900">
            
            <div>
                <div class="px-4 py-3">
                    <router-link to="/admin" class="flex items-center ps-2.5 mb-5" @click="openSidebar">
                        <img src="../../assets/long-logo.png" alt="Timely Delivery">
                    </router-link>
                </div>

                <hr />

                <router-link to="/admin" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1" user-menu-item-0 >Dashboard</router-link>
                <router-link to="/admin/customers" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Customers</router-link>
                <router-link to="/admin/agents" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Agents</router-link>
                <router-link to="/admin/riders" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Riders</router-link>
                <router-link to="/admin/collectors" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Collectors</router-link>
                <!-- <router-link to="/admin/orders" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Orders</router-link> -->

                <button type="button" class="flex items-center gap-4 justify-between text-white rounded-lg hover:text-blue-300 group" @click="openOrderMenu()">
                    <span class="ms-3 p-2 ">Orders</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6" :class="[ !isOrderMenuOpen ? '' : 'rotate-180' ]">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>

                <ul v-if="isOrderMenuOpen">
                    <li>
                        <router-link to="/admin/orders" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="openSidebar" >All Orders</router-link>
                    </li>
                    <li>
                        <router-link to="/admin/cod-orders" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="openSidebar" >COD Orders</router-link>
                    </li>
                </ul>
                
                <router-link to="/admin/transactions" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Transactions</router-link>
                <router-link to="/admin/requests" @click="openSidebar" class="block px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1"  >Withdrawals 
                    <span v-if="withdrawals" class="rounded-full bg-white ml-2 px-3 py-1 text-black font-semibold truncate">{{ withdrawals }}</span>
                </router-link>
                <router-link to="/admin/services" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="openSidebar" >Services</router-link>

                <button type="button" class="flex items-center gap-4 justify-between text-white rounded-lg hover:text-blue-300 group" @click="openReportsMenu()">
                    <span class="ms-3 p-2 ">Reports</span>
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6" :class="[ !isReportsMenuOpen ? '' : 'rotate-180' ]">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                    </svg>
                </button>

                <ul v-if="isReportsMenuOpen">
                    <li>
                        <router-link to="/admin/report/agents" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="openSidebar" >Agents</router-link>
                    </li>
                    <li>
                        <router-link to="/admin/report/cod-customers-report" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="openSidebar" >Customers (COD)</router-link>
                    </li>
                </ul>

                <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10">
                
                <button type="button" @click="logout()" class="px-4 py-2 text-base text-white hover:bg-gray-300" role="menuitem" tabindex="-1" user-menu-item-2 >Log out</button>

            </div>
        </div>
    </div>

    <!-- sidebar -->
    <aside id="default-sidebar" aria-label="Sidebar" class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 ">
        <div class="h-full px-3 py-4 overflow-y-auto bg-blue-900">
            <router-link to="/admin" class="flex items-center ps-2.5 mb-5" @click="pageActive = 'dashboard'">
                <img src="../../assets/long-logo.png" alt="Timely Delivery">
            </router-link>

            <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10">

            <ul class="space-y-2 font-medium">
                <li>
                    <router-link to="/admin" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'dashboard'" :class="{ 'text-blue-300' : pageActive === 'dashboard' }">Dashboard</router-link>
                </li>
                <li>
                    <router-link to="/admin/customers" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'customers'" :class="{ 'text-blue-300' : pageActive === 'customers'}" >Customers</router-link>
                </li>
                <li>
                    <router-link to="/admin/agents" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'agents'" :class="{ 'text-blue-300' : pageActive === 'agents'}" >Agents</router-link>
                </li>
                <li>
                    <router-link to="/admin/riders" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'drivers'" :class="{ 'text-blue-300' : pageActive === 'drivers'}" >Drivers</router-link>
                </li>
                <li>
                    <router-link to="/admin/collectors" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'collectors'" :class="{ 'text-blue-300' : pageActive === 'collectors'}" >Collectors</router-link>
                </li>
                <li>
                    <button type="button" class="flex items-center gap-4 justify-between text-white rounded-lg hover:text-blue-300 group" @click="openOrderMenu()">
                        <span class="ms-3 p-2 ">Orders</span>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6" :class="[ !isOrderMenuOpen ? '' : 'rotate-180' ]">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                        </svg>
                    </button>

                    <ul v-if="isOrderMenuOpen">
                        <li>
                            <router-link to="/admin/orders" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="pageActive = 'orders'" :class="{ 'text-blue-300' : pageActive === 'orders'}" >All Orders</router-link>
                        </li>
                        <li>
                            <router-link to="/admin/cod-orders" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="pageActive = 'codOrders'" :class="{ 'text-blue-300' : pageActive === 'codOrders'}" >COD Orders</router-link>
                        </li>
                    </ul>
                </li>
                <li>
                    <router-link to="/admin/transactions" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'transactions'" :class="{ 'text-blue-300' : pageActive === 'transactions'}" >Transactions</router-link>
                </li>
                <li>
                    <router-link to="/admin/requests" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'requests'" :class="{ 'text-blue-300' : pageActive === 'requests'}" >Withdrawals 
                        <span v-if="withdrawals" class="rounded-full bg-white ml-2 px-3 py-1 text-black font-semibold truncate">{{ withdrawals }}</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/admin/services" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="pageActive = 'services'" :class="{ 'text-blue-300' : pageActive === 'services'}" >Services</router-link>
                </li>
                <li>
                    <button type="button" class="flex items-center gap-4 justify-between text-white rounded-lg hover:text-blue-300 group" @click="openReportsMenu()">
                        <span class="ms-3 p-2 ">Reports</span>
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6" :class="[ !isReportsMenuOpen ? '' : 'rotate-180' ]">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
                        </svg>
                    </button>

                    <ul v-if="isReportsMenuOpen">
                        <li>
                            <router-link to="/admin/report/agents" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="pageActive = 'agentsReport'" :class="{ 'text-blue-300' : pageActive === 'agentsReport'}" >Agents</router-link>
                        </li>
                        <li>
                            <router-link to="/admin/report/cod-customers-report" class="flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:text-blue-300 " @click="pageActive = 'customersReport'" :class="{ 'text-blue-300' : pageActive === 'customersReport'}" >Customers (COD)</router-link>
                        </li>
                    </ul>
                </li>
                
                <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10">
            
                <li>
                    <button type="button" class="flex items-center ms-3 p-2 text-white rounded-lg hover:text-blue-300 group" @click="logout()" >Logout</button>
                </li>

            </ul>
        </div>

    </aside>

    <div class="p-4 md:ml-64">
        <router-view />
    </div>



</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

    export default {
        name: 'adminLayout',
        data() {
            return {
                pageActive: 'dashboard',

                isSidebarOpen: false,

                isOrderMenuOpen: false,

                isReportsMenuOpen: false,

            }
        },
        computed: {
            ...mapGetters(['getWithdrawals']),

            withdrawals() {
                return this.getWithdrawals.filter(withdrawal => withdrawal.disbursment_status == 'PENDING').length;
            }
        },
        methods: {
            openSidebar() {
                this.isSidebarOpen = !this.isSidebarOpen
            },

            openOrderMenu() {
                this.isOrderMenuOpen = !this.isOrderMenuOpen
            },
            
            openReportsMenu() {
                this.isReportsMenuOpen = !this.isReportsMenuOpen
            },

            logout() {
                axios.defaults.headers.common['Authorization'] = ''
                this.$store.commit('removeAdmin')

                this.$toast.success(
                    `Successfully logged out!`,
                    {
                        position: 'bottom-right',
                        duration: 4000
                    }
                )

                this.$router.push('/adminLogin')
            }
        }
    }
</script>