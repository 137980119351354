<template>
    <form @submit.prevent="submitForm" class="my-8 md:mx-24 w-auto">
        <div class="mb-12 text-center">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">Log in to your account
            </h1>

            <h4 class="mt-2 text-base font-semibold leading-tight tracking-tight text-gray-500 md:text-xl">Welcome back!
                Select method to log in</h4>

        </div>

        <!-- Show errors if any -->
        <!-- <div v-if="errors.length" class="mb-5 bg-red-200 p-6">
            <h2 class="text-red-800 text-base font-medium">Error: </h2>
            <p v-for="error in errors" :key="error" class="mt-2 text-red-600 text-sm font-medium">{{ error }}</p>
        </div> -->

        <!-- Email -->
        <div class="relative z-0 w-full mb-5 group">
            <input name="email" id="email" type="email"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" " v-model="email" required>
            <label for="email"
                class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
        </div>

        <!-- Password -->
        <div class="relative z-0 w-full mb-5 group">
            <input name="password" id="password" type="password"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                placeholder=" " v-model="password" required>
            <label for="password"
                class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
        </div>

        <!-- Remember me & Forgot password -->
        <div class="flex items-start mb-5 justify-between">
            <div class="flex items-center h-5">
                <input type="checkbox" name="remember" id="remember"
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-orange-300"
                    v-model="rememberMe" />
                <label for="remember" class="ms-2 text-sm font-medium text-gray-900">Remember me</label>
            </div>

            <div class="flex items-center h-5">
                <router-link to="/login/forgot-password"
                    class="ms-2 text-sm font-medium text-gray-900 hover:text-orange-500">Forgot password?</router-link>
            </div>

        </div>

        <!-- Submit -->
        <button type="submit"
            class="flex justify-center block w-full rounded-md bg-blue-600 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
            :class="{ 'bg-blue-200': $store.state.isLoading }" :disabled="$store.state.isLoading">
            <span v-if="!$store.state.isLoading">Log in</span>
            <span v-else><svg aria-hidden="true" class="mx-3 w-5 h-5 text-gray-200 animate-spin fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg></span>
        </button>


        <!-- Or divider -->
        <!-- <div class="inline-flex items-center justify-center w-full">
            <hr class="w-64 h-px my-8 bg-gray-500 border-0">
            <span class="absolute px-3 font-medium text-gray-500 bg-white">or</span>
        </div> -->

        <!-- Google sign in -->
        <!-- <button type="button" class="text-black bg-white hover:bg-orange-500/90 focus:ring-4 focus:outline-none focus:ring-orange-500/50 font-medium rounded-lg w-full text-md px-5 py-2.5 text-center flex items-center justify-center me-2 mb-2 shadow-lg">
            <svg class="w-4 h-4 me-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 19">
                <path fill-rule="evenodd" d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z" clip-rule="evenodd"/>
            </svg>
            Sign in with Google
        </button> -->

        <p class="text-center text-sm font-light text-gray-500 mt-4">Don't have an account yet?
            <router-link to="/register" class="font-medium text-blue-600 hover:underline">Register</router-link>
        </p>
    </form>

</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            rememberMe: false,
            errors: [],
            id: ''
        }
    },
    methods: {
        ...mapMutations(['setToken', 'removeToken', 'setUser', 'setLoadingStatus']),

        async submitForm() {
            this.setLoadingStatus(true)

            axios.defaults.headers.common['Authorization'] = ''
            localStorage.removeItem('token')
            this.errors = []

            const formData = {
                email: this.email,
                password: this.password,
            }

            // login
            await axios
                .post('/login/', formData)
                .then(async (response) => {
                    const results = response.data

                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + results.access

                    this.$store.dispatch('fetchCustomerDetails', results.user.customer_id)
                    this.$store.dispatch('fetchAllParcels');
                    this.$store.dispatch('fetchAgents');
                    this.$store.dispatch('fetchDeliveryTimes');
                    this.$store.dispatch('fetchParcelTypes');
                    this.$store.dispatch('fetchPackageStatuses');
                    this.$store.dispatch('fetchWithdrawals');
                    this.$store.dispatch('fetchTransactions');

                    localStorage.setItem('email', this.email); // Store email in localStorage

                    if (this.password === 'Pa$$w0rd!') { // Check for default password
                        console.log("Default password detected");
                        this.showSuccessNotification('Please change your password');
                        
                        this.$router.push('/reset-password'); // Redirect to ResetPassword
                    } else {
                        this.showSuccessNotification('Successfully logged in');
                        this.$router.push('/');
                    }
                })
                .catch((error) => {

                    if (error.response) {
                        for (const property in error.response.data) {
                            this.showErrorNotification(`${error.response.data[property]}`)
                        }
                    } else if (error.message) {
                        this.showErrorNotification("Something went wrong. Please try again!")
                    }
                })

            this.setLoadingStatus(false)

        },

        async getCustomerDetails(customerID) {
            await axios
                .get(`/customers/${customerID}`)
                .then((response) => {
                    const customer = response.data

                    localStorage.setItem('walletAmount', customer.wallet_amount)
                    localStorage.setItem('userPhone', customer.phone_number)

                    // console.log("Customer details: ", customer)

                    this.setUser(customer)
                })
                .catch((error) => {
                    // console.error("Error fetching customer details: ", error)

                    this.showErrorNotification("Something went wrong while fetching customer details. Please try again!")
                })
        },

        showSuccessNotification(message) {
            this.$toast.success(message, {
                position: 'bottom-right',
                duration: 3000,
            });
        },
        showErrorNotification(message) {
            this.$toast.error(message, {
                position: 'bottom-right',
                duration: 3000,
            });

        },
    },
}
</script>