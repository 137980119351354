import router from '@/router';
import axios from 'axios';
import { useToast } from 'vue-toast-notification';
import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    isAuthenticated: false,
    isLoading: false,
    
    isAdminAuthenticated: false,
    admin: [],
    adminLastFetched: null,

    user: {},

    customerRegistrationDetails: {},

    pickUpDetails: {},
    newParcelDetails: [],
    dropoffDetails: {},

    services: [],
    packageStatuses: [],
    deliveryTimes: [],
    parcelTypes: [],
    zones: [],

    transactions: [],
    transactionsLastFetched: null,
    withdrawals: [],
    withdrawalsLastFetched: null,

    parcels: [],
    parcelsLastFetched: null,
    newParcel: [],

    isUserAuthenticated: false,

    agents: [],
    agentsLastFetched: null,
    agentDetails: [],
    agentLastFetched: [],
    
    collectors: [],
    collectorsLastFetched: null,
    collectorDetails: [],
    collectorLastFetched: [],

    drivers: [],
    driversLastFetched: null,
    driverDetails: [],
    driverLastFetched: [],

    customers: [],
    customersLastFetched: null,
    customerDetails: [],
    customerLastFetched: [],

    companyPhoneNumber: null,

  },
  getters: {
    getServices: state => state.services,

    companyPhoneNumber: state => state.companyPhoneNumber,

    getZones: (state) => state.zones,

    getPackageStatuses: state => state.packageStatuses,

    getDeliveryTimes: state => state.deliveryTimes,

    getParcelTypes: state => state.parcelTypes,

    getWithdrawals: state => {
      return state.withdrawals.map( withdrawal => {
        const customer = state.customers.find(customer => customer.customer_id == withdrawal.customer)
        const customerName = customer.first_name + ' ' + customer.last_name
        return {
          ...withdrawal,
          customer_name: customerName
        }
      })
    },

    getWithdrawalsByCustomerId: state => (customerId) => {
      return state.withdrawals.filter(withdrawal => withdrawal.customer == customerId)
    },

    getWithdrawalsByRequestId: state => (requestId) => {
      return state.withdrawals.find(withdrawal => withdrawal.request_id == requestId)
    },

    getTransactions: (state) => {
      return state.transactions
    },

    getTransactionByReceiptNo: (state) => (receiptNo) => {
      return state.transactions.find(transaction => transaction.receipt_no === receiptNo)
    },
    
    getParcelsByAgentId: (state) => (agentId) => {
      return state.parcels.filter(parcel => parcel.payment_status === 'PAID' && (parcel.agent === agentId || parcel.dropoff_agent === agentId))
    },

    getParcelById: (state) => (parcelId) => {
      return state.parcels.find(parcel => parcel.id === parcelId)
    },

    getParcelsByCollectorId: (state) => (collectorId) => {
      return state.parcels.filter(parcel => parcel.payment_status === 'PAID' && parcel.collector === collectorId)
    },

    getParcelsByDriverId: (state) => (driverId) => {
      return state.parcels.filter(parcel => parcel.payment_status === 'PAID' && (parcel.driver === driverId || parcel.return_driver === driverId || parcel.dropoff_driver === driverId))
    },

    getParcelsByCustomerId: (state) => (customerId) => {
      return state.parcels
        .filter(parcel => parcel.customer === customerId)
        .map(parcel => {
          const agent = state.agents.find(agent => agent.agent_id === parcel.agent)
          return {
            ...parcel,
            agent: agent ? agent.collection_shop_name : 'N/A'
          }
        })
    },

    getPaidParcels: (state) => {
      return state.parcels.filter(parcel => parcel.payment_status === 'PAID')
    },

    getParcelByTransactionId: (state) => (transactionId) => {
      return state.parcels.filter(parcel => parcel.transaction_id === transactionId);
    },

    getParcelByCodConfirmationCode: (state) => (confirmationCode) => {
      return state.parcels.filter(parcel => parcel.COD_Confirmation_code === confirmationCode);
    },

    getAllParcels: (state) => {
      return state.parcels.map(parcel => {
        const collector = state.collectors.find(collector => collector.collector_id === parcel.collector);
        const driver = state.drivers.find(driver => driver.driver_id === parcel.driver);
        return {
          ...parcel,
          collector: collector ? `${collector.first_name} ${collector.last_name}` : '',
          driver: driver ? `${driver.first_name} ${driver.last_name}` : ''
        };
      });
    },

    getNewParcel: (state) => {
      return state.newParcel
    },

    getAgentDetails: (state) => state.agentDetails,

    getAgentById: (state) => (agentId) => {
      return state.agents.find(agent => agent.agent_id === agentId)
    },

    getAgents: (state) => {
      return state.agents.map(agent => {
        const agentParcels = state.parcels.filter(parcel => parcel.payment_status == 'PAID' && (parcel.agent === agent.agent_id || parcel.dropoff_agent === agent.agent_id));
        const totalPackages = agentParcels.length;
        const completedPackages = agentParcels.filter(parcel => (parcel.status === 'RECEIVED BY RECIPIENT' && parcel.agent === agent.agent_id) || (parcel.dropoff_agent === agent.agent_id && parcel.date_leaving_for_office)).length
        const completedCodPackages = agentParcels.filter(parcel => ((parcel.status === 'RECEIVED BY RECIPIENT' && parcel.agent === agent.agent_id) || (parcel.dropoff_agent === agent.agent_id && parcel.date_leaving_for_office)) && parcel.COD_Confirmation).length
        const returnedPackages = agentParcels.filter(parcel => parcel.status === 'RETURN PACKAGE').length
        const ownerName = agent.first_name + ' ' + agent.last_name

        return {
          ...agent,
          owner_name: ownerName,
          total_parcels: totalPackages,
          completed_parcels: completedPackages,
          completed_cod_parcels: completedCodPackages,
          returned_parcels: returnedPackages
        }
      })
    },

    getDriverById: (state) => (driverId) => {
      return state.drivers.find(driver => driver.driver_id === driverId)
    },

    getDriverDetails: (state) => state.driverDetails,

    getDrivers: (state) => {
      return state.drivers.map(driver => {
        const driverParcels = state.parcels.filter(parcel => parcel.payment_status == 'PAID' && (parcel.driver === driver.driver_id || parcel.dropoff_driver === driver.driver_id || parcel.returning_driver === driver.driver_id));
        const totalPackages = driverParcels.length;
        const name = driver.first_name + ' ' + driver.last_name

        return {
          ...driver,
          name: name,
          total_packages: totalPackages,
        };
      })
    },

    getCollectorDetails: (state) => state.collectorDetails,

    getCollectors: (state) => {
      return state.collectors.map(collector => {
        const collectorParcels = state.parcels.filter(parcel => parcel.collector === collector.collector_id);
        const totalPackages = collectorParcels.length;
        const name = collector.first_name + ' ' + collector.last_name

        return {
          ...collector,
          name: name,
          total_packages: totalPackages,
        };
      })
    },

    getCollectorById: (state) => (collectorId) => {
      return state.collectors.find(collector => collector.collector_id === collectorId)
    },

    getCustomerById: (state) => (customerId) => {
      return state.customers.find(customer => customer.customer_id === customerId)
    },

    getCustomerDetails: (state) => state.customerDetails,

    getCustomers: (state) => {
      return state.customers.map(customer => {
        const customerParcels = state.parcels.filter(parcel => parcel.customer === customer.customer_id);
        const totalPackages = customerParcels.length;
        const unpaidPackages = customerParcels.filter(parcel => parcel.payment_status == 'UNPAID').length;
        const paidPackages = customerParcels.filter(parcel => parcel.payment_status == 'PAID').length;
        const codPackages = customerParcels.filter(parcel => parcel.parcel_type == 'CASH ON DELIVERY' && parcel.payment_status == 'PAID').length
        const name = customer.first_name + ' ' + customer.last_name

        return {
          ...customer,
          name: name,
          total_packages: totalPackages,
          unpaid_packages: unpaidPackages,
          paid_packages: paidPackages,
          cod_packages: codPackages
        };
      });
    },

    getAdminDetails: (state) => state.admin
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
        state.user.username = localStorage.getItem('username')
        state.user.id = localStorage.getItem('userid')
      } else {
        state.token = ''
        state.isAuthenticated = false
        state.user = {}
      }
    },

    setLoadingStatus(state, status) {
      state.isLoading = status
    },

    setNewParcelDetails(state, data) {
      state.newParcelDetails = Object.assign({...state.newParcelDetails}, data);
    },

    setCustomerRegistrationDetails(state, data) {
      state.customerRegistrationDetails = Object.assign({...state.customerRegistrationDetails}, data);
    },

    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      state.token = '';
      state.isAuthenticated = false;
    },

    setUser(state, data) {
      state.user = Object.assign({...state.user}, data);
      state.isAuthenticated = true;
    },

    removeUser(state) {
      state.user = {};
      state.isAuthenticated = false;
    },

    clearDetails(state) {
      state.newParcelDetails = {};
    },

    setAdmin(state, data) {
      state.admin = data,
      state.adminLastFetched = new Date()
      state.isAdminAuthenticated = true
    },

    removeAdmin(state) {
      state.admin = []
      state.adminLastFetched = null
      state.isAdminAuthenticated = false
    },

    setServices(state, services) {
      state.services = services
    },

    setPackageStatuses(state, packageStatuses) {
      state.packageStatuses = packageStatuses
    },

    setDeliveryTimes(state, deliveryTimes) {
      state.deliveryTimes = deliveryTimes
    },

    setZones(state, zones) {
      state.zones = zones
    },

    setParcelTypes(state, parcelTypes) {
      state.parcelTypes = parcelTypes
    },

    setParcels(state, parcels) {
      state.parcels = parcels
      state.parcelsLastFetched = new Date()
    },

    setTransactions(state, transactions) {
      state.transactions = transactions
      state.transactionsLastFetched = new Date()
    },

    setNewParcel(state, parcel) {
      state.newParcel = parcel
    },

    removeNewParcel(state) {
      state.newParcel = []
    },

    setAgents(state, agents) {
      state.agents = agents
      state.agentsLastFetched = new Date()
    },

    setAgent(state, agentDetails) {
      state.agentDetails = agentDetails
      state.agentLastFetched = new Date()
      state.isUserAuthenticated = true
    },

    removeAgent(state) {
      state.agentDetails = []
      state.agentLastFetched = null
      state.isUserAuthenticated = false
    },
    
    setCollectors(state, collectors) {
      state.collectors = collectors
      state.collectorsLastFetched = new Date()
    },

    setCollector(state, collectorDetails) {
      state.collectorDetails = collectorDetails
      state.collectorLastFetched = new Date()
      state.isUserAuthenticated = true
    },

    removeCollector(state) {
      state.collectorDetails = []
      state.collectorLastFetched = null
      state.isUserAuthenticated = false
    },
    
    setDrivers(state, drivers) {
      state.drivers = drivers
      state.driversLastFetched = new Date()
    },

    setDriver(state, driverDetails) {
      state.driverDetails = driverDetails
      state.driverLastFetched = new Date()
      state.isUserAuthenticated = true
    },

    removeDriver(state) {
      state.driverDetails = []
      state.driverLastFetched = null
      state.isUserAuthenticated = false
    },

    setCustomers(state, customers) {
      state.customers = customers
      state.customersLastFetched = new Date()
    },

    setCustomer(state, customerDetails) {
      state.customerDetails = customerDetails
      state.customerLastFetched = new Date()
      state.isAuthenticated = true
    },

    setWithdrawals(state, withdrawals) {
      state.withdrawals = withdrawals
      state.withdrawalsLastFetched = new Date()
    },

    setCompanyPhoneNumber(state, phoneNumber) {
      state.companyPhoneNumber = phoneNumber;
    }

  },
  actions: {
    // create new package
    async createPackage({state, commit}) {
      const data = state.newParcelDetails
      // console.log("New parcel data: ", data);

      let message = ''

      await axios
        .post('/packages/make/', data)
        .then((response) => {
          // console.log("New package created: ", response);
          commit('setNewParcelDetails', response.data)

          message = response
        })
        .catch((error) => {
          // console.error("Error creating package (store): ", error);
          this.$toast.error(
            "Something went wrong when creating the package. Please try again!",
            {
              position: 'bottom-right',
              duration: 3000
            }
          )

          message = error
        })

      return message
    },
    storePhoneNumber({ commit }, phoneNumber) {
      commit('setCompanyPhoneNumber', phoneNumber);
    },

    // create new customer
    async createCustomer( { state, commit }) {
      const data = state.customerRegistrationDetails
      
      try {

        var message = ''

        await axios
          .post('/customer/register/', data)
          .then((response) => {

            message = response

            localStorage.setItem('email', response.data.user.email)
          })
          .catch((error) => {

            message = error.data
          })

        return message
                        
      } catch (error) {

        return error
      }
    },

    // fetch all services from db
    async fetchServices({state, commit}) {
      if (state.services.length > 0) {
        return state.services
      }

      const services = await axios.get('/api/choices/service-types/')

      commit('setServices', services.data)

      return services.data
    },

    // fetch all package statuses choices as defined in backend
    async fetchPackageStatuses({state, commit}) {
      if (state.packageStatuses.length > 0) {
        // console.log("Package statuses already set");
        return state.packageStatuses
      }

      const packageStatuses = await axios.get('/api/choices/package-statuses/')

      commit('setPackageStatuses', packageStatuses.data)

      return packageStatuses.data
    },

    // fetch all delivery times as defined in the backend
    async fetchDeliveryTimes({state, commit}) {
      if (state.deliveryTimes.length > 0) {
        return state.deliveryTimes
      }

      const deliveryTimes = await axios.get('/api/choices/delivery-times/')

      commit('setDeliveryTimes', deliveryTimes.data)

      return deliveryTimes.data
    },

    // fetch all zones
    async fetchZones({state, commit}) {
      if (state.zones.length > 0) {
        return state.zones
      }

      const zones = await axios.get('/api/choices/route-types/')

      commit('setZones', zones.data)

      return zones.data
    },

    // fetch all parcel types as defined in the backend
    async fetchParcelTypes({state, commit}) {
      if (state.parcelTypes.length > 0) {
        // console.log("Parcel types already set");
        return state.parcelTypes
      }

      const parcelTypes = await axios.get('/api/choices/parcel-types/')

      commit('setParcelTypes', parcelTypes.data)

      return parcelTypes.data
    },

    // fetch transactions
    async fetchTransactions({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let transactions = []

      if (state.transactions.length > 0 && state.transactionsLastFetched > oneMinuteAgo) {
        transactions = state.transactions

      } else {

        await axios
          .get('/mpesa/transactions/')
          .then((response) => {
            const results = response.data.transactions
            transactions = results

            commit('setTransactions', transactions)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return transactions
      }
    },

    // fetch withdrawals
    async fetchWithdrawals({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let withdrawals = []

      if (state.withdrawals.length > 0 && state.withdrawalsLastFetched > oneMinuteAgo) {
        withdrawals = state.withdrawals

      } else {

        await axios
          .get('/withdrawal/')
          .then((response) => {
            const results = response.data.withdrawalrequests
            withdrawals = results

            commit('setWithdrawals', withdrawals)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

          return withdrawals
      }
    },

    // paid packages only
    async fetchParcels({commit, state}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let parcels = []

      if (state.parcels.length > 0 && state.parcelsLastFetched > oneMinuteAgo) {
        parcels = state.parcels

      } else {

        await axios
          .get('/packages/')
          .then((response) => {
            const results = response.data.results
            results.forEach(parcel => {
              if (parcel.payment_status == 'PAID') {
                parcels.push(parcel);
              }
            });

            commit('setParcels', parcels)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return parcels
      }
    },
    
    // including unpaid packages
    async fetchAllParcels({commit, state}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let parcels = []

      if (state.parcels.length > 0 && state.parcelsLastFetched > oneMinuteAgo) {
        parcels = state.parcels

      } else {

        await axios
          .get('/packages/')
          .then((response) => {
            const results = response.data.results
            results.forEach(parcel => {
              parcels.push(parcel);
            });

            commit('setParcels', parcels)
          })
          .catch((error) => {
            // console.error("Error fetching parcels (store): ", error)

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return parcels
      }

    },

    // fetch new parcel details
    async fetchNewParcelDetails({state, commit}, parcel_id) {
      let parcelDetails = []

      await axios
        .get(`/packages/${parcel_id}/`)
        .then((response) => {
          const results = response.data
          parcelDetails = results

          commit('setNewParcel', parcelDetails)
        })
        .catch((error) => {
          console.error("Error fetching parcel details (store): ", error)
        })

      return parcelDetails
    },

    // get all agens
    async fetchAgents({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let agents = []

      if (state.agents.length > 0 && state.agentsLastFetched > oneMinuteAgo) {
        agents = state.agents

      } else {

        await axios
          .get('/agentsregistered/')
          .then((response) => {
            const results = response.data
            agents = results

            commit('setAgents', agents)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              // router.push('/users/')
            }
          })

        return agents
      }
    },

    // get details of single agent
    async fetchAgentDetails({state, commit}, agent_id) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let agentDetails = []

      if (state.agentDetails.length > 0 && state.agentLastFetched > oneMinuteAgo) {
        agentDetails = state.agentDetails
      } else {

        await axios
          .get(`/agents/${agent_id}/`)
          .then((response) => {
            const results = response.data
            agentDetails = results

            commit('setAgent', agentDetails)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            }
          })

        return agentDetails
      }
    },
    async fetchCollectors({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let collectors = []

      if (state.collectors.length > 0 && state.collectorsLastFetched > oneMinuteAgo) {
        collectors = state.collectors

      } else {

        await axios
          .get('/collectorsregistered/')
          .then((response) => {
            const results = response.data
            collectors = results

            commit('setCollectors', collectors)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return collectors
      }
    },
    
    // get details of single collector
    async fetchCollectorDetails({state, commit}, collector_id) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let collectorDetails = []

      if (state.collectorDetails.length > 0 && state.collectorLastFetched > oneMinuteAgo) {
        collectorDetails = state.collectorDetails
      } else {

        await axios
          .get(`/collectors/${collector_id}/`)
          .then((response) => {
            const results = response.data
            collectorDetails = results

            commit('setCollector', collectorDetails)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            }
          })

        return collectorDetails
      }
    },

    // get all registered drivers
    async fetchDrivers({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let drivers = []

      if (state.drivers.length > 0 && state.driversLastFetched > oneMinuteAgo) {
        drivers = state.drivers

      } else {

        await axios
          .get('/driversregistered/')
          .then((response) => {
            const results = response.data
            drivers = results

            commit('setDrivers', drivers)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return drivers
      }
    },

    // get details of single driver
    async fetchDriverDetails({state, commit}, driver_id) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let driverDetails = []

      if (state.driverDetails.length > 0 && state.driverLastFetched > oneMinuteAgo) {
        driverDetails = state.driverDetails
      } else {

        await axios
          .get(`/drivers/${driver_id}/`)
          .then((response) => {
            const results = response.data
            driverDetails = results

            commit('setDriver', driverDetails)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            }
          })

        return driverDetails
      }
    },

    // get all customers
    async fetchCustomers({state, commit}) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let customers = []

      if (state.customers.length > 0 && state.customersLastFetched > oneMinuteAgo) {
        customers = state.customers

      } else {

        await axios
          .get('/customersregistered/')
          .then((response) => {
            const results = response.data
            customers = results

            commit('setCustomers', customers)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            } else if (error.response.status == 401) {
              useToast().error("Please log in to continue!",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )

              router.push('/users/')
            }
          })

        return customers
      }
    },

    // get single customer
    async fetchCustomerDetails({state, commit}, customer_id) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let customerDetails = []

      if (state.customerDetails.length > 0 && state.customerLastFetched > oneMinuteAgo) {
        customerDetails = state.customerDetails
      } else {

        await axios
          .get(`/customers/${customer_id}/`)
          .then((response) => {
            const results = response.data
            customerDetails = results

            commit('setCustomer', customerDetails)
          })
          .catch((error) => {

            if (error.code == 'ERR_NETWORK') {
              useToast().error("Please check your internet connection and try again",
                {
                  position: 'bottom-right',
                  duration: 3000
                }
              )
            }
          })

        return customerDetails
      }
    },

    // get admin details
    async fetchAdminDetails({state, commit}, admin_id) {
      // check if data is more than one minute old
      const oneMinuteAgo = new Date(new Date().getTime() - 1 * 60 * 1000)
      let adminDetails = []

      if (state.admin.length > 0 && state.adminLastFetched > oneMinuteAgo) {
        adminDetails = state.adminDetails
      } else {
          
          await axios
            .get(`/administrators/${admin_id}/`)
            .then((response) => {
              const results = response.data
              adminDetails = results

              commit('setAdmin', adminDetails)
            })
            .catch((error) => {
  
              if (error.code == 'ERR_NETWORK') {
                useToast().error("Please check your internet connection and try again",
                  {
                    position: 'bottom-right',
                    duration: 3000
                  }
                )
              }
            })
  
          return adminDetails
      }
    }
  },
  modules: {
  }
})
