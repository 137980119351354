<template>
  <footer class="bg-gray-700 shadow">
    <div class="w-full max-w-screen-xl mx-auto px-4 py-6 md:py-8">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Logo and Navigation Links -->
        <div class="space-y-6">
          <!-- Logo -->
          <router-link to="/" class="flex items-center mb-4 sm:mb-0 space-x-3">
            <span class="text-white self-center text-2xl font-semibold whitespace-nowrap hover:text-orange-400">Timely Deliveries</span>
          </router-link>

          <!-- Navigation links -->
          <ul class="flex flex-col gap-3 text-sm font-medium text-white">
            <li>
              <router-link to="/about-us" class="hover:text-orange-400">About Us</router-link>
            </li>
            <li>
              <router-link to="/terms-and-conditions" class="hover:text-orange-400">Terms &amp; Conditions</router-link>
            </li>
            <li>
              <router-link to="/refundpolicy" class="hover:text-orange-400">Refund Policy</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy" class="hover:text-orange-400">Privacy Policy</router-link>
            </li>
            <li>
               <router-link to="/our-agents" class="hover:text-orange-400">Our Agents</router-link>
            </li>
            <!-- <li>
              <router-link to="#" class="hover:text-orange-400">Contact</router-link>
            </li> -->
            <li>
              <router-link to="/faq" class="hover:text-orange-400">FAQs</router-link>
            </li>
          </ul>
        </div>

        <!-- Contact Information -->
        <div class="text-white space-y-4">
          <h3 class="text-lg font-semibold">Contact Us</h3>
          <address class="not-italic">
            <p class="font-medium">Office Location:</p>
            <p>Greencourt Business Center</p>
            <p>4th floor, Wing A, Office 411A</p>
            <p>Latema Road, Nairobi</p>
          </address>
          <div>
            <p>Contact: <a href="tel:0720063338" class="hover:text-orange-400">0720063338</a></p>
            <p>Email: <a href="mailto:Help@timelydelivery.net" class="hover:text-orange-400">Help@timelydelivery.net</a></p>
          </div>
        </div>

        <!-- Social Media Icons -->
        <div class="space-y-4">
          <h3 class="text-lg font-semibold text-white">Follow Us</h3>
          <div class="flex flex-wrap gap-4">
            <!-- Facebook Page -->
            <a href="https://www.facebook.com/timelydeliveryke?mibextid=ZbWKwL" class="text-white hover:text-orange-400">
              <svg width="25" height="25" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
              </svg>
              <span class="sr-only">Facebook page</span>
            </a>
            <!-- LinkedIn -->
            <a href="https://www.linkedin.com/company/kidogosoko/" class="text-blue-600 hover:text-orange-400">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256" fill="currentColor">
                <g transform="scale(2.81 2.81)">
                  <path d="M0 6.447C0 2.887 2.978 0 6.651 0h76.698C87.022 0 90 2.887 90 6.447v77.106C90 87.114 87.022 90 83.349 90H6.651C2.978 90 0 87.114 0 83.553V6.447z"/>
                  <path d="M20.485 29.151c4.74 0 7.691-3.121 7.691-7.021-.088-3.988-2.95-7.022-7.601-7.022-4.65 0-7.69 3.034-7.69 7.022 0 3.9 2.95 7.021 7.512 7.021h.088zM13.688 34.699h13.594v40.64H13.688v-40.64zM34.804 34.699h13.594v23.415c0 1.228.089 2.45.452 3.325.992 2.461 3.246 5.006 7.037 5.006 4.958 0 6.94-3.776 6.94-9.312v-22.434h13.592v24.03c0 12.659-6.765 18.538-15.78 18.538-7.388 0-10.63-4.13-12.432-6.936h.092v5.968H34.804c.18-3.866 0-41.6 0-41.6z" fill="#fff"/>
                </g>
              </svg>
              <span class="sr-only">LinkedIn page</span>
            </a>
            <!-- Tiktok -->
            <a href="https://www.tiktok.com/@timely.delivery.f?_t=8lpJDfPaEmU&_r=1" class="text-black hover:text-orange-400">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256" fill="currentColor">
                <g transform="scale(2.81 2.81)">
                  <rect width="90" height="90" rx="0" ry="0"/>
                  <path d="M74.5 34.662c-6.65 0-12.803-2.171-17.776-5.846v26.621c0 13.289-10.777 24.066-24.067 24.066-13.289 0-24.066-10.777-24.066-24.066 0-13.29 10.777-24.067 24.066-24.067.623 0 1.238.027 1.847.073v11.857c-.61-.099-1.233-.155-1.867-.155-6.725 0-12.184 5.459-12.184 12.184s5.459 12.184 12.184 12.184c6.725 0 12.775-5.459 12.775-12.184l.112-53.874h11.868c1.099 7.422 6.999 13.093 14.108 13.093V34.662z" fill="#fff"/>
                </g>
              </svg>
              <span class="sr-only">TikTok page</span>
            </a>
            <!-- Instagram -->
            <a href="https://www.instagram.com/timely.delivery?igsh=cWU2ajV1Nm05a2pu" class="text-white hover:text-orange-400">
              <svg width="25" height="25" viewBox="0 0 31 31" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0312 0.441895H21.1688C26.2939 0.441895 30.45 4.59446 30.45 9.71515V20.8431C30.45 25.9637 26.2939 30.1163 21.1688 30.1163H10.0312C4.90614 30.1163 0.75 25.9637 0.75 20.8431V9.71515C0.75 4.59446 4.90614 0.441895 10.0312 0.441895ZM21.1688 27.3343C24.7514 27.3343 27.6657 24.4225 27.6657 20.843V9.71514C27.6657 6.13566 24.7514 3.22386 21.1688 3.22386H10.0313C6.44873 3.22386 3.53442 6.13566 3.53442 9.71514V20.843C3.53442 24.4225 6.44873 27.3343 10.0313 27.3343H21.1688Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.17505 15.2792C8.17505 11.1823 11.4996 7.8606 15.6 7.8606C19.7005 7.8606 23.025 11.1823 23.025 15.2792C23.025 19.3761 19.7005 22.6978 15.6 22.6978C11.4996 22.6978 8.17505 19.3761 8.17505 15.2792ZM10.9594 15.2792C10.9594 17.8349 13.0421 19.9158 15.6 19.9158C18.1579 19.9158 20.2406 17.8349 20.2406 15.2792C20.2406 12.7216 18.1579 10.6426 15.6 10.6426C13.0421 10.6426 10.9594 12.7216 10.9594 15.2792Z" fill="white"/>
                <ellipse cx="23.5819" cy="7.30396" rx="0.989381" ry="0.988529" fill="white"/>
              </svg>
              <span class="sr-only">Instagram page</span>
            </a>
          </div>
        </div>
      </div>

      <hr class="my-6 border-gray-600 sm:mx-auto lg:my-8">

      <div class="text-sm text-center text-gray-400">
        © 2024 Timely Deliveries. All Rights Reserved.
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Footer',
  created() {
    this.storePhoneNumber('0720063338'); 
  },
  methods: {
    ...mapActions(['storePhoneNumber']), 
  }
}
</script>
