<template>
    <div class="container mx-auto bg-white">
        <!-- header (Back button & Order ID) -->
        <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
            <div>
                <button @click="$router.back()"
                    class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                            fill="currentColor" />
                    </svg>

                    <span>Back</span>
                </button>

            </div>

            <div>
                <div class="flex justify-end">
                    <span v-if="!agentDetails.active" class="font-semibold rounded-full bg-red-500 px-12 py-1 text-white text-center">INACTIVE</span>
                    <span v-if="agentDetails.active" class="font-semibold rounded-full bg-green-500 px-12 py-1 text-white text-center">ACTIVE</span>
                </div>
                <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Agent ID: 
                    <span class="text-gray-600 font-bold">{{ agentDetails.agent_code }}</span>
                </div>
            </div>

        </div>
    </div>

    <!-- Agent details -->

    <!-- Loading indicator -->
    <div v-if="$store.state.isLoading">
        <div>
            <div>
                <div class="flex items-center justify-center px-6 py-3">
                    <div class="flex justify-center items-center">
                        <div class="mr-2">Loading...</div>
                        <LoadingIcon />

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="agent-details" v-else-if="agentDetails">
        <!-- Agent details -->
        <div>
            <h2 class="font-bold text-2xl leading-7 mx-24 mb-5">Agent Details:</h2>

            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <dl class="divide-y divide-gray-100">
                    <!-- Name -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Name</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.first_name }} {{ agentDetails.last_name }}</dd>
                    </div>

                    <!-- Email -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Email</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.email }}</dd>
                    </div>

                    <!-- Phone Number -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.phone_number }}
                        </dd>
                    </div>

                    <!-- Mpesa Name -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Mpesa Name</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.mpesa_name }}
                        </dd>
                    </div>

                    <!-- Mpesa Phone Number -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Mpesa Number</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.mpesa_number }}
                        </dd>
                    </div>


                </dl>
            </div>

        </div>

        <!-- Shop details -->
        <div>
            <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Shop Details:</h2>

            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <dl class="divide-y divide-gray-100">
                    <!-- Name -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Shop Name</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.collection_shop_name }}
                        </dd>
                    </div>

                    <!-- building -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Building</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.agent_building }}
                        </dd>
                    </div>

                    <!-- Shop Location -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Shop Location</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentDetails.location_name }}
                        </dd>
                    </div>

                    <!-- Shop Pin -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Shop Pin</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {{ agentDetails.pinned_location }}
                        </dd>
                    </div>

                    <!-- Zone -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Zone</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {{ agentDetails.zone }}
                        </dd>
                    </div>


                </dl>
            </div>

        </div>

        <!-- order details (stats) -->
        <div>
            <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Order Details:</h2>

            <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                <dl class="divide-y divide-gray-100">

                    <!-- Expecting orders -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Expecting Orders</dt>

                        <!-- show expecting packages -->
                        <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <button type="button" class="flex items-center gap-x-4" @click="openExpectingOrders()"
                                :disabled="!expectingOrders.length">
                                <span>{{ expectingOrders.length }}</span>
                                <svg v-if="expectingOrders.length" class="w-3 h-3 shrink-0" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
                                    :class="[ showExpectingOrders ? '' : 'rotate-180' ]">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>

                            <dd v-if="showExpectingOrders" class="relative overflow-x-auto mt-3">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                ID
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Service
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="divide-y divide-y-100 bg-white">
                                        <tr v-for="order in expectingOrders" :key="order.id">
                                            <td class="px-6 py-3 hover:text-blue-500">
                                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                                            </td>
                                            <td class="px-6 py-3">
                                                {{ order.route_type }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </dd>

                        </div>
                    </div>

                    <!-- Dropoff orders -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Dropoff Orders</dt>

                        <!-- show dropoff orders -->
                        <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <button type="button" class="flex items-center gap-x-4" @click="openDropoffOrders()"
                                :disabled="!dropoffOrders.length">
                                <span>{{ dropoffOrders.length }}</span>
                                <svg v-if="dropoffOrders.length" class="w-3 h-3 shrink-0" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
                                    :class="[ showDropoffOrders ? '' : 'rotate-180' ]">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>

                            <dd v-if="showDropoffOrders" class="relative overflow-x-auto mt-3">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                ID
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Service
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="divide-y divide-y-100 bg-white">
                                        <tr v-for="order in dropoffOrders" :key="order.id">
                                            <td class="px-6 py-3 hover:text-blue-500">
                                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                                            </td>
                                            <td class="px-6 py-3">
                                                {{ order.route_type }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </dd>

                        </div>
                    </div>

                    <!-- orders at agent -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Orders at Agent</dt>

                        <!-- show details of packages at agent -->
                        <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <button type="button" class="flex items-center gap-x-4" @click="openInOfficeOrders()"
                                :disabled="!atAgentOrders.length">
                                <span>{{ atAgentOrders.length }}</span>
                                <svg v-if="atAgentOrders.length" class="w-3 h-3 shrink-0" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
                                    :class="[ showInOfficeOrders ? '' : 'rotate-180' ]">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>

                            <dd v-if="showInOfficeOrders" class="relative overflow-x-auto mt-3">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                ID
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Service
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr class="bg-white divide-y divide-y-100" v-for="order in atAgentOrders"
                                            :key="order.id">
                                            <td class="px-6 py-3 hover:text-blue-500">
                                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                                            </td>
                                            <td class="px-6 py-3">
                                                {{ order.route_type }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </dd>

                        </div>

                    </div>

                    <!-- Fulfilled Orders -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Fulfilled Orders</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ fulfilledOrders.length }}</dd>
                    </div>

                    <!-- To be returned -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">To be returned</dt>

                        <!-- Show orders to be returned -->
                        <div class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <button type="button" class="flex items-center gap-x-4" @click="openReturnOrders()"
                                :disabled="!toBeReturned.length">
                                <span>{{ toBeReturned.length }}</span>
                                <svg v-if="toBeReturned.length" class="w-3 h-3 shrink-0" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"
                                    :class="[ showReturnOrders ? '' : 'rotate-180' ]">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M9 5 5 1 1 5" />
                                </svg>
                            </button>

                            <dd v-if="showReturnOrders" class="relative overflow-x-auto mt-3">

                                <table class="w-full text-sm text-left text-gray-500">
                                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                        <tr>
                                            <th scope="col" class="px-6 py-3">
                                                ID
                                            </th>
                                            <th scope="col" class="px-6 py-3">
                                                Service
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr class="bg-white divide-y divide-y-100" v-for="order in toBeReturned"
                                            :key="order.id">
                                            <td class="px-6 py-3 hover:text-blue-500">
                                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                                            </td>
                                            <td class="px-6 py-3">
                                                {{ order.route_type }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </dd>

                        </div>
                    </div>

                    <!-- total orders -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Total Orders</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ agentOrders.length }}
                        </dd>
                    </div>

                    <!-- Amount Owed -->
                    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt class="text-sm font-medium leading-6 text-gray-900">Amount Owed</dt>
                        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ agentDetails.agent_commission }}</dd>
                    </div>

                </dl>
            </div>
            <div class="flex justify-end items-center mt-8 -mb-8">
                <div class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                    type="button" @click="downloadPDF">Download PDF</div>
            </div>
        </div>

        <!-- Deactivate button -->
        <div class="flex justify-center mt-12">
            <button v-if="agentDetails.active" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" @click="changeActiveStatus">
                Deactivate Agent
            </button>
            <button v-else class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full" @click="changeActiveStatus">
                Activate Agent
            </button>
        </div>

    </div>

    <div v-else>
        <p class="items-center text-center font-semibold text-lg text-red-500">Error fetching order details. Please
            reload page!</p>
    </div>


</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import html2pdf from 'html2pdf.js';
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'AgentPage',
        data() {
            return {
                id: this.$route.params.id,

                // expectingOrders: [],
                // dropoffOrders: [],
                // atAgentOrders: [],
                // fulfilledOrders: [],
                // toBeReturned: [],

                showInOfficeOrders: false,
                showExpectingOrders: false,
                showDropoffOrders: false,
                showReturnOrders: false,

                // isActive: null

            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getAgentById', 'getParcelsByAgentId']),

            agentDetails() {
                return this.getAgentById(this.id)
            },

            agentOrders() {
                return this.getParcelsByAgentId(this.id)
            },

            expectingOrders() {
                return this.agentOrders.filter(parcel => parcel.status === 'PACKAGE IN TRANSIT TO AGENT' && parcel.agent === this.agentDetails.agent_id || parcel.dropoff_agent === this.agentDetails.agent_id && parcel.status === 'PACKAGE CREATED')
            },

            dropoffOrders() {
                return this.agentOrders.filter(parcel => parcel.dropoff_agent === this.agentDetails.agent_id)
            },

            atAgentOrders() {
                return this.agentOrders.filter(parcel => (parcel.status === 'DELIVERED TO DROPOFF AGENT' && parcel.dropoff_agent === this.agentDetails.agent_id) || (parcel.status === 'DELIVERED TO AGENT' && parcel.agent === this.agentDetails.agent_id));
            },

            fulfilledOrders() {
                return this.agentOrders.filter(parcel => parcel.status === 'RECEIVED BY RECIPIENT' && parcel.agent === this.agentDetails.agent_id)
            },

            toBeReturned() {
                return this.agentOrders.filter(parcel => parcel.agent === this.agentDetails.agent_id && parcel.days_stayed_at_agent > 3 && parcel.status == 'DELIVERED TO AGENT')
            },
        },

        methods: {
            ...mapMutations(['setLoadingStatus']),

            // change active status
            async changeActiveStatus() {
                // update active status
                if (confirm("Are you sure you want to change status?")) {
                    let activeStatus = !this.agentDetails.active

                    await axios
                        .put(`/agents/${this.id}/`, {
                            active: activeStatus
                        })
                        .then((response) => {

                            this.$store.state.agentsLastFetched = null
                            this.$store.dispatch('fetchAgents')

                            this.$toast.success(
                                "Agent status updated successfully!",
                                {
                                    position: "bottom-right",
                                    duration: 3000,
                                }
                            )

                            this.$router.push('/admin/agents/')

                        })
                        .catch((error) => {
                            this.$toast.error(
                                "Error updating agent status. Please try again!",
                                {
                                    position: "bottom-right",
                                    duration: 3000,
                                }
                            )
                        })

                }
                
                
            },

            // open in office orders
            async openInOfficeOrders() {
                this.showInOfficeOrders = !this.showInOfficeOrders
            },

            // Show expecting orders
            async openExpectingOrders() {
                this.showExpectingOrders = !this.showExpectingOrders
            },

            // show dropoff orders
            async openDropoffOrders() {
                this.showDropoffOrders = !this.showDropoffOrders
            },

            // show return orders
            async openReturnOrders() {
                this.showReturnOrders = !this.showReturnOrders;
            },
            downloadPDF() {
                const element = document.getElementById('agent-details');
                const opt = {
                    margin: 1,
                    filename: `agent-${this.agentID}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                };

                html2pdf().set(opt).from(element).save();
            }
        }
    }
</script>