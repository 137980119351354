<template>
    <nav class="mx-auto flex max-w-7xl items-center justify-between px-3 py-5 lg:px-8" aria-label="Global">
        <!-- Logo -->
        <div class="flex">
            <router-link to="/" class="flex items-center space-x-3">
                <span class="self-center text-2xl font-semibold whitespace-nowrap hover:text-orange-400">
                    <img src="../../assets/long-logo.png" alt="Timely Delivery" class="h-16 w-auto p-2">
                </span>
            </router-link>
        </div>

        <!-- search bar -->
        <!-- <div class="hidden lg:flex">
            <div class="flex justify-between relative  md:block">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                    </svg>
                    <span class="sr-only">Search icon</span>
                </div>
                <input type="text" id="search-navbar"
                    class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-200 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter Order Number">
            </div>

            <div class="mx-3">
                <button
                    class="block rounded-md bg-[#F39E20] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Track
                    Your Order</button>
            </div>
        </div> -->

        <!-- Log in and Register buttons -->
        <template v-if="!$store.state.isAuthenticated">
            <div class="hidden lg:flex lg:justify-end">
                <router-link to="/register"
                    class="font-medium text-md px-5 py-2.5 text-center me-2 mb-2 text-black hover:text-orange-400">Register</router-link>
                <router-link to="/login"
                    class="flex font-medium text-md px-5 py-2.5 text-center me-2 mb-2 text-black hover:text-orange-400">
                    <svg class="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd"
                            d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
                            clip-rule="evenodd" />
                    </svg>
                    Login
                </router-link>
            </div>
        </template>

        <!-- Profile icon and menu -->
        <template v-else>
            <div class="relative ml-3">
                <div>
                    <button type="button"
                        class="text-gray-800 hover:bg-orange-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-base px-5 py-2.5 text-center inline-flex items-center"
                        id="user-menu-button" aria-expanded="false" aria-haspopup="false" ref="menu" @click="openMenu">
                        <span class="absolute -inset-1.5"></span>
                        <span class="sr-only">Open user menu</span>
                        {{ customerDetails.first_name }} {{ customerDetails.last_name }}
                        <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="m1 1 4 4 4-4" />
                        </svg>
                    </button>
                </div>

                <div v-if="isOpen"
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                    <div class="block px-4 py-2 text-sm text-gray-700">
                        <div class="font-light text-xl">Wallet: {{ customerDetails.wallet_amount }}</div>
                    </div>
                    <div class="block px-4 py-2 text-sm bg-green-400 hover:bg-green-700">
                        <button type="button" @click="toggleDepositDialog" class="text-sm text-white">Deposit to Wallet</button>
                    </div>

                    <hr />
                    <router-link to="/my-profile" @click="openMenu"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300" role="menuitem" tabindex="-1"
                        user-menu-item-0>My Profile</router-link>
                    <router-link to="/my-wallet" @click="openMenu"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300" role="menuitem" tabindex="-1"
                        user-menu-item-0>My Wallet</router-link>
                    <router-link to="/my-parcels" @click="openMenu"
                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300" role="menuitem" tabindex="-1"
                        user-menu-item-1>My Parcels</router-link>
                    <hr />

                    <div class="block px-4 py-2 text-sm bg-red-400 hover:bg-red-700">
                        <button type="button" @click="logout()" class="text-white text-sm" role="menuitem" tabindex="-1"
                            user-menu-item-2>Log out</button>

                    </div>
                </div>
                <div v-if="showDepositDialog"
                    class="fixed inset-0 z-50 overflow-auto bg-opacity-75 flex justify-center items-center">
                    <form @submit.prevent="initiateSTKPush">
                        <div class="bg-white rounded-lg p-8 relative">
                            <button @click="toggleDepositDialog"
                                class="absolute top-2 right-2 text-gray-500 hover:text-gray-800">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                    stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <h2 class="text-2xl font-bold mb-4">Deposit to Wallet</h2>
                            <div class="mb-4">
                                <label for="phone" class="block font-medium mb-2">Phone Number:</label>
                                <input type="text" id="phone" v-model="phoneNumber"
                                    class="border border-gray-300 rounded-md px-3 py-2 w-full"
                                    placeholder="Enter your phone number">
                            </div>
                            <div class="mb-4">
                                <label for="amount" class="block font-medium mb-2">Amount:</label>
                                <input type="number" id="amount" v-model="depositAmount"
                                    class="border border-gray-300 rounded-md px-3 py-2 w-full"
                                    placeholder="Enter amount to deposit">
                            </div>
                            <div class="flex justify-end">
                                <button type="submit"
                                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"> <span v-if="!$store.state.isLoading">Complete Deposit</span>
                                    <span v-else><LoadingIcon /></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </template>

        <!-- Mobile Menu -->
        <div v-if="!$store.state.isAuthenticated" class="lg:hidden">
            <button type="button"
                class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-orange-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                id="user-menu-button" aria-expanded="false" aria-haspopup="false" ref="menu" @click="openMobileMenu">
                <span class="absolute -inset-0.5"></span>
                <span class="sr-only">Open main menu</span>
                <!-- When menu closed -->
                <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
            </button>

            <div v-if="isMobileMenuOpen"
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                <template v-if="!$store.state.isAuthenticated">
                    <div class="flex-grid grid-cols-1">
                        <router-link to="/register"
                            class="font-medium text-md px-5 py-2.5 text-justify me-2 mb-2 text-black hover:text-orange-400">Register</router-link>
                        <router-link to="/login"
                            class="flex font-medium text-md px-5 py-2.5 text-justify me-2 mb-2 text-black hover:text-orange-400">
                            <svg class="w-6 h-6 text-black" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor" viewBox="0 0 24 24">
                                <path fill-rule="evenodd"
                                    d="M12 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm-2 9a4 4 0 0 0-4 4v1a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1a4 4 0 0 0-4-4h-4Z"
                                    clip-rule="evenodd" />
                            </svg>
                            Login
                        </router-link>
                    </div>
                </template>

            </div>
        </div>
    </nav>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import axios from 'axios';
import LoadingIcon from '../LoadingIcon.vue';

export default {
    name: 'Navbar',
    computed: {
        ...mapState(['isAuthenticated']),
        ...mapGetters(['getCustomerDetails']),

        customerDetails() {
            return this.getCustomerDetails
        }
    },
    data() {
        return {
            isOpen: false,
            isMobileMenuOpen: false,
            showDepositDialog: false,
            depositAmount: 0,
            mpesaSuccessful: false,
            mpesaFailed: false,
            mpesaResponses: [],
            mpesaPushResponse: []
        };
    },
    components: {
        LoadingIcon,
    },
    methods: {
        ...mapMutations(['removeToken', 'setLoadingStatus']),

        openMenu() {
            this.isOpen = !this.isOpen;
        },

        openMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },

        toggleDepositDialog() {
            this.showDepositDialog = !this.showDepositDialog;
        },

        logout() {
            axios.defaults.headers.common['Authorization'] = ''
            this.$store.commit('removeToken');
            this.$router.push('/');
            this.openMenu();
        },


        validatePhoneNumber() {
            const phoneNumber = this.phoneNumber.trim();
            const isValidFormat = /^(01|07)\d{8}$/.test(phoneNumber);

            if (!isValidFormat) {
                this.showErrorNotification('Please enter a valid phone number starting with 01 or 07 and having 10 digits.');
                return false;
            }

            return true;
        },

        async initiateSTKPush() {
            this.setLoadingStatus(true);

            if (!this.validatePhoneNumber()) {
                this.setLoadingStatus(false);
                return;
            }

            try {
                const phoneNumber = '254' + this.phoneNumber.slice(1);
                const Amount = this.depositAmount;
                this.mpesaPushResponse = []

                const data = {
                    customer_id: this.customerDetails.customer_id,
                    phone_number: phoneNumber,
                    amount: Amount,
                };

                await axios
                    .post('/mpesa/wallet/stk-push/', data)
                    .then((response) => {
                        const pushResponse = response.data;

                        if (response.status == 200) {
                            this.showSuccessNotification('STK push success, Check phone');
                            this.showSuccessNotification('Transaction window will last 15 seconds');
                            
                        } else {
                            this.showErrorNotification('STK push failed');
                        }

                        this.mpesaPushResponse.push({
                            MerchantRequestID: pushResponse.MerchantRequestID,
                            CheckoutRequestID: pushResponse.CheckoutRequestID
                        });

                    })
                    .catch((error) => {
                        this.showErrorNotification(
                            "Error initiating STK push. Please check your connection and try again!"
                        );
                    });
            } catch (error) {
                this.$toast.error(
                    "Something went wrong. Please try again!",
                    {
                        position: 'bottom-right',
                        duration: 3000,
                    }
                )
            }

            setTimeout(async () => {
                await this.verifyPayment();
            }, 15000);
        },

        async verifyPayment() {
            await axios
                .get('/mpesa/wallet/callback')
                .then(async (responses) => {
                    const callbackResponses = responses.data.responses;

                    for (const x in callbackResponses) {
                        const confirmMerchantRequestID = callbackResponses[x].body.Body.stkCallback.MerchantRequestID;
                        const confirmCheckoutRequestID = callbackResponses[x].body.Body.stkCallback.CheckoutRequestID;

                        if (confirmMerchantRequestID === this.mpesaPushResponse[0].MerchantRequestID && confirmCheckoutRequestID === this.mpesaPushResponse[0].CheckoutRequestID) {
                            const stkCallback = callbackResponses[x].body.Body.stkCallback;

                            if (stkCallback.ResultCode === 0) {
                                this.showSuccessNotification("Payment successful");

                                this.$store.state.customerLastFetched = null
                                this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)

                                this.toggleDepositDialog()

                            } else {
                                this.showErrorNotification("Payment failed due to cancellation");
                            }
                        }
                    }

                    this.setLoadingStatus(false);
                })
                .catch((error) => {
                    this.showErrorNotification("Error verifying payment");
                });

            this.setLoadingStatus(false);
        },

        showSuccessNotification(message) {
            this.$toast.success(message, {
                position: 'bottom-right',
                duration: 4000,
            });
        },
        showErrorNotification(message) {
            this.$toast.error(message, {
                position: 'bottom-right',
                duration: 3000,
            });
        },
    },
}
</script>