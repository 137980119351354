<template>
    <div class="text-justify bg-gray-200 px-9 md:px-24 py-8 md:py-20">
        <div class="font-bold text-4xl text-gray-900 text-center mb-5">Refund Policy</div>

        <ol class="list-decimal">
            <li class="font-bold text-2xl mb-5">Damaged/Missing Delivery Refund Procedure:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>In the event of receiving a damaged or tampered package, customers are required to immediately report the issue to Timely Delivery's customer service team via Instagram DM, WhatsApp, or call while still at the Pickup Point or agent location.</li>
                <li>Customers must share an image of the damaged product or provide necessary documentation to support the claim.</li>
                <li>Timely Delivery reserves the right to assess the validity of the claim and determine eligibility for a refund.</li>
            </ul>
    
            <li class="font-bold text-2xl mb-5">Eligibility for Refund:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>Refunds for damaged or missing deliveries will be processed if the claim is reported within the specified timeframe and is supported by appropriate evidence</li>
                <li>Refunds will not be issued for items deemed fragile, as delivery of such items is at the owner's risk, and Timely Delivery shall not be held liable for any damage claim.</li>
            </ul>
    
            <li class="font-bold text-2xl mb-5">Refund Procedure:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>Upon validation of the claim, Timely Delivery will initiate the refund process</li>
                <li>Refunds will be processed through the original payment method used for the transaction</li>
                <li>Customers may be required to provide additional documentation, such as sender name and MPesa statements, to facilitate the refund process.</li>
            </ul>
            
            <li class="font-bold text-2xl mb-5">Maximum Liability:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>Timely Delivery's liability for any claim for loss or damage shall not exceed Kshs. 2000.</li>
                <li>Customers acknowledge and accept this maximum liability limit as outlined in the Terms and Conditions.</li>
            </ul>
            
            <li class="font-bold text-2xl mb-5">Claim Deadline:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>Claims for damaged or missing deliveries must be reported within 3 days of the delivery date.</li>
                <li>Timely Delivery shall not be held liable for any claims made after this specified period.</li>
            </ul>
    
            <li class="font-bold text-2xl mb-5">Unclaimed Returns and Disposal:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>Customers are responsible for collecting returned packages within the stipulated timeframe.</li>
                <li>Uncollected returns will be disposed of after 30 days from the date of return.</li>
                <li>Packages that have exceeded 10 days from the date of dispatch will accrue a daily fee of Ksh. 50 until collected.</li>
            </ul>
    
            <li class="font-bold text-2xl mb-5">Acknowledgement of Refund Policy:</li>
            <ul class="leading-loose font-gray-700 mb-5">
                <li>By utilizing Timely Delivery's services, customers acknowledge and agree to abide by the terms and conditions outlined in the refund policy.</li>
                <li>Continued use of Timely Delivery's services constitutes acceptance of the refund policy.</li>
            </ul>
    
        </ol>

    </div>

</template>

<script>
    export default {
        name: 'RefundPolicy'
    }
</script>