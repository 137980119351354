<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">In Transit</h2>
                        <p class="text-gray-600">You have <span class="font-semibold">{{ inTransitOrders.length }}</span> order<span v-if="inTransitOrders.length > 1 || inTransitOrders.length == 0">s</span> in transit</p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="inTransitOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Destination</th>
                                    <th scope="col" class="px-6 py-3">To</th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                    <th scope="col" class="px-6 py-3">Date Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in inTransitOrders" :key="order.id" class="text-gray-700 divide-y divide-gray-100" :class="order.days_stayed_at_agent > 3 ? 'bg-red-300 hover:bg-red-500' : 'bg-white hover:bg-gray-50'">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/driver/pending/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">{{ order.destination }}</td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.status === 'PACKAGE IN TRANSIT TO AGENT'">AGENT</span>
                                        <span v-else-if="order.status === 'PACKAGE IN TRANSIT TO OFFICE'">OFFICE</span>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.agent_receiving_status === 'NULL'">In Transit</span>
                                        <span v-else-if="order.agent_receiving_status === 'PENDING'">Pending</span>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.date_leaving_for_office">{{ order.date_leaving_for_office.split('T')[0] }}</span>
                                        <span v-else>{{ order.date_leaving_office.split('T')[0] }}</span>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InTransit',
    computed: {
        ...mapGetters(['getParcelsByDriverId', 'getDriverDetails', 'getCustomerDetails']),

        driverDetails() {
            return this.getDriverDetails
        },

        driverOrders() {
            return this.getParcelsByDriverId(this.driverDetails.driver_id);
        },

        inTransitOrders() {
            return this.driverOrders.filter(parcel => (parcel.status === 'PACKAGE IN TRANSIT TO OFFICE' && parcel.dropoff_driver === this.driverDetails.driver_id) || (parcel.status === 'PACKAGE IN TRANSIT TO AGENT' && parcel.driver === this.driverDetails.driver_id));
        },

    },
}
</script>