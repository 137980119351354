<template>
    <div class="items-center bg-gray-200 pb-10">
        <div class="items-center py-12">
            <h1 class="text-center text-2xl text-black font-bold">404 - Page Not Found</h1>
        </div>
        <p><br></p>


        <div class="border-b border-gray-200">
            <div class="mb-8 md:mx-24 w-auto items-center px-8 md:px-52">
                <div class="mb-7">
                    <p class="text-center text-gray-900">The page you are looking for does not exist.</p>
                </div>
                <p><br></p>
                <p><br></p>


                <div class="mb-7 text-center">
                    <router-link to="/"
                        class="block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        Go to Home
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
}
</script>