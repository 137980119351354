<template>
    <div class="text-justify bg-gray-200 px-24 py-20">
        <div class="font-bold text-4xl text-gray-900 text-center mb-5">
            Privacy Policy of Timely Delivery Services
        </div>
        
        <ol class="list-decimal">

            <li class="font-bold text-2xl mb-5">Collection and Use of Personal Information:</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery collects personal information such as sender and receiver details, package tracking data, and payment information to facilitate parcel delivery services. This information is used solely for the execution of delivery services and is not shared with third parties except as necessary to fulfil the service.</div>

            <li class="font-bold text-2xl mb-5">Data Security:</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery employs industry-standard security measures to safeguard personal information against unauthorized access, disclosure, alteration, or destruction. Access to personal data is restricted to authorized personnel only.</div>

            <li class="font-bold text-2xl mb-5">Information Sharing:</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery may share personal information with designated agents and service providers solely to execute delivery services. Additionally, information may be shared in compliance with legal obligations or to protect the rights, property, or safety of Timely Delivery, its clients, or others.</div>

            <li class="font-bold text-2xl mb-5">Data Retention:</li>
            <div class="leading-loose font-gray-700 mb-5">Personal information collected by Timely Delivery is retained only for as long as necessary to fulfill the purposes for which it was collected or as required by law. Upon completion of services, personal data may be securely archived or deleted in accordance with applicable regulations.</div>

            <li class="font-bold text-2xl mb-5">User Rights:</li>
            <div class="leading-loose font-gray-700 mb-5">Users have the right to access, correct, or delete their personal information held by Timely Delivery. Requests for such actions can be made by contacting Timely Delivery's customer service team.</div>

            <li class="font-bold text-2xl mb-5">Third-Party Links:</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery's website or applications may contain links to third-party websites or services. Timely Delivery is not responsible for the privacy practices or content of such third-party sites and encourages users to review the privacy policies of these entities.</div>

            <li class="font-bold text-2xl mb-5">Policy Updates:</li>
            <div class="leading-loose font-gray-700 mb-5">Timely Delivery reserves the right to update or amend this privacy policy as necessary to reflect changes in legal requirements or business practices. Users will be notified of any significant updates to the policy.</div>

            <li class="font-bold text-2xl mb-5">Consent:</li>
            <div class="leading-loose font-gray-700 mb-5">By engaging with Timely Delivery's services, users consent to the collection, use, and disclosure of personal information as outlined in this privacy policy.</div>

            <li class="font-bold text-2xl mb-5">Contact Information:</li>
            <div class="leading-loose font-gray-700 mb-5">For inquiries or concerns regarding privacy practices, users may contact Timely Delivery's privacy officer at [contact email or phone number].</div>

            <li class="font-bold text-2xl mb-5">Effective Date:</li>
            <div class="leading-loose font-gray-700 mb-5">This privacy policy is effective as of [insert effective date] and supersedes any prior versions.</div>

            <div class="leading-loose font-gray-700 mb-5">
                By continuing to use Timely Delivery's services, users acknowledge their understanding and acceptance of this privacy policy.
            </div>

        </ol>

    </div>
</template>

<script>
    export default {
        name: 'PrivacyPolicy'
    }
</script>