<template>

    <!-- Header -->
    <div class="flex justify-between mt-3 mx-10 mb-8 items-center">
        <div>
            <button @click="$router.go(-1)" class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">
                
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z" fill="currentColor"/>
                </svg>
    
                <span>Back</span>
                
            </button>

        </div>

        <div>
            <div class="text-gray-500 font-light tracking-normal text-right text-sm">Step 03/03</div>
            <div class="text-gray-600 font-medium tracking-normal text-right text-sm">M-Pesa verification</div>

        </div>

    </div>

    <form @submit.prevent="submitForm" class="mb-8 mx-24 w-auto">
        <div class="mb-12">
            <h1 class="text-md font-bold leading-relaxed tracking-light text-gray-900 md:text-2xl">Verify M-Pesa Number</h1>

            <h4 class="mt-2 text-sm font-semibold leading-relaxed tracking-tight text-gray-500 md:text-xl">
                Please verify your M-Pesa number to be used for payments.
            </h4>
        </div>
            

        <!-- MPesa Verification Number -->
        <div class="relative z-0 w-full mb-5 group">
            
            <input type="text" name="bvn" id="bvn" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer" placeholder=" " required />
            <label for="bvn" class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">M-Pesa Number (07xx-xxx-xxx)</label>
        </div>


        <!-- Register button -->
        <button type="submit" class="block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Save &amp; Continue</button>

    </form>
</template>

<script>
    export default {
        name: 'CompleteProfile',
        methods: {
            submitForm() {
                this.$router.push('/')
            }
        }
    }

</script>