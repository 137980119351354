<template>
  <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <h1 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot Your Password?</h1>

      <p class="mt-5 text-center text-lg text-gray-500">
        No worries, we're here to help! To reset your password, please give us a call at 
        <span class="font-bold">{{ companyPhoneNumber }}</span> between 8am and 8pm. 
      </p>

      <p class="mt-2 text-center text-lg text-gray-500">
        Please have your registered email address and full name ready so we can verify your identity.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  computed: {
    ...mapGetters(['companyPhoneNumber']),
  },
};
</script>
