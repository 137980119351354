<template>
    <div class="items-center bg-gray-200">
        <div>
            <img src="../../assets/homepage/homepage2.jpg" alt="" class="rounded-t-lg h-[200px] object-none w-full md:rounded-none md:rounded-s-lg">
        </div>

        <router-view />
        
    </div>
</template>

<script>
    export default {
        name: 'ServiceForm'
    }
</script>