<template>
    <div class="container mx-auto bg-white">
        <!-- Title -->
        <div class="bg-gray-300 mx-auto container py-12 px-8 -mt-7">
            <h1 class="font-bold text-4xl text-gray-900">My Parcels</h1>
        </div>

        <div>
            <div class="grid grid-cols-1 lg:grid-cols-2 items-center mt-8">
                <!-- Package Status -->
                <div class="col-span-1 lg:col-span-1 px-10 flex gap-x-12 items-center">
                    <label for="route" class="block mb-2 text-base font-medium text-gray-900 text-center ">Package status:</label>
                    <select id="packageStatusFilter" v-model="packageStatusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Package Status">
                        <option value="">All</option>
                        <option v-for="(status, index) in packageStatuses" :key="index" :value="index">{{ status }}</option>
                    </select>
                </div>

                <div class="col-span-1 lg:col-span-1 px-10 flex gap-x-12 items-center">
                    <label for="route" class="block mb-2 text-base font-medium text-gray-900 text-center ">Payment status:</label>
                    <select id="paymentStatusFilter" v-model="paymentStatusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Package Status">
                        <option value="">All</option>
                        <option value="PAID">Paid</option>
                        <option value="UNPAID">Unpaid</option>
                    </select>
                </div>
            </div>
        </div>

        

        <div class="relative overflow-x-auto my-12">
            <table class="w-full text-sm text-left text-gray-500 ">
                <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                        <th scope="col" class="px-6 py-3">
                            #
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Parcel Number
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Receiver
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Agent
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Destination
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Date
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Value
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Payment Status
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Status
                        </th>
                    </tr>
                </thead>
                
                <!-- Show parcel details when details loaded -->
                <tbody v-if="isLoaded">
                    <tr v-if="filteredParcels.length" v-for="(parcel, index) in filteredParcels" :key="parcel.invoice_id" class="bg-white border-b">
                        <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            {{ index + +1 }}
                        </th>
                        <td class="px-6 py-3 hover:text-blue-500">
                            <router-link :to="'/my-parcels/invoice/' + parcel.id" >{{ parcel.invoice_id }}</router-link>
                        </td>
                        <td class="px-6 py-3">
                            {{ parcel.receiver }}
                        </td>
                        <td class="px-6 py-3">
                            {{ parcel.agent }}
                        </td>
                        <td class="px-6 py-3">
                            {{ parcel.destination }}
                        </td>
                        <td class="px-6 py-3">
                            {{ parcel.created.split('T')[0] }}
                        </td>
                        <td class="px-6 py-3">
                            Kshs. {{ parcel.parcel_value }}
                        </td>
                        <td class="px-6 py-3 font-semibold"  >
                            <span v-if="parcel.payment_status === 'UNPAID'" class="rounded-full bg-red-500 px-6 py-1 text-white font-semibold">{{ parcel.payment_status }}</span>
                            <span v-if="parcel.payment_status === 'PAID'" class="rounded-full bg-green-500 text-white font-semibold px-8 py-1">{{ parcel.payment_status }}</span>
                        </td>
                        <td class="px-6 py-3">
                            <span v-if="parcel.status === 'PACKAGE CANCELLED'" class="px-6 py-1 text-red-700 font-semibold" >CANCELLED</span>

                            <span v-else-if="parcel.payment_status === 'UNPAID'" class="rounded-full bg-red-500 px-3 py-1 text-white font-semibold truncate" >Pending Payment</span>
                            
                            <span v-else>
                                <span v-if="parcel.status === 'PACKAGE CREATED'" class="rounded-full bg-gray-400 py-1 text-white font-semibold" >
                                    <span v-if="parcel.collector" class="px-3 truncate" >Pending Collection</span>
                                    <span v-else-if="parcel.dropoff_destination" class="px-4 truncate" >Pending dropoff</span>
                                    <span v-else class="px-12" >Ready</span>
                                </span>

                                <span v-else-if="parcel.status === 'COLLECTED BY COLLECTOR'" class="rounded-full bg-gray-600 px-10 py-1 text-white font-semibold truncate" >Collected</span>
                                
                                <span v-else-if="parcel.status === 'DELIVERED TO DROPOFF AGENT'" class="rounded-full bg-gray-600 px-7 py-1 text-white font-semibold truncate" >Dropped Off</span>

                                <span v-else-if="parcel.status === 'PACKAGE IN OFFICE'" class="rounded-full bg-gray-800 px-3 py-1 text-white font-semibold truncate" >
                                    <span v-if="parcel.rider" class="px-1 truncate" >Pending Delivery</span>
                                    <span v-else class="px-7 truncate" >In Office</span>
                                </span>

                                <span v-else-if="parcel.status === 'PACKAGE IN TRANSIT TO AGENT'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >In Transit</span>

                                <span v-else-if="parcel.status === 'PACKAGE IN TRANSIT TO OFFICE'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >In Transit</span>

                                <span v-else-if="parcel.status === 'DELIVERED TO AGENT'" class="rounded-full bg-green-500 px-3 py-1 text-white font-semibold truncate" >Waiting Recepient</span>

                                <span v-else-if="parcel.status === 'RECEIVED BY RECIPIENT'" class="px-6 py-1 text-gray-400 font-semibold" >FULFILLED</span>

                                <span v-else-if="parcel.status === 'RETURN PACKAGE'" class="px-6 py-1 text-red-400 font-semibold" >RETURNED</span>

                            </span>

                        </td>
                    </tr>

                    <!-- No parcels -->
                    <tr v-else>
                        <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                            No parcels
                        </th>
                    </tr>
                </tbody>

                <!-- Loading indicator -->
                <div v-else>
                    <div>
                        <div class="flex items-center justify-center px-6 py-3">
                            <div class="flex justify-center items-center">
                                <div class="mr-2">Loading...</div>
                                <LoadingIcon />
                                
                            </div>
                        </div>
                    </div>
                </div>

                
            </table>
        </div>

    </div>
</template>

<script>
    import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
    
    export default {
        name: 'myParcels',
        data() {
            return {
                isLoaded: false,
                parcels: [],
                agentName: '',
                agentID: '',

                packageStatusFilter: '',
                paymentStatusFilter: ''
            }
        },
        components: {
            LoadingIcon,
        },
        async created() {
            await this.$store.commit('removeNewParcel')
            
            await this.$store.dispatch('fetchAllParcels');

            this.isLoaded = true

        },

        computed: {
            ...mapGetters(['getParcelsByCustomerId', 'getCustomerDetails', 'getPackageStatuses']),

            customerDetails() {
                return this.getCustomerDetails
            },

            packageStatuses() {
                return this.getPackageStatuses
            },

            customerParcels() {
                return this.getParcelsByCustomerId(this.customerDetails.customer_id)
            },

            filteredParcels() {
                let parcelsData = this.customerParcels

                parcelsData = parcelsData.filter(parcel => {
                    const statusMatch = this.packageStatusFilter.trim() === '' || parcel.status == this.packageStatusFilter;
                    const paymentMatch = this.paymentStatusFilter.trim() === '' || parcel.payment_status == this.paymentStatusFilter;

                    return statusMatch && paymentMatch
                })

                return parcelsData
            }
        },

        methods: {
        }
    }
</script>


