<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Report</h2>
                        <p class="text-gray-600">Total Orders completed: <span class="font-semibold">{{ completedOrders.length }}</span></p>
                        <p class="text-gray-600">Total Commission balance: <span class="font-semibold">Kshs. {{ agentDetails.agent_commission }}</span></p>
                        <p class="text-gray-600">Total Commission earned: <span class="font-semibold">Kshs. {{ agentDetails.total_earned }}</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="completedOrders.length"  ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Type</th>
                                    <th scope="col" class="px-6 py-3">Service</th>
                                    <th scope="col" class="px-6 py-3">Commission</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in completedOrders" :key="order.id" class="text-gray-600 divide-y divide-gray-100" :class="order.days_stayed_at_agent > 3 ? 'bg-red-300 hover:bg-red-500' : 'bg-white hover:bg-gray-50'">
                                    <td class="px-6 py-3">{{ index + +1 }}</td>
                                    <td class="px-6 py-3">{{ order.invoice_id }}</td>
                                    <td class="px-6 py-3">{{ order.parcel_type }}</td>
                                    <td class="px-6 py-3">{{ order.route_type }}</td>
                                    <td class="px-6 py-3">KSHS. 
                                        <span v-if="order.customer_collection_date">{{ orderCommission(order.route_type) }}</span>
                                        <span v-else>20</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "Report",
    data() {
        return {
            agentID: localStorage.getItem('agentID'),
        }
    },
    computed: {
        ...mapGetters(['getParcelsByAgentId', 'getAgentDetails']),

        agentDetails() {
            return this.getAgentDetails
        },

        agentParcels() {
            return this.getParcelsByAgentId(this.agentDetails.agent_id);
        },

        completedOrders() {
            return this.agentParcels.filter(parcel => (parcel.status === 'RECEIVED BY RECIPIENT' && parcel.agent === this.agentDetails.agent_id) || (parcel.dropoff_agent === this.agentDetails.agent_id && parcel.date_leaving_for_office))
        },

    },
    async created () {
        await this.$store.dispatch('fetchParcels');
        await this.$store.dispatch('fetchAgentDetails', this.agentDetails.agent_id);
    },
    methods: {
        orderCommission(service) {
            let commission = 0

            if (service == 'CBD TO CBD') {
                commission = 50
            } else if (service == 'CBD TO MTAANI') {
                commission = 60
            } else if (service == 'CBD TO UPCOUNTRY') {
                commission = 70
            } else if (service == 'MTAANI TO CBD') {
                commission = 80
            } else if (service == 'MTAANI TO MTAANI') {
                commission = 90
            } else if (service == 'MTAANI TO UPCOUNTRY') {
                commission = 100
            }

            return commission
        }
    },

    
}
</script>