<template>
    <div v-if="showNotification" class="fixed top-0 left-0 right-0 z-50 p-4">
        <div class="bg-red-500 text-white p-4 rounded-md shadow-md flex items-center justify-between" role="alert"
            :class="{ 'bg-green-500': isSuccess }">
            <span class="font-bold">{{ isSuccess ? 'Success' : 'Error' }}:</span>
            <span>{{ message }}</span>
            <button class="text-white ml-4 hover:text-gray-300 focus:outline-none" @click="hideNotification">
                &times;
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Notification',
    data() {
        return {
            showNotification: false,
            message: '',
            isSuccess: false,
        };
    },
    methods: {
        showNotification(message, isSuccess) {
            this.message = message;
            this.isSuccess = isSuccess;
            this.showNotification = true;
        },
        hideNotification() {
            this.showNotification = false;
        },
    },
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateY(-100%);
}
</style>