<template>
    <Navbar />
    
    <router-view/>
  
    <Footer />
  </template>
  
  <script>
    import axios from 'axios'
    import { mapMutations } from 'vuex';
  
    import Navbar from '@/components/layout/Navbar.vue'
    import Footer from '@/components/layout/Footer.vue'
  
    export default {
      ...mapMutations(['initializeStore']),
  
      name: 'AppLayout',
      components: {
        Navbar,
        Footer
      },
      beforeCreate() {
        // this.initializeStore()
        this.$store.commit('initializeStore')
      }
    }
  
  </script>
  
  
  <style lang="scss">
  </style>
  