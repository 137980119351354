<template>
    <div class="">
        <p class="text-gray-500 text-sm font-light tracking-normal text-right m-5 md:mx-24 md:mb-16">Already have an account?
            <router-link to="/login" class="font-medium text-blue-600 hover:underline">Sign In</router-link>
        </p>
        
        <form @submit.prevent="submitForm" class="mx-5 my-5 md:mb-24 md:mx-24 w-auto">
            <div class="mb-12">
                <h1 class="text-xl font-bold leading-relaxed tracking-tight text-gray-900 md:text-xl">Join Us!</h1>
    
                <h4 class="mt-2 text-justify text-md font-semibold leading-relaxed tracking-tight text-gray-500 md:text-md">To begin this journey, tell us what type of account you'd be opening.</h4>
    
            </div>
    
            <div class=" grid w-full gap-6">
                <!-- Individual button -->
                <div>
                    <input type="radio" v-model="account_type" id="individual" name="account_type" v-bind:value="'Individual'" class="hidden peer"/>
    
                    <label for="individual" class="flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                        <svg class="hidden md:inline" width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 0L49.7275 17.9656L40.2824 47.0344H9.71758L0.272532 17.9656L25 0Z" fill="#1565D8"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7204 28.8869C19.5018 28.1055 20.5616 27.6665 21.6667 27.6665H28.3333C29.4384 27.6665 30.4982 28.1055 31.2796 28.8869C32.061 29.6683 32.5 30.7281 32.5 31.8332V33.4998C32.5 33.9601 32.1269 34.3332 31.6667 34.3332C31.2064 34.3332 30.8333 33.9601 30.8333 33.4998V31.8332C30.8333 31.1701 30.5699 30.5342 30.1011 30.0654C29.6323 29.5966 28.9964 29.3332 28.3333 29.3332H21.6667C21.0036 29.3332 20.3677 29.5966 19.8989 30.0654C19.4301 30.5342 19.1667 31.1701 19.1667 31.8332V33.4998C19.1667 33.9601 18.7936 34.3332 18.3333 34.3332C17.8731 34.3332 17.5 33.9601 17.5 33.4998V31.8332C17.5 30.7281 17.939 29.6683 18.7204 28.8869Z" fill="white"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9999 19.3332C23.6192 19.3332 22.4999 20.4525 22.4999 21.8332C22.4999 23.2139 23.6192 24.3332 24.9999 24.3332C26.3806 24.3332 27.4999 23.2139 27.4999 21.8332C27.4999 20.4525 26.3806 19.3332 24.9999 19.3332ZM20.8333 21.8332C20.8333 19.532 22.6987 17.6665 24.9999 17.6665C27.3011 17.6665 29.1666 19.532 29.1666 21.8332C29.1666 24.1344 27.3011 25.9998 24.9999 25.9998C22.6987 25.9998 20.8333 24.1344 20.8333 21.8332Z" fill="white"/>
                        </svg>
                        
                        <div class="block">
                            <div class="w-full text-lg font-semibold">Individual</div>
                            <div class="w-full">Personal account to manage all your activities</div>
                        </div>
    
                        <svg class="w-5 h-5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
    
                    </label>
                </div>
                
                <!-- Business button -->
                <div>
                    <input type="radio" v-model="account_type" id="business" name="account_type" v-bind:value="'Business'" class="hidden peer">
    
                    <label for="business" class="inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100">
                        <svg class="hidden md:inline" width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.09543 18.2329L25 0.865247L48.9046 18.2329L39.7738 46.3344H10.2262L1.09543 18.2329Z" stroke="#1565D8" stroke-width="1.4"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 22.6665C17.873 22.6665 17.4999 23.0396 17.4999 23.4998V31.8332C17.4999 32.2934 17.873 32.6665 18.3333 32.6665H31.6666C32.1268 32.6665 32.4999 32.2934 32.4999 31.8332V23.4998C32.4999 23.0396 32.1268 22.6665 31.6666 22.6665H18.3333ZM15.8333 23.4998C15.8333 22.1191 16.9525 20.9998 18.3333 20.9998H31.6666C33.0473 20.9998 34.1666 22.1191 34.1666 23.4998V31.8332C34.1666 33.2139 33.0473 34.3332 31.6666 34.3332H18.3333C16.9525 34.3332 15.8333 33.2139 15.8333 31.8332V23.4998Z" fill="#1565D8"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5655 18.3987C22.0343 17.9299 22.6702 17.6665 23.3333 17.6665H26.6666C27.3296 17.6665 27.9655 17.9299 28.4344 18.3987C28.9032 18.8676 29.1666 19.5035 29.1666 20.1665V33.4998C29.1666 33.9601 28.7935 34.3332 28.3333 34.3332C27.873 34.3332 27.4999 33.9601 27.4999 33.4998V20.1665C27.4999 19.9455 27.4121 19.7335 27.2558 19.5772C27.0996 19.421 26.8876 19.3332 26.6666 19.3332H23.3333C23.1122 19.3332 22.9003 19.421 22.744 19.5772C22.5877 19.7335 22.4999 19.9455 22.4999 20.1665V33.4998C22.4999 33.9601 22.1268 34.3332 21.6666 34.3332C21.2063 34.3332 20.8333 33.9601 20.8333 33.4998V20.1665C20.8333 19.5035 21.0966 18.8676 21.5655 18.3987Z" fill="#1565D8"/>
                        </svg>
                        
                        <div class="block">
                            <div class="w-full text-lg font-semibold">Business</div>
                            <div class="w-full">Own or belong to a company, this is for you.</div>
                        </div>
    
                        <svg class="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </label>
                </div>

                <button type="submit" class="block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">Next</button>

                
            </div>
    
    
        </form>

    </div>
</template>

<script>
    import { mapMutations } from 'vuex'

    export default {
        name: 'Register',

        data() {
            return {
                account_type: ''
            }  
        },

        methods: {
            ...mapMutations(['setCustomerRegistrationDetails']),

            async submitForm() {
                if (this.account_type === 'Individual') {
                    // account_type: this.account_type

                    const account_type = {
                        account_type: this.account_type.toUpperCase()
                    }

                    // console.log('Account type:', account_type['account_type'])

                    this.setCustomerRegistrationDetails(account_type)

                    this.$router.push('register/i/step-1')
                } else if (this.account_type === 'Business') {
                    // console.log('Business category')

                    const account_type = {
                        account_type: this.account_type.toUpperCase(),
                    }

                    // console.log('Account type: ', account_type['account_type'])

                    this.setCustomerRegistrationDetails(account_type)

                    this.$router.push('register/b/step-1')
                }     
                
            }
        }
    }
    
</script>
