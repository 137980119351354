<template>
  <div class="bg-gray-100 py-12">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2 class="text-base text-orange-600 font-semibold tracking-wide uppercase">About Us</h2>
        <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Timely Delivery
        </p>
        <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Simplifying Logistics, Delivering Happiness
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Our Mission</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              We are dedicated to simplifying logistics for businesses of all sizes, by providing a cost-effective, efficient, and professional delivery experience. Our goal is to be the trusted partner that SMEs, corporates, NGOs, and government institutions rely on for their delivery and fulfillment needs.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 01-9-9" />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Our Services</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              <ul class="list-disc list-inside space-y-2">
                <li>
                  <span class="font-bold">Agent Delivery Model:</span> Our agent-based delivery system ensures flexibility, allowing businesses to outsource their last-mile delivery operations while maintaining real-time tracking and professional handling of their goods.
                </li>
                <li>
                  <span class="font-bold">Corporate Parcel Delivery:</span> We offer specialized parcel delivery services for corporate clients, providing a seamless and secure way to send documents, packages, and other essential items across Kenya and Uganda.
                </li>
                <li>
                  <span class="font-bold">Storage and Fulfillment Services:</span> For businesses that require more than just delivery, we provide comprehensive storage and fulfillment solutions, including inventory management, warehousing, and order fulfillment, ensuring that your products are always handled with care and precision.
                </li>
              </ul>
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Our Coverage</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              With operations spanning Kenya and Uganda, Timely Delivery is strategically positioned to serve businesses across the region. Our head office is located in Nairobi, Kenya, with plans to expand further as we continue to grow and innovate.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-500 text-white">
                <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Why Choose Us?</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              <ul class="list-disc list-inside space-y-2">
                <li>
                  <span class="font-bold">Experience:</span> Since 2019, we have built extensive expertise in handling complex logistics for diverse industries.
                </li>
                <li>
                  <span class="font-bold">Regional Reach:</span> Our presence in Kenya and Uganda allows us to cover both local and cross-border delivery needs.
                </li>
                <li>
                  <span class="font-bold">Customized Solutions:</span> We understand that each client has unique requirements, and we tailor our services to ensure their specific needs are met.
                </li>
                <li>
                  <span class="font-bold">Professionalism:</span> Our team of experienced agents and drivers are committed to ensuring that every parcel is delivered on time and in perfect condition.
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>

      <div class="mt-12 lg:text-center">
        <p class="text-xl text-gray-500">
          Let us handle your logistics so you can focus on growing your business.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>
