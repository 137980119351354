export default class PasswordValidation {
    static minLength(name, minLength) {
        if (name.length < minLength) {
            return false
        }

        return true
    }

    static uppercaseCheck(name) {
        let passw = new RegExp("^(?=.*[A-Z]).+$")

        return passw.test(name)
        
    }

    static lowercaseCheck(name) {
        let passw = new RegExp("^(?=.*[a-z]).+$")

        return passw.test(name)
    }

    static numberCheck(name) {
        let passw = new RegExp("^(?=.*[0-9]).+$")

        return passw.test(name)
    }

    // static specialCharacterCheck(name) {
    //     let passw = new RegExp("^(?=.*[-+_!@#$%^&*., ?]).+$")

    //     return passw.test(name)
    // }

}