<template>
    <div class="container mx-auto bg-white">
        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="grid gap-y-4 bg-gray-100 shadow-md rounded-md mx-auto container p-4 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Withdrawals</h1>
                <p class="text-gray-600">Pending: <span class="font-semibold">{{ pendingRequests }}</span></p>
                <p class="text-gray-600">Approved: <span class="font-semibold">{{ approvedRequests }}</span></p>
                <p class="text-gray-600">Declined: <span class="font-semibold">{{ declinedRequests }}</span></p>
            </div>


            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-3 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-3">Filters</h1>

                <!-- Customer Name -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="customerName" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Customer Name</label>
                    <input type="text" id="customerFilter" v-model="customerFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Customer Name" />
                </div>

                <!-- Disburse to -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="disburseToFilter" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Disburse To</label>
                    <select id="disburseToFilter" v-model="disburseToFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Disburse To">
                        <option value="">All</option>
                        <option value="WALLET">Wallet</option>
                        <option value="MPESA">Mpesa</option>
                    </select>
                </div>

                <!-- Status -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="statusFilter" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Status</label>
                    <select id="statusFilter" v-model="statusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Status">
                        <option value="">All</option>
                        <option value="PENDING">Pending</option>
                        <option value="REQUEST ACCEPTED">Approved</option>
                        <option value="REQUEST DENIED">Declined</option>
                    </select>
                </div>

            </div>

            <!-- Sort by | Download report button button -->
            <div class="flex justify-between items-center mt-8 -mb-8">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="customer">Customer</option>
                        <option value="amount">Amount</option>
                        <option value="date">Date</option>
                    </select>
                </div>
                
                <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading" >Download Report</button>

            </div>


            <!-- Withdrawals table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="withdrawalsTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'transactionType' }">
                                <span>Customer</span>
                                <button v-if="sortBy == 'customer'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'amount' }">
                                <span>Amount</span>
                                <button v-if="sortBy == 'amount'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                From
                            </th>
                            <th scope="col" class="px-6 py-3">
                                To
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Mpesa Number
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'date' }">
                                <span>Date</span>
                                <button v-if="sortBy == 'date'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">Status</th>
                            <th scope="col" class="px-6 py-3"></th>
                        </tr>
                    </thead>

                    <!-- Show transactions details when details loaded -->
                    <tbody v-if="withdrawals">
                        <tr v-if="filteredWithdrawals.length" v-for="(withdrawal, index) in filteredWithdrawals" :key="withdrawal.request_id"
                            class="bg-white border-b hover:bg-gray-50">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3">
                                {{ withdrawal.customer_name }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                KSHS. {{ withdrawal.amount }}
                            </td>
                            <td class="px-6 py-3">
                                {{ withdrawal.disburse_from }}
                            </td>
                            <td class="px-6 py-3">
                                {{ withdrawal.disburse_to }}
                            </td>
                            <td class="px-6 py-3">
                                <span v-if="withdrawal.phone_number">{{ withdrawal.phone_number }}</span>
                                <span v-else>N/A</span>
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ withdrawal.created_at.split('T')[0] }}
                            </td>
                            <td class="px-6 py-3 text-center">
                                <span v-if="withdrawal.disbursment_status == 'REQUEST ACCEPTED'" class="rounded-full bg-green-500 px-3 py-1 text-white font-semibold truncate">APPROVED</span>
                                <span v-else-if="withdrawal.disbursment_status == 'REQUEST DENIED'" class="rounded-full bg-red-500 px-3 py-1 text-white font-semibold truncate">DECLINED</span>
                                <span v-else class="rounded-full bg-orange-500 px-3 py-1 text-white font-semibold truncate">{{ withdrawal.disbursment_status }}</span>
                            </td>
                            <td class="px-6 py-3">
                                <div v-if="withdrawal.disbursment_status == 'PENDING'" class="flex gap-x-4">
                                    <button type="button" class="bg-green-500 text-white text-sm font-medium rounded-md px-2 py-2.5 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 " @click="fulfillRequest(withdrawal.request_id)" :disabled="this.$store.state.isLoading">
                                        <span v-if="$store.state.isLoading"><LoadingIcon /></span>
                                        <span v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                            </svg>
                                        </span>
                                    </button>
                                    <button type="button" class="bg-red-500 text-white text-sm font-medium rounded-md px-2 py-2.5 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 " @click="declineRequest(withdrawal.request_id)" :disabled="this.$store.state.isLoading">
                                        <span v-if="$store.state.isLoading"><LoadingIcon /></span>
                                        <span v-else>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>

                                        </span>
                                    </button>

                                </div>
                            </td>
                        </tr>

                        <!-- No transactions -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No Requests
                            </th>
                        </tr>

                    </tbody>

                </table>
            </div>

        </div>
    </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'Withdrawals',
        data() {
            return {
                disburseToFilter: '',
                customerFilter: '',
                statusFilter: '',
                customer_id: '',

                sortBy: '',
                sortAscending: false
            }
        },
        computed: {
            ...mapGetters(['getWithdrawals', 'getCustomerById']),

            withdrawals() {
                return this.getWithdrawals;
            },

            filteredWithdrawals() {
                let withdrawalsData = this.withdrawals

                withdrawalsData = withdrawalsData.filter(withdrawal => {
                    const customerMatch = this.customerFilter ? withdrawal.customer_name.toLowerCase().includes(this.customerFilter.toLowerCase()) : true
                    const disburseToMatch = this.disburseToFilter.trim() === '' || withdrawal.disburse_to == this.disburseToFilter;
                    const statusMatch = this.statusFilter.trim() === '' || withdrawal.disbursment_status == this.statusFilter;

                    return customerMatch && disburseToMatch && statusMatch;
                })

                if (this.sortBy == 'customer') {
                    withdrawalsData = withdrawalsData.sort((a, b) => {
                        return this.sortAscending ? a.customer_name.localeCompare(b.customer_name) : b.customer_name.localeCompare(a.customer_name);
                    })
                } else if (this.sortBy == 'amount') {
                    withdrawalsData = withdrawalsData.sort((a, b) => {
                        return this.sortAscending ? a.amount - b.amount : b.amount - a.amount;
                    })
                } else if (this.sortBy == 'date') {
                    withdrawalsData = withdrawalsData.sort((a, b) => {
                        return this.sortAscending ? new Date(a.created_at) - new Date(b.created_at) : new Date(b.created_at) - new Date(a.created_at);
                    })
                }

                return withdrawalsData
                
            },

            pendingRequests() {
                return this.withdrawals.filter(withdrawal => withdrawal.disbursment_status == 'PENDING').length
            },

            approvedRequests() {
                return this.withdrawals.filter(withdrawal => withdrawal.disbursment_status == 'REQUEST ACCEPTED').length
            },

            declinedRequests() {
                return this.withdrawals.filter(withdrawal => withdrawal.disbursment_status == 'REQUEST DENIED').length
            },

            customerDetails() {
                return this.getCustomerById(this.customer_id)
            }
        },
        components: {
            LoadingIcon,
        },
        async created () {
            await this.$store.dispatch('fetchWithdrawals');
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            async fulfillRequest(request_id) {
                /**
                 * Fulfill a withdrawal request
                 * @param request_id: Id of the request
                 * TODO: Details to update:
                 *  * disbursment_status: "REQUEST ACCEPTED"
                 */

                this.setLoadingStatus(true)

                const data = {
                    disbursment_status: 'REQUEST ACCEPTED'
                }

                await axios
                    .put(`/withdraw/${request_id}/`, data)
                    .then(async (response) => {
                        const withdrawal = response.data
                        console.log("Withdrawal (response.data): ", withdrawal);
                        this.customer_id = withdrawal.customer

                        this.$toast.success(
                            "Successfully approved request!",
                            {
                                duration: 5000,
                                position: 'bottom-right'
                            }
                        )

                        if (withdrawal.disburse_from == 'COD_BALANCE' && withdrawal.disburse_to == 'WALLET') {
                            // transfer from COD_balance to wallet
                            console.log("Updating details ...");
                            await this.fromCodBalanceToWallet(withdrawal.customer, withdrawal.amount)

                        } else {
                            console.log("Not applicable");
                            
                        }

                        this.$store.state.withdrawalsLastFetched = null
                        this.$store.dispatch('fetchWithdrawals')
                    })
                    .catch((error) => {
                        console.log("Error approving request: ", error)

                        this.$toast.error(
                            "Error approving request!",
                            {
                                duration: 5000,
                                position: 'bottom-right'
                            }
                        )
                    })

                this.setLoadingStatus(false)
            },

            // transfer from COD_balance to wallet
            async fromCodBalanceToWallet(customer_id, amount) {
                /**
                 * Transfer money from customer's Cod_balance to Wallet
                 * @param customer_id: customer UUID,
                 *      amount: amount to be added to wallet and deducted from COD_Balance
                 */

                this.setLoadingStatus(true)

                console.log("fromCodBalanceToWallet (customer_id): ", customer_id);
                console.log("fromCodBalanceToWallet (amount): ", amount);
                
                try {
                    await this.updateCodBalance(customer_id, amount)
    
                    await this.updateWallet(customer_id, amount)
    
                    await this.updateTotalEarned(customer_id, amount)
                    
                } catch (error) {
                    console.error("Error calling other functions (fromCodBalanceToWallet): ", error);
                    
                    this.$toast.error(
                        "Something went wrong! Please try again!",
                        {
                            duration: 3000,
                            position: 'bottom-right'
                        }
                    )
                }


                this.setLoadingStatus(false)
            },

            async updateCodBalance(customer_id, amount) {
                /**
                 * Update COD Balance
                 * @param customer_id: customer's ID
                 * @param amount: amount to be deducted
                 * 
                 * @data customer_id: customer_id,
                 *      CODbalance: customerDetails.COD_balance - amount
                 */

                this.setLoadingStatus(true)

                // const customerDetails = await this.getCustomerDetails(customer_id)
                // console.log("Customer details (updateCodBalance): ", customerDetails);

                const codData = {
                    customer_id: customer_id,
                    CODbalance: this.customerDetails.COD_balance - amount
                }

                console.log("Updating COD Balance (data): ", codData);

                await axios
                    .put(`customercodbalance/update/`, codData)
                    .then(async (response) => {
                        console.log("Successfully updated cod balance (withdrawals)");
                    })
                    .catch((error) => {
                        console.log("Error updating cod balance (withdrawals): ", error)
                        this.$toast.error(
                            "Something went wrong. Please try again!",
                            {
                                duration: 3000,
                                position: 'bottom-right'
                            }
                        )
                    })

                this.setLoadingStatus(false)
            },

            async updateTotalEarned(customer_id, amount) {
                /**
                 * Updating customer's total earned value
                 * @param customer_id: customer's ID
                 *      amount: amount to be added
                 * 
                 * @data customer_id: customer's ID
                 *      amount: customerDetails.Total_earned + amount
                 */

                this.setLoadingStatus(true)

                // const customerDetails = await this.getCustomerDetails(customer_id)
                // console.log("Customer details (updateTotalEarned): ", customerDetails);

                const data = {
                    customer_id: customer_id,
                    TotalEarned: this.customerDetails.Total_earned + +amount
                }
                console.log("Updating Total Earned (data): ", data);

                await axios
                    .put(`customertotalearned/update/`, data)
                    .then((response) => {
                        console.log("Successfully updated total earned (withdrawals)");
                    })
                    .catch((error) => {
                        console.log("Error updating total earned (withdrawals): ", error)
                        this.$toast.error(
                            "Something went wrong. Please try again!",
                            {
                                duration: 3000,
                                position: 'bottom-right'
                            }
                        )
                    })

                this.setLoadingStatus(false)
            },

            async updateWallet(customer_id, amount) {
                /**
                 * Update customer's wallet
                 * @param customer_id: customer's ID
                 * @param amount: amount to be added
                 * 
                 * @data customer_id: customer_id
                 *      amount: customerDetails.wallet_amount + amount
                 */

                this.setLoadingStatus(true)

                // const customerDetails = await this.getCustomerDetails(customer_id)
                // console.log("Customer details (updateWallet): ", customerDetails);

                const data = {
                    customer_id: customer_id,
                    amount: amount
                }
                console.log("Updating Wallet (data): ", data);

                await axios
                    .post(`customerwithdraw/wallet/`, data)
                    .then((response) => {
                        console.log("Successfully updated wallet (withdrawals)");
                    })
                    .catch((error) => {
                        console.log("Error updating wallet (withdrawals): ", error)
                        this.$toast.error(
                            "Something went wrong. Please try again!",
                            {
                                duration: 3000,
                                position: 'bottom-right'
                            }
                        )
                    })

                this.setLoadingStatus(false)
            },

            async declineRequest(request_id) {
                /**
                 * Fulfill a withdrawal request
                 * @param request_id: Id of the request
                 * TODO: Details to update:
                 *  * disbursment_status: "REQUEST DENIED"
                 */

                this.setLoadingStatus(true)

                const data = {
                    disbursment_status: 'REQUEST DENIED'
                }

                await axios
                    .put(`/withdraw/${request_id}/`, data)
                    .then((response) => {

                        this.$toast.success(
                            "Request successfully declined!",
                            {
                                duration: 5000,
                                position: 'bottom-right'
                            }
                        )

                        this.$store.state.withdrawalsLastFetched = null
                        this.$store.dispatch('fetchWithdrawals')
                    })
                    .catch((error) => {
                        console.log("Error approving request: ", error)

                        this.$toast.error(
                            "Error approving request!",
                            {
                                duration: 5000,
                                position: 'bottom-right'
                            }
                        )
                    })

                this.setLoadingStatus(false)
            },
        },
    }
</script>