<template>
    <UserNavbar />

    <router-view />
</template>

<script>
    import UserNavbar from '@/users/components/layout/UserNavbar.vue'

    export default {
        name: "AgentsLayout",
        components: {
            UserNavbar,
        },
    }
</script>