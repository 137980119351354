<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full md:h-screen">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <div class="bg-white shadow-md rounded-md p-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Incoming</h2>
                        <p class="text-gray-600">You have <span class="font-semibold">{{ incomingOrders.length }}</span> pending order<span v-if="incomingOrders.length > 1 || incomingOrders.length == 0">s</span></p>
                        <p class="text-gray-600">From driver: <span class="font-semibold">{{ receivingFromDriver.length }}</span></p>
                        <p class="text-gray-600">From customer (Dropoff): <span class="font-semibold">{{ receivingFromCustomer.length }}</span></p>
                    </div>

                    <div class="relative overflow-x-auto my-12">
                        <table v-show="incomingOrders.length" ref="incomingOrders" class="w-full text-sm text-left text-gray-500">
                            <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                                <tr>
                                    <th scope="col" class="px-6 py-3">#</th>
                                    <th scope="col" class="px-6 py-3">Parcel No.</th>
                                    <th scope="col" class="px-6 py-3">Description</th>
                                    <th scope="col" class="px-6 py-3">From (Driver/Customer)</th>
                                    <th scope="col" class="px-6 py-3">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(order, index) in incomingOrders" :key="order.id" class="bg-white divide-y divide-gray-100 hover:bg-gray-100">

                                    <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">{{ index + +1 }}</th>
                                    <td class="px-6 py-3 hover:text-blue-500">
                                        <router-link :to="'/users/agent/incoming/' + order.id">{{ order.invoice_id }}</router-link>
                                    </td>
                                    <td class="px-6 py-3">{{ order.parcel_description }}</td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.dropoff_agent">Customer</span>
                                        <span v-else>Driver</span>
                                    </td>
                                    <td class="px-6 py-3">
                                        <span v-if="order.agent_receiving_status == 'PENDING'">PENDING</span>
                                        <span v-else-if="order.dropoff_agent == agentDetails.agent_id && order.status == 'PACKAGE CREATED'">PENDING DROPOFF</span>
                                        <span v-else-if="order.agent == agentDetails.agent_id && order.agent_receiving_status == 'NULL'">IN TRANSIT</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

    export default {
        name: "Incoming",
        computed: {
            ...mapGetters(['getParcelsByAgentId', 'getAgentDetails']),

            agentDetails() {
                return this.getAgentDetails
            },

            agentParcels() {
                return this.getParcelsByAgentId(this.agentDetails.agent_id);
            },

            incomingOrders() {
                // console.log("Incoming orders: ", this.agentParcels.filter(parcel => 
                //     parcel.status === 'PACKAGE IN TRANSIT' && parcel.agent === this.agentDetails.agent_id || 
                //     parcel.dropoff_agent === this.agentDetails.agent_id && parcel.status === 'PACKAGE CREATED')); 

                return this.agentParcels.filter(parcel => 
                    parcel.status === 'PACKAGE IN TRANSIT TO AGENT' && parcel.agent === this.agentDetails.agent_id || 
                    parcel.dropoff_agent === this.agentDetails.agent_id && parcel.status === 'PACKAGE CREATED')
            },

            receivingFromCustomer() {
                return this.incomingOrders.filter(order => order.dropoff_agent === this.agentDetails.agent_id)
            },

            receivingFromDriver() {
                return this.incomingOrders.filter(order => order.status === 'PACKAGE IN TRANSIT')
            }
        },
        async created () {
            await this.$store.dispatch('fetchParcels');
        },
    }
</script>