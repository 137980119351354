<template>
    <div class="container mx-auto">
        <!-- header (Back button & Order ID) -->
        <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
            <div>
                <button @click="$router.back()"
                    class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                            fill="currentColor" />
                    </svg>

                    <span>Back</span>
                </button>

            </div>

            <div>
                <div class="flex justify-end">
                    <span v-if="!riderDetails.active" class="font-semibold rounded-full bg-red-500 px-12 py-1 text-white text-center">INACTIVE</span>
                    <span v-if="riderDetails.active" class="font-semibold rounded-full bg-green-500 px-12 py-1 text-white text-center">ACTIVE</span>
                </div>
                <div class="text-gray-500 font-semibold tracking-normal text-right text-base">rider ID: 
                    <span class="text-gray-600 font-bold">{{ riderDetails.driver_code }}</span>
                </div>
            </div>

        </div>

        <!-- rider details -->

        <!-- Loading indicator -->
        <div v-if="$store.state.isLoading">
            <div>
                <div>
                    <div class="flex items-center justify-center px-6 py-3">
                        <div class="flex justify-center items-center">
                            <div class="mr-2">Loading...</div>
                            <LoadingIcon />

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="rider-details" v-else-if="riderDetails">
            <!-- rider details -->
            <div>
                <h2 class="font-bold text-2xl leading-7 mx-24 mb-5">Rider Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Name -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Name</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ riderDetails.first_name }} {{ riderDetails.last_name }}</dd>
                        </div>

                        <!-- Email -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Email</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ riderDetails.email }}
                            </dd>
                        </div>

                        <!-- Phone Number -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ riderDetails.phone_number
                                }}</dd>
                        </div>

                        <!-- Zone -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Zone</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ riderDetails.zone }}</dd>
                        </div>

                        <!-- Registered Date -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Registered Date</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                riderDetails.date_joined.split(' ')[0] }}</dd>
                        </div>

                    </dl>
                </div>

            </div>

            <!-- Order details (stats) -->
            <div>
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Order Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- orders assigned -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Pending collection</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                pendingCollection }}</dd>
                        </div>

                        <!-- orders in transit -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">In Transit</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ inTransit }}</dd>
                        </div>

                        <!-- orders Delivered -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Delivered</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ completed }}</dd>
                        </div>

                        <!-- Total orders -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Total orders</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ driverParcels.length }}</dd>
                        </div>

                    </dl>
                </div>

            </div>

            <!-- Deactivate button -->
            <div class="flex justify-center mt-12">
                <button v-if="riderDetails.active" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full" @click="changeActiveStatus">
                    Deactivate Agent
                </button>
                <button v-else class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full" @click="changeActiveStatus">
                    Activate Agent
                </button>
            </div>

        </div>

        <div v-else>
            <p class="items-center text-center font-semibold text-lg text-red-500">Error fetching rider details. Please
                reload page again!</p>
        </div>

        <div class="flex justify-end items-center mt-8 -mb-8">
            <div class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                type="button" @click="downloadPDF">Download PDF</div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import html2pdf from 'html2pdf.js'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'RiderPage',
        data() {
            return {
                id: this.$route.params.id,

            }
        },

        components: {
            LoadingIcon,
        },

        computed: {
            ...mapGetters(['getDriverById', 'getParcelsByDriverId']),

            riderDetails() {
                return this.getDriverById(this.id)
            },

            driverParcels() {
                return this.getParcelsByDriverId(this.id)
            },

            pendingCollection() {
                return this.driverParcels.filter(parcel => parcel.status === 'PACKAGE IN OFFICE' || parcel.status === 'DELIVERED TO DROPOFF AGENT').length
            },

            inTransit() {
                return this.driverParcels.filter(parcel => parcel.status === 'PACKAGE IN TRANSIT TO OFFICE' || parcel.status === 'PACKAGE IN TRANSIT TO AGENT').length;
            },

            completed() {
                return this.driverParcels.filter(parcel => (parcel.date_delivered_to_agent && parcel.driver === this.riderDetails.driver_id) || (parcel.status == 'RETURN PACKAGE' && parcel.returning_driver === this.riderDetails.driver_id && parcel.package_return) || (parcel.date_received_at_office && parcel.dropoff_driver === this.riderDetails.driver_id)).length;

            }
            
        },

        methods: {
            ...mapMutations(['setLoadingStatus']),

            downloadPDF() {
                const element = document.getElementById('rider-details');
                const opt = {
                    margin: 1,
                    filename: `Driver-${this.riderID}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                };

                html2pdf().set(opt).from(element).save();
            },
        
            // change active status
            async changeActiveStatus() {
                // update active status
                if (confirm("Are you sure you want to change status?")) {
                    let activeStatus = !this.riderDetails.active

                    await axios
                        .put(`/drivers/${this.id}/`, {
                            active: activeStatus
                        })
                        .then((response) => {

                            this.$store.state.driversLastFetched = null
                            this.$store.dispatch('fetchDrivers')

                            this.$toast.success(
                                "Rider status updated successfully!",
                                {
                                    position: "bottom-right",
                                    duration: 3000,
                                }
                            )

                            this.$router.push('/admin/riders/')

                        })
                        .catch((error) => {
                            this.$toast.error(
                                "Error updating rider status. Please try again!",
                                {
                                    position: "bottom-right",
                                    duration: 3000,
                                }
                            )
                        })

                }
                
                
            },
        }
    }
</script>