<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <!-- Header -->
            <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
                <div>
                    <button @click="$router.back()"
                        class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                                fill="currentColor" />
                        </svg>

                        <span>Back</span>
                    </button>

                </div>

                <div>
                    <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Order ID:
                        <span class="text-gray-600 font-bold tracking-normal text-base">{{ orderDetails.invoice_id
                            }}</span>
                    </div>
                </div>

            </div>

            <!-- all details -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <!-- Order details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Order Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Parcel No.</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.invoice_id }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Description</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.parcel_description }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Quantity</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.parcel_quantity }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date created</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.created.split('T')[0] }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Customer details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Customer Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Name</div>
                                <div class="text-gray-800 font-semibold">{{ customerDetails.first_name }} {{ customerDetails.last_name }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Phone Number</div>
                                <div class="text-gray-800 font-semibold">0{{ customerDetails.phone_number }}</div>
                            </div>
                        </div>
                    </div>

                    <!-- Collection details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Collection Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Building</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.pickup_building }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Shop Number</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.pickup_shop_no }}</div>
                            </div>
                            <div v-if="orderDetails.date_collected" class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Collected</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.date_collected.split('T')[0] }}</div>
                            </div>
                            <div v-if="orderDetails.date_received_at_office" class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Dropped Off At Office</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.date_received_at_office.split('T')[0] }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <button @click="collectPackage"
                                    v-show="orderDetails.status == 'PACKAGE CREATED'"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Collect Package</span>
                                </button>
                                <button @click="dropoffAtOffice"
                                    v-show="orderDetails.status == 'COLLECTED BY COLLECTOR'"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Dropoff At Office</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue'
import { MESSAGE_TEMPLATES } from '@/services/messageTemplates';
import { sendMessage } from '@/services/messagingService';

export default {
    name: "CollectorOrder",
    data() {
        return {
            id: this.$route.params.id,
        }
    },
    components: {
        LoadingIcon,
    },
    computed: {
        ...mapGetters(['getParcelById', 'getCustomerById', 'getCollectorDetails']),

        collectorDetails() {
            return this.getCollectorDetails
        },

        orderDetails() {
            return this.getParcelById(this.id);
        },

        customerDetails() {
            return this.getCustomerById(this.orderDetails.customer);
        }
    },
    created () {
        this.$store.dispatch('fetchCustomers');
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        async collectPackage() {
            /**
             * When the collector collects the package from customer
             * TODO: Details to change:
             *      * date_collected
             *      * status: 'COLLECTED BY COLLECTOR'
             */

            this.setLoadingStatus(true)

            const data = {
                date_collected: new Date(),
                status: 'COLLECTED BY COLLECTOR'
            }

            await axios
                .put(`/packages/${this.id}/`, data)
                .then((response) => {
                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} collected successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/collector/pending')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async dropoffAtOffice() {
            /**
             * When the collector drops off the package at the admin
             * TODO: Details to change:
             *      * date_received_at_office
             *      * status: 'PACKAGE IN OFFICE'
             *      * updateCollectorTally()
             *      * sendReceivedMessage()
             */

            this.setLoadingStatus(true)

            const data = {
                date_received_at_office: new Date(),
                status: 'PACKAGE IN OFFICE'
            }

            await axios
                .put(`/packages/${this.id}/`, data)
                .then(async (response) => {

                    await this.updateCollectorTally()
                    await this.sendReceivedMessage()

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} dropped off successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/collector/collected')
                })
                .catch((error) => {
                    console.error("Error when dropping off at office (dropOffAtOffice): ", error);
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async updateCollectorTally() {
            /**
             * Update the collector's tally
             * TODO: Details to update:
             *      * parcels_collected: + 1
             */

            this.setLoadingStatus(true)


            const data = {
                parcels_collected: this.collectorDetails.parcels_collected + 1
            }

            await axios
                .put(`/collectors/${this.collectorDetails.collector_id}/`, data)
                .then((response) => {

                    this.$store.state.collectorsLastFetched = null
                    this.$store.dispatch('fetchCollectors')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async sendReceivedMessage() {
            /**
             * Send a message to the customer that their package has been received
             * * TEMPLATE: PACKAGE_RECEIVED
             * @data receiver: orderDetails.received,
             *      invoice_id: orderDetails.invoice_id,
             *      parcel_description: orderDetails.parcel_description
             * 
             * @data phoneNumber: orderDetails.receiver_phone_number
             */

            this.setLoadingStatus(true)

            const messageTemplate = MESSAGE_TEMPLATES.PACKAGE_RECEIVED

            const messageData = {
                receiver: this.orderDetails.receiver,
                invoice_id: this.orderDetails.invoice_id,
                parcel_description: this.orderDetails.parcel_description
            }

            const phoneNumber = this.orderDetails.receiver_phone_number

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
            } catch (error) {
                console.error("Something went wrong while sending message: ", error);
            }

            this.setLoadingStatus(false)
        }
    },
}
</script>