<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="grid gap-y-4 bg-gray-100 shadow-md rounded-md mx-auto container p-4 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Orders</h1>
                <div class="grid grid-cols-1 gap-x-6 gap-y-4 lg:grid-cols-6">
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Unpaid: <span class="font-semibold">{{ unpaidOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Paid: <span class="font-semibold">{{ paidOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Cancelled: <span class="font-semibold">{{ cancelledOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Returned: <span class="font-semibold">{{ returnedOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Completed: <span class="font-semibold">{{ completedOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Total: <span class="font-semibold">{{ orders.length }}</span></p>
                </div>

            </div>


            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-6 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-6">Filters</h1>

                <!-- ID -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">ID</label>
                    <input type="text" id="idFilter" v-model="idFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Parcel Number" />
                </div>

                <!-- Destination -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Destination</label>
                    <select id="paymentFilter" v-model="destinationFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Service">
                        <option value="">All</option>
                        <option v-for="destination in destinations" :key="destination" :value="destination">{{ destination }}</option>
                    </select>
                </div>


                <!-- Service -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Service</label>
                    <select id="paymentFilter" v-model="serviceFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Service">
                        <option value="">All</option>
                        <option v-for="(service, index) in services" :key="index" :value="index">{{ service }}</option>
                    </select>
                </div>

                <!-- Payment Status -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Payment Status</label>
                    <select id="paymentFilter" v-model="paymentFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Payment Status">
                        <option value="">All</option>
                        <option value="PAID">Paid</option>
                        <option value="UNPAID">Unpaid</option>
                    </select>
                </div>

                <!-- Delivery time -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Delivery time</label>
                    <select id="paymentFilter" v-model="deliveryTimeFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Delivery time">
                        <option value="">All</option>
                        <option v-for="(deliveryTime, index) in deliveryTimes" :key="index" :value="index">{{ deliveryTime }}</option>
                    </select>
                </div>

                <!-- Package Status -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Package Status</label>
                    <select id="packageStatusFilter" v-model="packageStatusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Package Status">
                        <option value="">All</option>
                        <option v-for="(status, index) in packageStatuses" :key="index" :value="index">{{ status }}</option>
                    </select>
                </div>
                
                <!-- Overstayed -->
                <div class="col-span-1 lg:col-span-1 items-center flex justify-center gap-4">
                    <input type="checkbox" name="overstayedFilter" v-model="overstayedFilter" id="overstayed">
                    <label for="route" class="mb-2 text-sm font-medium text-gray-900 text-center ">Overstayed</label>
                </div>
    

            </div>

            <!-- Download report buttont button -->
            <div class="flex justify-between items-center mt-8 -mb-8">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="parcelNumber">Parcel Number</option>
                        <option value="destination">Destination Type</option>
                        <option value="service">Service</option>
                        <option value="collector">Collector</option>
                        <option value="driver">Driver</option>
                        <option value="paymentStatus">Payment Status</option>
                        <option value="packageStatus">Parcel Status</option>
                    </select>
                </div>
                
                <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading">Download Report</button>

            </div>



            <!-- Order table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="ordersTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 text-center ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'parcelNumber' }">
                                <span>Parcel Number</span>
                                <button v-if="sortBy == 'parcelNumber'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'destination' }">
                                <span>Destination</span>
                                <button v-if="sortBy == 'destination'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'service' }">
                                <span>Service</span>
                                <button v-if="sortBy == 'service'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'collector' }">
                                <span>Collector</span>
                                <button v-if="sortBy == 'collector'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'driver' }">
                                <span>Driver</span>
                                <button v-if="sortBy == 'driver'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'paymentStatus' }">
                                <span>Payment Status</span>
                                <button v-if="sortBy == 'paymentStatus'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'packageStatus' }">
                                <span>Package Status</span>
                                <button v-if="sortBy == 'packageStatus'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>

                    <!-- Show orders details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredOrders.length" v-for="(order, index) in filteredOrders" :key="order.id"
                            class="border-b" :class="[ order.days_stayed_at_agent > 3 ? 'bg-red-300 hover:bg-red-400' : 'bg-white hover:bg-gray-50']">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3 hover:text-blue-500">
                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                            </td>
                            <td class="px-6 py-3">
                                {{ order.destination }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ order.route_type }}
                            </td>
                            <td class="px-6 py-3 truncate" :class="{ 'text-center' : !order.collector }">
                                <span v-if="order.collector">{{ order.collector }}</span>
                                <span v-else-if="order.status == 'PACKAGE CANCELLED' || order.payment_status == 'UNPAID' || order.dropoff_destination"> N/A </span>
                                <span v-else> - </span>
                            </td>
                            <td class="px-6 py-3 truncate" :class="{ 'text-center' : !order.driver }">
                                <span v-if="order.driver">{{ order.driver }}</span>
                                <span v-else-if="order.status == 'PACKAGE CANCELLED' || order.payment_status == 'UNPAID'"> N/A </span>
                                <span v-else> - </span>
                            </td>
                            <td class="px-6 py-3">
                                <span v-if="order.payment_status === 'UNPAID'"
                                    class="rounded-full bg-red-500 px-6 py-1 text-white font-semibold">{{
                                    order.payment_status }}</span>
                                <span v-if="order.payment_status === 'PAID'"
                                    class="rounded-full bg-green-500 text-white font-semibold px-8 py-1">{{
                                    order.payment_status }}</span>
                            </td>
                            <td class="px-3 py-3 text-center">
                                <span v-if="order.status === 'PACKAGE CANCELLED'" class="px-6 py-1 text-red-700 font-semibold" >CANCELLED</span>

                                <span v-else-if="order.payment_status === 'UNPAID'" class="rounded-full bg-red-500 px-3 py-1 text-white font-semibold truncate" >Pending Payment</span>

                                <span v-else-if="order.days_stayed_at_agent > 3" class="rounded-full bg-red-500 px-3 py-1 text-white font-semibold truncate" >OVERSTAYED AT AGENT</span>

                                <span v-else>
                                    <span v-if="order.status === 'PACKAGE CREATED'" class="rounded-full bg-gray-400 py-1 text-white font-semibold" >
                                        <span v-if="order.collector" class="px-2 truncate" >Pending Collection</span>
                                        <span v-else-if="order.dropoff_destination" class="px-4 truncate" >Pending dropoff</span>
                                        <span v-else class="px-12" >Ready</span>
                                    </span>

                                    <span v-else-if="order.status === 'COLLECTED BY COLLECTOR'" class="rounded-full bg-gray-600 px-10 py-1 text-white font-semibold truncate" >Collected</span>
                                    
                                    <span v-else-if="order.status === 'DELIVERED TO DROPOFF AGENT'" class="rounded-full bg-gray-600 px-7 py-1 text-white font-semibold truncate" >Dropped Off</span>

                                    <span v-else-if="order.status === 'PACKAGE IN OFFICE'" class="rounded-full bg-gray-800 py-1 text-white font-semibold truncate" >
                                        <span v-if="order.driver" class="px-3 truncate" >Pending Delivery</span>
                                        <span v-else class="px-7 truncate" >In Office</span>
                                    </span>

                                    <span v-else-if="order.status === 'PACKAGE IN TRANSIT TO OFFICE'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >Incoming</span>

                                    <span v-else-if="order.status === 'PACKAGE IN TRANSIT TO AGENT'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >In Transit</span>

                                    <span v-else-if="order.status === 'DELIVERED TO AGENT'" class="rounded-full bg-green-500 px-3 py-1 text-white font-semibold truncate" >Waiting Recepient</span>

                                    <span v-else-if="order.status === 'RECEIVED BY RECIPIENT'" class="px-6 py-1 text-gray-400 font-semibold" >FULFILLED</span>
                                    
                                    <span v-else-if="order.status === 'RETURN PACKAGE'" class="px-6 py-1 text-red-400 font-semibold" >RETURNED</span>

                                </span>

                            </td>
                        </tr>

                        <!-- No orders -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No orders
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />

                                </div>
                            </div>
                        </div>
                    </div>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapMutations } from 'vuex';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable'

    // import logo
    import logo from '../../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';


    export default {
        name: 'Orders',
        data() {
            return {
                // filter fields
                idFilter: '',
                destinationFilter: '',
                paymentFilter: '',
                packageStatusFilter: '',
                serviceFilter: '',
                deliveryTimeFilter: '',
                overstayedFilter: '',

                // sorting
                sortBy: '',
                sortAscending: false

            }
        },
        components: {
            LoadingIcon,
        },
        async created() {
            await this.$store.dispatch('fetchAllParcels')
        },
        computed: {
            ...mapGetters(['getServices', 'getPackageStatuses', 'getAllParcels', 'getDeliveryTimes']),

            services() {
                return this.getServices
            },

            deliveryTimes() {
                return this.getDeliveryTimes
            },

            orders() {
                return this.getAllParcels
            },

            filteredOrders() {
                let ordersData = this.orders

                ordersData = ordersData.filter(order => {
                    const idMatch = this.idFilter ? order.invoice_id.toLowerCase().includes(this.idFilter.toLowerCase()) : true;
                    const destinationMatch = this.destinationFilter.trim() === '' || order.destination.toLowerCase().includes(this.destinationFilter.toLowerCase());
                    const paymentMatch = this.paymentFilter.trim() === '' || order.payment_status == this.paymentFilter;
                    const statusMatch = this.packageStatusFilter.trim() === '' || order.status == this.packageStatusFilter;
                    const serviceMatch = this.serviceFilter.trim() === '' || order.route_type == this.serviceFilter;
                    const deliveryTimeMatch = this.deliveryTimeFilter.trim() === '' || order.parcel_delivery_time == this.deliveryTimeFilter;
                    const overstayedMatch = this.overstayedFilter ? order.days_stayed_at_agent > 3 : true;

                    return paymentMatch && idMatch && destinationMatch && statusMatch && serviceMatch && deliveryTimeMatch && overstayedMatch;
                });

                // Sort orders
                if (this.sortBy == 'parcelNumber') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.invoice_id.localeCompare(b.invoice_id) : b.invoice_id.localeCompare(a.invoice_id);
                    });
                } else if (this.sortBy == 'destination') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.destination.localeCompare(b.destination) : b.destination.localeCompare(a.destination);
                    });
                } else if (this.sortBy == 'service') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.route_type.localeCompare(b.route_type) : b.route_type.localeCompare(a.route_type);
                    });
                } else if (this.sortBy == 'collector') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.collector.localeCompare(b.collector) : b.collector.localeCompare(a.collector);
                    });
                } else if (this.sortBy == 'driver') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.driver.localeCompare(b.driver) : b.driver.localeCompare(a.driver);
                    });
                } else if (this.sortBy == 'paymentStatus') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.payment_status.localeCompare(b.payment_status) : b.payment_status.localeCompare(a.payment_status);
                    });
                } else if (this.sortBy == 'packageStatus') {
                    ordersData = ordersData.sort((a, b) => {
                        return this.sortAscending ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
                    });
                }

                return ordersData
            },

            unpaidOrders() {
                return this.orders.filter(order => order.payment_status === 'UNPAID').length
            },

            paidOrders() {
                return this.orders.filter(order => order.payment_status === 'PAID').length
            },

            cancelledOrders() {
                return this.orders.filter(order => order.status === 'PACKAGE CANCELLED').length
            },

            returnedOrders() {
                return this.orders.filter(order => order.status === 'RETURN PACKAGE').length
            },

            completedOrders() {
                return this.orders.filter(order => order.status === 'RECEIVED BY RECIPIENT').length
            },

            destinations() {
                let destinations = []

                this.orders.forEach(order => {
                    if (!destinations.includes(order.destination.toUpperCase())) {
                        destinations.push(order.destination.toUpperCase())
                    }
                })

                return destinations
            },

            packageStatuses() {
                return this.getPackageStatuses
            }
        },
        async created () {
            await this.$store.dispatch('fetchAllParcels');
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true);

                const today = new Date().toLocaleDateString();

                const doc = new jsPDF({
                    orientation: "landscape"
                });

                const table = this.$refs.ordersTable;

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'All Orders Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);

                // logo
                const logoWidth = 40;
                const logoHeight = 40;
                const logoX = 20;
                const logoY = titleY + 10;

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

                // date and other information
                const reportInfo = `Date: ${today}\n\nTotal orders: ${this.orders.length}`;
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10;

                doc.autoTable({
                    html: table,
                    startY: tableStartY
                });

                doc.save(`all_orders-${today}.pdf`);

                this.setLoadingStatus(false);
            }
        },
    }
    
</script>