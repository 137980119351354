<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="bg-gray-100 mx-auto container py-6 px-8 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Agents</h1>
            </div>


            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-6 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-6">Filters</h1>

                <!-- <div class="flex justify-between items-center gap-x-8"> -->
                    <!-- Zone -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="zone" class="block mb-2 text-sm font-medium text-gray-900 ">Zone:</label>
                        <select name="zone" id="zone"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="zoneFilter"
                            required>
                            <option value="">All</option>
                            <option v-for="(zone, index) in zones" :value="index" :key="index">{{ zone.split('-')[1] }}</option>
                        </select>
                    </div>
    
                    <!-- ID -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">ID:</label>
                        <input type="text" id="route" v-model="idFilter"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="ID" />
                    </div>
    
                    <!-- Location -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Location:</label>
                        <select name="zone" id="zone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="locationFilter"
                            required>
                            <option value="">All</option>
                            <option v-for="location in locations" :value="location" :key="location">{{ location }}</option>
                        </select>
                    </div>
    
                    <!-- Business Name -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Name:</label>
                        <input type="text" id="route" v-model="nameFilter"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Business Name" />
                    </div>
    
                    <!-- Phone -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Number:</label>
                        <input type="text" id="route" v-model="phoneFilter"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Phone Number" />
                    </div>
    
                    <!-- Active/Inactive -->
                    <div class="col-span-1 lg:col-span-1 items-center">
                        <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Status:</label>
                        <select id="paymentFilter" v-model="activeFilter"
                            class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Payment Status">
                            <option value="">All</option>
                            <option value="True">Active</option>
                            <option value="False">Inactive</option>
                        </select>
                    </div>
    
                <!-- </div> -->

            </div>

            <!-- Sort by | New Agent button | Download Report button -->
            <div class="flex justify-between items-center mt-8 -mb-8">
                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="id">ID</option>
                        <!-- <option value="zone">Zone</option> -->
                        <option value="location">Location</option>
                        <option value="businessName">Business Name</option>
                        <!-- <option value="ownerName">Owner's Name</option> -->
                        <option value="registrationDate">Registration Date</option>
                    </select>
                </div>

                <div class="flex gap-x-4" >
                    <router-link to="/admin/agents/new"
                        class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                        type="button">New Agent</router-link>
    
                    <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" >Download Report</button>
                    
                </div>

            </div>

            <!-- Agents table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="agentsTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'id' }">
                                <span>ID</span>
                                <button v-if="sortBy == 'id'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'zone' }">
                                <span>Zone</span>
                                <button v-if="sortBy == 'zone'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'location' }">
                                <span>Location</span>
                                <button v-if="sortBy == 'location'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'businessName' }">
                                <span>Business Name</span>
                                <button v-if="sortBy == 'businessName'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'ownerName' }">
                                <span>Owner's Name</span>
                                <button v-if="sortBy == 'ownerName'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Phone
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'registrationDate' }">
                                <span>Registration Date</span>
                                <button v-if="sortBy == 'registrationDate'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                            </th>
                        </tr>
                    </thead>

                    <!-- Show Agent details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredAgents.length" v-for="(agent, index) in filteredAgents" :key="agent.id" class="bg-white border-b">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3 hover:text-blue-500">
                                <router-link :to="'/admin/agents/' + agent.agent_id">{{ agent.agent_code }}</router-link>
                            </td>
                            <td class="px-6 py-3">
                                {{ agent.zone }}
                            </td>
                            <td class="px-6 py-3">
                                {{ agent.location_name }}
                            </td>
                            <td class="px-6 py-3">
                                {{ agent.collection_shop_name }}
                            </td>
                            <td class="px-6 py-3">
                                {{ agent.first_name }} {{ agent.last_name }}
                            </td>
                            <td class="px-6 py-3">
                                {{ agent.phone_number }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ agent.date_joined.split(' ')[0] }}
                            </td>
                            <td class="px-6 py-3 truncate" >
                                <div v-if="agent.active">
                                    <span class="hidden">Active</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4.5" stroke="#0bd659" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                    </svg>
                                </div>
                                <div v-else>
                                    <span class="hidden">Inactive</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4.5" stroke="#ed0e0e" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </td>
                        </tr>

                        <!-- No agents -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No Agents
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />

                                </div>
                            </div>
                        </div>
                    </div>


                </table>
            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import { mapGetters, mapMutations } from 'vuex';

    // import logo
    import logo from '../../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'Agents',
        data() {
            return {
                // filters
                locationFilter:'',
                idFilter: '',
                phoneFilter: '',    
                nameFilter: '',
                zoneFilter: '',
                activeFilter: '',

                // sorting
                sortBy: '',
                sortAscending: false
            }
        },
        components: {
            LoadingIcon,
        },
        async created() {
            await this.$store.dispatch('fetchAgents')
        },
        computed: {
            ...mapGetters(['getAgents', 'getZones']),

            zones() {
                return this.getZones
            },

            agents() {
                return this.getAgents
            },

            locations() {
                let locations = []

                this.agents.forEach(agent => {
                    if (!locations.includes(agent.location_name.toUpperCase())) {
                        locations.push(agent.location_name.toUpperCase())
                    }
                })

                return locations
            },
            
            filteredAgents() {
                let agentsData = this.agents

                agentsData = agentsData.filter(agent => {
                    const idMatch = this.idFilter ? agent.agent_code.toString().includes(this.idFilter.toString()) : true;
                    const locationMatch = this.locationFilter.trim() === '' || agent.location_name.toLowerCase().includes(this.locationFilter.toLowerCase());
                    const phoneMatch = this.phoneFilter ? agent.phone_number.toString().includes(this.phoneFilter.toString()) : true;
                    const nameMatch = this.nameFilter.trim() === '' || agent.collection_shop_name.toLowerCase().includes(this.nameFilter.toLowerCase());
                    const zoneMatch = this.zoneFilter === '' || agent.zone == this.zoneFilter;
                    const activeMatch = this.activeFilter.trim() === '' || agent.is_active.toString() == this.activeFilter;

                    return locationMatch && idMatch && phoneMatch && nameMatch && zoneMatch && activeMatch;
                });

                if (this.sortBy == 'location') {
                    agentsData = agentsData.sort((a, b) => {
                        return this.sortAscending ? a.location_name.localeCompare(b.location_name) : b.location_name.localeCompare(a.location_name)
                    })
                } else if (this.sortBy == 'businessName') {
                    agentsData = agentsData.sort((a, b) => {
                        return this.sortAscending ? a.collection_shop_name.localeCompare(b.collection_shop_name) : b.collection_shop_name.localeCompare(a.collection_shop_name)
                    })
                } else if (this.sortBy == 'ownerName') {
                    agentsData = agentsData.sort((a, b) => {
                        return this.sortAscending ? a.owner_name.localeCompare(b.owner_name) : b.owner_name.localeCompare(a.owner_name)
                    })
                } else if (this.sortBy == 'registrationDate') {
                    agentsData = agentsData.sort((a, b) => {
                        return this.sortAscending ? a.date_joined.localeCompare(b.date_joined) : b.date_joined.localeCompare(a.date_joined)
                    })
                } else if (this.sortBy == 'id') {
                    agentsData = agentsData.sort((a, b) => {
                        return this.sortAscending ? a.agent_code.localeCompare(b.agent_code) : b.agent_code.localeCompare(a.agent_code)
                    })
                }

                return agentsData
            }
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true)
                
                const today = new Date().toLocaleDateString()

                const doc = new jsPDF({
                    orientation: "landscape"
                })

                const table = this.$refs.agentsTable

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'All Agents Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);
                
                // logo
                const logoWidth = 40
                const logoHeight = 40
                const logoX = 20
                const logoY = titleY + 10

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)

                // date and other information
                const reportInfo = `Date: ${today}\n\nTotal Agents: ${this.agents.length}`
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10

                doc.autoTable({ 
                    html: table,
                    startY: tableStartY
                 })

                doc.save(`all_agents-${today}.pdf`)

                this.setLoadingStatus(false)
            }

        }
    }
</script>