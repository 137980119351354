<template>
    <div class="items-center bg-gray-200 pb-10">

        <div class="items-center py-12">
            <h1 class="text-center text-2xl text-black font-bold"></h1>
        </div>

        <div class="border-b border-gray-200">

            <div>
                <form @submit.prevent="submitForm" class="mb-8 md:mx-24 w-auto items-center px-8 md:px-52">
                    <h2 class="font-bold text-2xl mb-5">Parcel Drop Off</h2>
                    
                    <!-- Dropoff destination (Area) -->
                    <div class="mb-7">
                        <label for="area" class="block mb-2 text-sm font-medium text-gray-900 ">Select Area</label>
                        <input type="text" id="area" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Area" v-model="dropoff_destination" required />
                    </div>

                    <!-- Drop Off Agent -->
                    <div class="mb-7">
                        <label for="agent" class="block mb-2 text-sm font-medium text-gray-900 ">Select Drop Off Agent</label>

                        <select name="agent" id="agent" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " v-model="dropoff_agent" :disabled="!dropoff_destination" required >
                            <option v-if="!filteredAgents.length" disabled value="">No Agents Available in this area</option>
                            <option disabled value="" class="text-gray-500">Select agent</option>
                            <option v-for="agent in filteredAgents" :key="agent" :value="agent">{{ agent.collection_shop_name }} - {{ agent.location_name }}</option>
                        </select>
                    </div>


                    <!-- Receiver Details -->
                    <h2 class="font-bold text-2xl mb-3">Receiver's Details</h2>

                    <!-- Receiver's Name -->
                    <div class="mb-7">
                        <label for="receiverName" class="block mb-2 text-sm font-medium text-gray-900 ">Receiver's Name</label>
                        <input type="text" id="receiverName" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Name" v-model="receiver" required />
                    </div>

                    <!-- Phone Number -->
                    <div class="mb-7">
                        <label for="receiverPhoneNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                        <input type="phone" id="receiverPhoneNumber" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Phone Number" v-model="receiver_phone_number" required />
                    </div>

                    <!-- email (Optional) -->
                    <div class="mb-7">
                        <label for="receiverEmail" class="block mb-2 text-sm font-medium text-gray-900 ">Email (Optional)</label>
                        <input type="email" id="receiverEmail" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Enter Email" v-model="receiver_email" />
                    </div>

                    <!-- Show errors if any -->
                    <div v-if="errors.length" class="mb-5 bg-red-200 p-6">
                        <h2 class="text-red-800 text-base font-medium">Error: </h2>
                        <p v-for="error in errors" :key="error" class="mt-2 text-red-600 text-sm font-medium">{{ error }}</p>
                    </div>

                    
                    <button type="submit" class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        <span v-if="!$store.state.isLoading">Proceed</span>
                        <span v-else ><LoadingIcon /></span>
                    </button>
                </form>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import axios from 'axios';
import LoadingIcon from './LoadingIcon.vue';

    export default {
        name: 'ParcelDropOff',
        data() {
            return {
                currentRoute: this.$route.name,
                
                dropoff_destination: '',
                dropoff_agent: '',
                receiver: '',
                receiver_phone_number: '',
                receiver_email: null,

                errors: [],

            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getAgents']),

            agents() {
                return this.getAgents
            },

            filteredAgents() {
                let agentsData = this.agents

                agentsData = agentsData.filter(agent => {
                    const locationMatch = this.dropoff_destination.trim() === '' || agent.location_name.toLowerCase().includes(this.dropoff_destination.toLowerCase());

                    return locationMatch
                })

                return agentsData
            }
        },
        created () {
            // this.getAgents();
        },
        methods: {
            ...mapMutations(['setNewParcelDetails', 'setLoadingStatus']),

            async submitForm() {
                this.setLoadingStatus(true)

                if (this.dropoff_agent === '') {
                    this.errors.push('Please select a drop off agent')
                }
                
                if (!this.errors.length) {
                    const parcelDropOffDetails = {
                        dropoff_destination: this.dropoff_agent.location_name,
                        dropoff_agent: this.dropoff_agent.agent_id,
                        receiver: this.receiver,
                        receiver_phone_number: this.receiver_phone_number,
                        receiver_email: this.receiver_email
                    }
    
                    
                    await this.setNewParcelDetails(parcelDropOffDetails)

                    this.$router.push(this.currentRoute + '/parcel-details')
                }

                setTimeout(() => {
                    this.errors = []
                }, 5000)
                
                this.setLoadingStatus(false)
            },

        }
    }
</script>