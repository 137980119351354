<template>
   <div class="text-justify bg-gray-200 px-9 md:px-24 py-8 md:py-20">

      <div class="font-bold text-lg md:text-4xl text-gray-900 text-center mb-5">
         Terms and Conditions of Timely Delivery Services
      </div>
      
      <ol class="list-decimal">
         <li class="font-bold text-2xl mb-5">Service Description:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Timely Delivery's parcel delivery service to designated agents for client pickup offers numerous advantages. Firstly, it provides unparalleled convenience by allowing clients to have their parcels delivered to conveniently located agents, eliminating the need to wait at home or rearrange schedules for delivery. Clients can select a pickup location that suits them best and collect their parcels at their own convenience, enhancing overall customer experience.
         Secondly, the service ensures flexibility and reliability. Clients have the flexibility to choose from multiple pickup locations and times, enabling them to fit parcel collection into their busy schedules seamlessly. Moreover, Timely Delivery's commitment to upfront payment prior to dispatch enhances security for both clients and the company, minimizing the risk of unpaid or undelivered parcels. This, coupled with reduced delivery costs achieved through streamlined processes, makes parcel delivery more affordable and accessible for clients. Overall, Timely Delivery's service offers a seamless and reliable solution for parcel delivery, ensuring convenience, flexibility, security, and affordability for its clients.</div>

         <li class="font-bold text-2xl mb-5">CBD Offices: Operational Hours and Locations:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Our town offices are located at Dynamic Mall, adjacent to National Archives, 4th Floor. Operational hours are Monday to Friday, 8:30 AM to 7:30 PM, and Saturday, 9:00 AM to 7:00 PM, closed on Sunday.</div>
        
         <li class="font-bold text-2xl mb-5">Excluded Items:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Timely Delivery does not handle the delivery of money, food, flowers, groceries, explosives, ammunition, flammable items, pets, ashes, human remains, certain fragile products, or bulky and heavy packages.</div>
         
         <li class="font-bold text-2xl mb-5">Packaging Guidelines:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Packages must be properly sealed and labelled with sender and receiver details. Cosmetics products made of glass packaging should be appropriately protected. Failure to comply with packaging and labelling guidelines may result in refusal of delivery.</div>
         
         <li class="font-bold text-2xl mb-5">Agent Delivery Policies:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Same-day delivery to agents within Nairobi is offered through three delivery schedules: 9:45 AM, 1:45 PM, and 4:30 PM. Delivery to out-of-county agents is the next day for packages dropped before specified times. Same-day delivery within Nairobi for packages dropped before 2:30 PM at Mtaani agents.</div>
         
         <li class="font-bold text-2xl mb-5">Process of Sending a Package from Mtaani:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Packages must be documented and labelled accurately using the TIMELYDELIVERY.NET (WEB) or APP, we shall generate a digital receipt for tracking. Notification via Instagram DM or WhatsApp is required for delivery. All payments are processed through the WEB/APP (TIMELYDELIVERY.NET).</div>
         
         <li class="font-bold text-2xl mb-5">Package Collection Period and Return Policy:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Packages sent to Pickup Points outside Nairobi CBD remain at the collection point for three days. Timely Delivery is not liable for any uncollected returns after the specified period.</div>
         
         <li class="font-bold text-2xl mb-5">Claim Policy:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Claims must be reported within 12 days of delivery. Timely Delivery shall not be held liable for claims made after this period. The maximum amount payable for any claim for loss or damage is Kshs. 2000.</div>
         
         <li class="font-bold text-2xl mb-5">Damaged/Missing Delivery Refund Procedure and Policy:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Damaged or tampered packages must be reported immediately to customer service, with necessary documentation provided for refunds. Timely Delivery refrains from delivering fragile products and shall not be held liable for damage claims.</div>
         
         <li class="font-bold text-2xl mb-5">Package Collection Requirements:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Recipients must present either the notification message from Timely Delivery or valid identification for package collection. Agent locations and operational hours are available on TIMELYDELIVERY.NET</div>
         
         <li class="font-bold text-2xl mb-5">Home/Office Delivery Policies:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Same-day doorstep delivery is available for packages dropped before specified times. Delivery payments are made to Paybill number 4130605. Specific delivery times are not guaranteed.</div>
         
         <li class="font-bold text-2xl mb-5">Modification of Terms:</li>
         <div class="leading-loose font-gray-700 mb-5 text-base font-normal" >Timely Delivery reserves the right to modify these terms without prior notice. Continued use of Timely Delivery's services constitutes acceptance of the modified terms.</div>

      </ol>
      
      <div class="leading-loose font-gray-700 mb-5 text-base font-normal">
         By proceeding, you acknowledge to have read, understood, and agreed to the terms and conditions outlined above. These terms constitute the exclusive agreement between you (service provider and/or vendor) and Timely Delivery, prevailing over any other agreement or communication related to this contract.
      </div>
   </div>


</template>

<script>
 export default {
    name: 'TermsAndConditions'
 }
</script>
