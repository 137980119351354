<template>
    <section class="bg-gray-50">
        
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

            
            <div class="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
                
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    
                    <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center">
                        <div class="flex items-center ps-2.5 mb-5">
                            <img class="" src="../../assets/long-logo.png" alt="logo">    
                        </div>
                    </h1>

                    <form class="space-y-4 md:space-y-6" @submit.prevent="adminLogin">

                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Admin Email</label>
                            <input type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" v-model="adminEmail" placeholder="name@company.com" required="">
                        </div>

                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password</label>
                            <input type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 " v-model="password" required="">
                        </div>

                        <div class="flex items-center justify-between">
                            <!-- <div class="flex items-start">
                                <div class="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" v-model="rememberMe">
                                </div>
                                <div class="ml-3 text-sm">
                                    <label for="remember" class="text-gray-500 ">Remember me</label>
                                </div>
                            </div> -->
                            <!-- <a href="#" class="text-sm font-medium text-blue-600 hover:underline ">Forgot password?</a> -->
                        </div>

                        <button type="submit" class="flex justify-center w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                            <span v-if="!$store.state.isLoading">Sign in</span>
                            <span v-else ><LoadingIcon /></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapMutations, mapState } from 'vuex';

    export default {
        name: 'AdminLogin',

        data() {
            return {
                adminEmail: '',
                password: '',
                rememberMe: false,
                id: ''
            }
        },
        components: {
            LoadingIcon,
        },

        methods: {
            ...mapMutations(['setAdmin', 'setLoadingStatus', 'setToken']),
            ...mapState(['admin']),

            async adminLogin() {
                this.setLoadingStatus(true)
                
                // this.setAdmin({})
                // axios.defaults.headers.common['Authorization'] = ''
                // localStorage.removeItem('token')

                const formData = {
                    email: this.adminEmail,
                    password: this.password
                }

                await axios
                    .post('/administrator/login/', formData)
                    .then(async (response) => {
                        const results = response.data

                        this.setToken(results.access)

                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + results.access

                        await this.$store.dispatch('fetchAdminDetails', results.user.admin_id)
                        this.$store.dispatch('fetchCustomers')
                        this.$store.dispatch('fetchAllParcels')
                        this.$store.dispatch('fetchAgents')
                        this.$store.dispatch('fetchDrivers')
                        this.$store.dispatch('fetchCollectors')
                        this.$store.dispatch('fetchWithdrawals')

                        this.$toast.success(
                            "Successfully logged in",
                            {
                                position: 'bottom-right',
                                duration: 3000,
                            }
                        )

                        this.$router.push('/admin/')

                    })
                    .catch((error) => {

                        if (error.response) {
                            for (const property in error.response.data) {
                                this.$toast.error(
                                    `${error.response.data[property]}`,
                                    {
                                        position: 'bottom-right',
                                        duration: 3000,
                                    }
                                )
                            }
                        } else if (error.message) {
                            this.$toast.error(
                                "Something went wrong. Please try again!",
                                {
                                    position: 'bottom-right',
                                    duration: 3000,
                                }
                            )
                        }
                    })

                this.setLoadingStatus(false)
            }
        }
    }
</script>