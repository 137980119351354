<template>
    <div class="my-8 md:mx-24 w-auto">
        <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-500 md:text-2xl">Activate Account</h1>
        <p class="mt-2 text-sm font-semibold leading-relaxed tracking-tight text-gray-900 md:text-xl" >Please enter the verification code sent to <span class="text-gray-500">{{ email }}</span> below to activate your account.</p>

        <!-- enter verification code -->
        <form @submit.prevent="verifyEmail" class="mt-8">
            <input type="text" id="verificationCode" name="verificationCode" v-model="verificationCode" class="w-full mt-2 p-2 border border-gray-300 rounded-md" placeholder="Enter verification code">

            <button type="submit" class="w-full mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-orange-500">
                <span v-if="!$store.state.isLoading">Verify Email</span>
                <span v-else><LoadingIcon /></span>
            </button>
        </form>
    </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapMutations } from 'vuex';

    export default {
        name: 'EmailVerification',
        components: {
            LoadingIcon,
        },
        data() {
            return {
                email: localStorage.getItem('email'),
                verificationCode: ''
            }
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            async verifyEmail() {
                /**
                 * Verify email
                 * @data email: customer's registration email (localStorage)
                 *      verificationCode: verification code sent to email
                 */

                this.setLoadingStatus(true);

                const data = {
                    email: this.email,
                    verification_code: this.verificationCode
                }

                await axios
                    .post(`customer/activate/`, data)
                    .then((response) => {
                        
                        this.$toast.success(
                            "Account activated successfully. Please login to continue.",
                            {
                                duration: 3000,
                                position: "bottom-right",
                            }
                        )

                        localStorage.removeItem('email')
                        this.$router.push('/login');
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast.error(
                            "Something went wrong. Please try again!",
                            {
                                duration: 3000,
                                position: "bottom-right",
                            }
                        )
                    });

                this.setLoadingStatus(false);
            }
        },
    }
</script>