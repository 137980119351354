<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="grid gap-y-4 bg-gray-100 shadow-md rounded-md mx-auto container p-4 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">COD Orders</h1>
                <div class="grid grid-cols-1 gap-x-2 gap-y-4 lg:grid-cols-5">
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Unpaid: <span class="font-semibold">{{ unpaidCODOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Unpaid Value: <span class="font-semibold">KSHS. {{ unpaidCODValue }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Paid: <span class="font-semibold">{{ paidCODOrders }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Paid Value: <span class="font-semibold">KSHS. {{ paidCODValue }}</span></p>
                    <p class="cols-span-1 lg:col-span-1 text-gray-600">Total: <span class="font-semibold">{{ orders.length }}</span></p>
                </div>

            </div>

            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-4">Filters</h1>

                <!-- Parcel number -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Parcel No:</label>
                    <input type="text" id="pkgNumberFilter" v-model="pkgNumberFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="ID" />
                </div>

                <!-- Service -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Service</label>
                    <select id="paymentFilter" v-model="serviceFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Service">
                        <option value="">All</option>
                        <option v-for="(service, index) in services" :key="index" :value="index">{{ service }}</option>
                    </select>
                </div>

                <!-- COD Status -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">COD Status</label>
                    <select id="codStatusFilter" v-model="codStatusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="COD Status">
                        <option value="">All</option>
                        <option value="true">Paid</option>
                        <option value="false">Unpaid</option>
                    </select>
                </div>

                <!-- Package Status -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Package Status</label>
                    <select id="packageStatusFilter" v-model="packageStatusFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Package Status">
                        <option value="">All</option>
                        <option v-for="(status, index) in packageStatuses" :key="index" :value="index">{{ status }}</option>
                    </select>
                </div>
    
            </div>

            <!-- Download report buttont button -->
            <div class="flex justify-between items-center mt-8 -mb-8">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="parcelNumber">Parcel Number</option>
                        <option value="destination">Destination</option>
                        <option value="service">Service</option>
                        <option value="codStatus">COD Status</option>
                        <option value="packageStatus">Parcel Status</option>
                    </select>
                </div>
                
                <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading">Download Report</button>

            </div>



            <!-- Order table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="codOrdersTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 text-center ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'parcelNumber' }">
                                <span>Parcel Number</span>
                                <button v-if="sortBy == 'parcelNumber'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'destination' }">
                                <span>Destination</span>
                                <button v-if="sortBy == 'destination'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'service' }">
                                <span>Service</span>
                                <button v-if="sortBy == 'service'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'codStatus' }">
                                <span>COD Status</span>
                                <button v-if="sortBy == 'codStatus'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'packageStatus' }">
                                <span>Package Status</span>
                                <button v-if="sortBy == 'packageStatus'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    
                    <!-- Show orders details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredCODOrders.length" v-for="(order, index) in filteredCODOrders" :key="order.id" class="bg-white border-b text-center hover:bg-gray-50">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3 hover:text-blue-500">
                                <router-link :to="'/admin/orders/' + order.id">{{ order.invoice_id }}</router-link>
                            </td>
                            <td class="px-6 py-3">
                                {{ order.destination }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ order.route_type }}
                            </td>
                            <td class="px-6 py-3">
                                <span v-if="!order.COD_Confirmation" class="rounded-full bg-red-500 px-6 py-1 text-white font-semibold">PENDING</span>
                                <span v-if="order.COD_Confirmation" class="rounded-full bg-green-500 text-white font-semibold px-8 py-1">PAID</span>
                            </td>
                            <td class="px-6 py-3">
                                <span v-if="order.status === 'PACKAGE CANCELLED'" class="px-6 py-1 text-red-700 font-semibold" >CANCELLED</span>

                                <span v-else-if="order.payment_status === 'UNPAID'" class="rounded-full bg-red-500 px-3 py-1 text-white font-semibold truncate" >Pending Payment</span>
                                
                                <span v-else>
                                    <span v-if="order.status === 'PACKAGE CREATED'" class="rounded-full bg-gray-400 py-1 text-white font-semibold" >
                                        <span v-if="order.collector" class="px-3 truncate" >Pending Collection</span>
                                        <span v-else-if="order.dropoff_destination" class="px-4 truncate" >Pending dropoff</span>
                                        <span v-else class="px-12" >Ready</span>
                                    </span>

                                    <span v-else-if="order.status === 'COLLECTED BY COLLECTOR'" class="rounded-full bg-gray-600 px-10 py-1 text-white font-semibold truncate" >Collected</span>

                                    <span v-else-if="order.status === 'DELIVERED TO DROPOFF AGENT'" class="rounded-full bg-gray-600 px-7 py-1 text-white font-semibold truncate" >Dropped Off</span>

                                    <span v-else-if="order.status === 'PACKAGE IN OFFICE'" class="rounded-full bg-gray-800 px-3 py-1 text-white font-semibold truncate" >
                                        <span v-if="order.driver" class="px-1 truncate" >Pending Delivery</span>
                                        <span v-else class="px-7 truncate" >In Office</span>
                                    </span>

                                    <span v-else-if="order.status === 'PACKAGE IN TRANSIT TO OFFICE'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >Incoming</span>

                                    <span v-else-if="order.status === 'PACKAGE IN TRANSIT TO AGENT'" class="rounded-full bg-orange-500 px-10 py-1 text-white font-semibold truncate" >In Transit</span>

                                    <span v-else-if="order.status === 'DELIVERED TO AGENT'" class="rounded-full bg-green-500 px-3 py-1 text-white font-semibold truncate" >Waiting Recepient</span>

                                    <span v-else-if="order.status === 'RECEIVED BY RECIPIENT'" class="px-6 py-1 text-gray-400 font-semibold" >FULFILLED</span>

                                    <span v-else-if="order.status === 'RETURN PACKAGE'" class="px-6 py-1 text-red-400 font-semibold" >RETURNED</span>

                                </span>

                            </td>
                        </tr>

                        <!-- No orders -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No orders
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapMutations } from 'vuex';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable'

    // import logo
    import logo from '../../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'CODOrders',

        data() {
            return {
                // orders: [],
                // paidOrders: 0,
                // unpaidOrders: 0,
                // unpaidOrdersValue: 0,
                // paidOrdersValue: 0,

                // filters
                pkgNumberFilter: '',
                serviceFilter: '',
                codStatusFilter: '',
                packageStatusFilter: '',

                // sort by
                sortBy: '',
                sortAscending: false


            }
        },
        components: {
            LoadingIcon,
        },

        async created() {
            await this.$store.dispatch('fetchAllParcels')

            // await this.getOrders()

        },

        computed: {
            ...mapGetters(['getServices', 'getPackageStatuses', 'getAllParcels']),

            orders() {
                return this.getAllParcels.filter(parcel => parcel.parcel_type === 'CASH ON DELIVERY' && parcel.payment_status == 'PAID')
            },

            filteredCODOrders() {
                let codOrdersData = this.orders

                codOrdersData = codOrdersData.filter(order => {
                    const pkgNumberMatch = this.pkgNumberFilter ? order.invoice_id.toLowerCase().includes(this.pkgNumberFilter.toLowerCase()) : true;
                    const serviceMatch = this.serviceFilter.trim() === '' || order.route_type == this.serviceFilter;
                    const codStatusMatch = this.codStatusFilter.trim() === '' || order.COD_Confirmation.toString() == this.codStatusFilter;
                    const packageStatusMatch = this.packageStatusFilter.trim() === '' || order.status == this.packageStatusFilter;


                    return pkgNumberMatch && serviceMatch && codStatusMatch && packageStatusMatch

                })

                if (this.sortBy == 'parcelNumber') {
                    codOrdersData = codOrdersData.sort((a, b) => {
                        return this.sortAscending ? a.invoice_id.localeCompare(b.invoice_id) : b.invoice_id.localeCompare(a.invoice_id);
                    });
                } else if (this.sortBy == 'destination') {
                    codOrdersData = codOrdersData.sort((a, b) => {
                        return this.sortAscending ? a.destination.localeCompare(b.destination) : b.destination.localeCompare(a.destination);
                    });
                } else if (this.sortBy == 'service') {
                    codOrdersData = codOrdersData.sort((a, b) => {
                        return this.sortAscending ? a.route_type.localeCompare(b.route_type) : b.route_type.localeCompare(a.route_type);
                    });
                } else if (this.sortBy == 'codStatus') {
                    codOrdersData = codOrdersData.sort((a, b) => {
                        return this.sortAscending ? a.COD_Confirmation - b.COD_Confirmation : b.COD_Confirmation - a.COD_Confirmation;
                    });
                } else if (this.sortBy == 'packageStatus') {
                    codOrdersData = codOrdersData.sort((a, b) => {
                        return this.sortAscending ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
                    });
                }

                return codOrdersData
            },

            unpaidCODOrders() {
                return this.orders.filter(order => !order.COD_Confirmation).length
            },

            unpaidCODValue() {
                return this.orders.filter(order => !order.COD_Confirmation).reduce((acc, order) => acc + order.parcel_value, 0)
            },

            paidCODOrders() {
                return this.orders.filter(order => order.COD_Confirmation).length
            },

            paidCODValue() {
                return this.orders.filter(order => order.COD_Confirmation).reduce((acc, order) => acc + order.parcel_value, 0)
            },

            services() {
                return this.getServices
            },

            packageStatuses() {
                return this.getPackageStatuses
            }
        },

        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true)
                
                const today = new Date().toLocaleDateString()

                const doc = new jsPDF({
                    orientation: "landscape"
                })

                const table = this.$refs.codOrdersTable

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'COD Orders Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);
                
                // logo
                const logoWidth = 40
                const logoHeight = 40
                const logoX = 20
                const logoY = titleY + 10

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)

                // date and other information
                const reportInfo = `Date: ${today}\n\nTotal orders: ${this.orders.length}`
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10

                doc.autoTable({
                    html: table,
                    startY: tableStartY
                 })

                doc.save(`cod_orders-${today}.pdf`)

                this.setLoadingStatus(false)
            }
        }
    }
</script>