<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="grid gap-y-4 bg-gray-100 shadow-md rounded-md mx-auto container p-4 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Transactions</h1>
                <p class="text-gray-600">Total transactions: <span class="font-semibold">{{ transactions.length }}</span></p>
                <p class="text-gray-600">Total amount: <span class="font-semibold">KSHS. {{ totalAmount }}</span></p>

            </div>


            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-3 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-3">Filters</h1>

                <!-- Transaction type -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Transaction type</label>
                    <select id="transactionTypeFilter" v-model="transactionTypeFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Transaction type">
                        <option value="">All</option>
                        <option value="Payment for package">Payment for package</option>
                        <option value="Deposit to personal wallet">Wallet deposit</option>
                    </select>
                </div>

                <!-- M-Pesa code -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Mpesa Code:</label>
                    <input type="text" id="mpesaCodeFilter" v-model="mpesaCodeFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Mpesa Code" />
                </div>

                <!-- Number -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Number:</label>
                    <input type="text" id="phoneFilter" v-model="phoneFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Phone Number" />
                </div>
    
            </div>

            <!-- Download report buttont button -->
            <div class="flex justify-between items-center mt-8 -mb-8">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <!-- <option value="name">Name</option> -->
                        <option value="transactionType">Transaction Type</option>
                        <option value="amount">Amount</option>
                        <option value="date">Date</option>
                    </select>
                </div>
                
                <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading" >Download Report</button>

            </div>


            <!-- Transaction table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="transactionsTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'transactionType' }">
                                <span>Transaction Type</span>
                                <button v-if="sortBy == 'transactionType'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                M-Pesa Code
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Phone
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'amount' }">
                                <span>Amount</span>
                                <button v-if="sortBy == 'amount'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'date' }">
                                <span>Date</span>
                                <button v-if="sortBy == 'date'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>

                    <!-- Show transactions details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredTransactions.length" v-for="(transaction, index) in filteredTransactions" :key="transaction.id"
                            class="bg-white border-b">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3">
                                {{ transaction.transaction_type }}
                            </td>
                            <td class="px-6 py-3">
                                {{ transaction.receipt_no }}
                            </td>
                            <td class="px-6 py-3">
                                {{ transaction.phone_number }}
                            </td>
                            <td class="px-6 py-3">
                                {{ transaction.amount }}
                            </td>
                            <td class="px-6 py-3">
                                {{ transaction.created_at.split('T')[0] }}
                            </td>
                        </tr>

                        <!-- No transactions -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No transactions
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />

                                </div>
                            </div>
                        </div>
                    </div>


                </table>
            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapMutations } from 'vuex';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable'

    // import logo
    import logo from '../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'Transactions',
        data() {
            return {
                // transactions: [],

                // filters
                mpesaCodeFilter: '',
                nameFilter: '',
                phoneFilter: '',
                packageIDFilter: '',
                transactionTypeFilter: '',

                // sorting
                sortBy: '',
                sortAscending: false
            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getTransactions']),

            transactions() {
                return this.getTransactions
            },

            totalAmount() {
                let amount = 0

                for (const i in this.transactions) {
                    amount += this.transactions[i].amount
                }

                return amount
            },

            filteredTransactions() {
                let transactionsData = this.transactions

                transactionsData = transactionsData.filter(transaction => {
                    const mpesaCodeMatch = this.mpesaCodeFilter ? transaction.receipt_no.includes(this.mpesaCodeFilter.toUpperCase()) : true;
                    const phoneMatch = this.phoneFilter ? transaction.phone_number.toString().includes(this.phoneFilter.toString()) : true;
                    const transactionTypeMatch = this.transactionTypeFilter ? (transaction.transaction_type || '').toLowerCase().includes(this.transactionTypeFilter.toLowerCase()) : true;

                    return mpesaCodeMatch && phoneMatch && transactionTypeMatch;

                });

                if (this.sortBy == 'transactionType') {
                    transactionsData = transactionsData.sort((a, b) => {
                        return this.sortAscending ? a.transaction_type.localeCompare(b.transaction_type) : b.transaction_type.localeCompare(a.transaction_type)
                    })
                } else if (this.sortBy == 'amount') {
                    transactionsData = transactionsData.sort((a, b) => {
                        return this.sortAscending ? a.amount - b.amount : b.amount - a.amount
                    })
                } else if (this.sortBy == 'date') {
                    transactionsData = transactionsData.sort((a, b) => {
                        return this.sortAscending ? new Date(a.created_at) - new Date(b.created_at) : new Date(b.created_at) - new Date(a.created_at)
                    })
                }

                return transactionsData

            }
        },
        async created() {
            this.$store.dispatch('fetchTransactions')
        },

        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true)
                
                const today = new Date().toLocaleDateString()

                const doc = new jsPDF({
                    orientation: "landscape"
                })

                const table = this.$refs.transactionsTable

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'Transactions Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);
                
                // logo
                const logoWidth = 40
                const logoHeight = 40
                const logoX = 20
                const logoY = titleY + 10

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)

                // date and other information
                const reportInfo = `Date: ${today}\n\nTotal transactions: ${this.transactions.length}\n\nTotal amount: ${this.totalAmount}`
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10

                doc.autoTable({
                    html: table,
                    startY: tableStartY
                 })

                doc.save(`transactions-${today}.pdf`)

                this.setLoadingStatus(false)
            }
        }
        }
    
</script>