<template>
    <div class="py-6 md:py-12 bg-gray-200 h-full">
        <div class="mx-auto max-w-7xl px-3 lg:px-16">
            <!-- Header -->
            <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
                <div>
                    <button @click="$router.back()"
                        class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                                fill="currentColor" />
                        </svg>

                        <span>Back</span>
                    </button>

                </div>

                <div>
                    <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Order ID:
                        <span class="text-gray-600 font-bold tracking-normal text-base">{{ orderDetails.invoice_id
                            }}</span>
                    </div>
                </div>

            </div>

            <!-- all details -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <div class="col-span-1 lg:col-span-4">
                    <!-- Order details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Order Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Parcel No.</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.invoice_id }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Description</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.parcel_description }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Quantity</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.parcel_quantity }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date created</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.created.split('T')[0] }}</div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Destination details -->
                    <div class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Destination Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Agent Name</div>
                                <div class="text-gray-800 font-semibold">{{ agentDetails.first_name }} {{ agentDetails.last_name }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Shop Name</div>
                                <div class="text-gray-800 font-semibold">{{ agentDetails.collection_shop_name }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Location</div>
                                <div class="text-gray-800 font-semibold">{{ agentDetails.location_name }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Building</div>
                                <div class="text-gray-800 font-semibold">{{ agentDetails.agent_building }}, Floor No. {{ agentDetails.agent_floor }}, Shop No. {{ agentDetails.agent_room_no }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <div v-if="orderDetails.agent_receiving_status === 'PACKAGE RECEIVED'" class="text-gray-600">Date Delivered</div>
                                <div v-if="orderDetails.agent_receiving_status === 'PACKAGE RECEIVED'" class="text-gray-800 font-semibold">{{ orderDetails.date_delivered_to_agent.split('T')[0] }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <button @click="pickupPackageFromOffice"
                                    v-show="orderDetails.status == 'PACKAGE IN OFFICE' && orderDetails.driver == driverDetails.driver_id"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Accept Package</span>
                                </button>
                                <button @click="givePackageToAgent"
                                    v-show="orderDetails.status == 'PACKAGE IN TRANSIT TO AGENT' && orderDetails.agent_receiving_status === 'NULL'"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Give Package To Agent</span>
                                </button>
                                <button @click="dropoffAtOffice"
                                    v-show="orderDetails.status == 'PACKAGE IN TRANSIT TO OFFICE'"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Give Package To Office</span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <!-- Return Packages -->
                    <div v-if="orderDetails.status === 'RETURN PACKAGE'" class="bg-white shadow-md rounded-md p-4 mt-4">
                        <h2 class="text-xl font-semibold text-gray-800 mb-4">Return Details</h2>
                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                            <div class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Returned By Agent</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.date_returned_by_agent.split('T')[0] }}, {{ orderDetails.date_returned_by_agent.split('T')[1].split('.')[0] }}</div>
                            </div>
                            <div v-if="orderDetails.package_return" class="col-span-1 lg:col-span-1">
                                <div class="text-gray-600">Date Returned To Office</div>
                                <div class="text-gray-800 font-semibold">{{ orderDetails.date_returned_to_office.split('T')[0] }}, {{ orderDetails.date_returned_to_office.split('T')[1].split('.')[0] }}</div>
                            </div>
                            <div class="col-span-1 lg:col-span-1">
                                <button @click="returnToOffice"
                                    v-show="orderDetails.status == 'RETURN PACKAGE' && !orderDetails.package_return"
                                    class="flex justify-center bg-blue-500 text-white font-semibold tracking-normal text-base py-2 px-4 rounded-md hover:bg-blue-600">
                                    <span v-if="$store.state.isLoading">
                                        <LoadingIcon />
                                    </span>
                                    <span v-else>Return To Office</span>
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import LoadingIcon from '@/components/LoadingIcon.vue'
import { MESSAGE_TEMPLATES } from '@/services/messageTemplates';
import { sendMessage } from '@/services/messagingService';

export default {
    name: "DriverOrder",
    data() {
        return {
            id: this.$route.params.id,
        }
    },
    components: {
        LoadingIcon,
    },
    computed: {
        ...mapGetters(['getParcelById', 'getCustomerById', 'getAgentById', 'getDriverDetails']),

        driverDetails() {
            return this.getDriverDetails
        },

        orderDetails() {
            return this.getParcelById(this.id);
        },

        agentDetails() {
            return this.getAgentById(this.orderDetails.agent);
        },

        customerDetails() {
            return this.getCustomerById(this.orderDetails.customer);
        }
    },
    async created () {
        await this.$store.dispatch('fetchCustomers');
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        async pickupPackageFromOffice() {
            /**
             * When the driver picks up the package from the admin/dispatcher
             * TODO: Details to change:
             *      * status: 'PACKAGE IN TRANSIT TO AGENT'
             *      * date_leaving_office: new Date()
             */

            this.setLoadingStatus(true)

            const data = {
                status: 'PACKAGE IN TRANSIT TO AGENT',
                date_leaving_office: new Date()
            }
            
            await axios
                .put(`/packages/${this.id}/`, data)
                .then((response) => {
                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} picked up successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/driver/pending')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async pickupDropoffPackage() {
            /** 
             * ! UNUSED
             * When the driver picks up the package from the agent
             * TODO: Details to change:
             *      * status: 'PACKAGE IN TRANSIT TO OFFICE'
             */
        },

        async givePackageToAgent() {
            /**
             * When the driver gives the package to the agent
             * TODO: Details to change:
             *      * agent_receiving_status: 'PENDING'
             */

            this.setLoadingStatus(true)

            const data = {
                agent_receiving_status: 'PENDING'
            }

            await axios
                .put(`/packages/${this.id}/`, data)
                .then((response) => {
                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} given to agent successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/driver/in-transit')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async dropoffAtOffice() {
            /**
             * When the driver drops off the package at the admin
             * TODO: Details to change:
             *      * date_received_at_office
             *      * status: 'PACKAGE IN OFFICE'
             *      * updateDriverTally()
             *      * sendReceivedMessage()
             */

            this.setLoadingStatus(true)

            const data = {
                date_received_at_office: new Date(),
                status: 'PACKAGE IN OFFICE'
            }

            await axios
                .put(`/packages/${this.id}/`, data)
                .then(async (response) => {

                    await this.updateDriverTally()
                    await this.sendReceivedMessage()

                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} dropped off successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/driver/in-transit')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async returnToOffice() {
            /**
             * When the driver returns the package to the admin
             * TODO: Details to change:
             *      * package_return: true
             *      * date_returned_to_office: new Date()
             */

            this.setLoadingStatus(true)

            const data = {
                package_return: true,
                date_returned_to_office: new Date()
            }

            await axios
                .put(`/packages/${this.id}/`, data)
                .then((response) => {
                    this.$toast.success(
                        `Package ${this.orderDetails.invoice_id} returned successfully!`,
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push('/users/driver/return')
                })
                .catch((error) => {
                    this.$toast.error(
                        "An error occurred. Please try again",
                        {
                            position: "bottom-right",
                            duration: 3000
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        async updateDriverTally() {
            /**
             * Update driver tally
             * TODO: Details to update:
             *  * parcels_delivered: driverDetails.parcels_delivered + 1
             */

            this.setLoadingStatus(true)

            const data = {
                parcels_delivered: this.driverDetails.parcels_delivered + 1
            }

            await axios
                .put(`/drivers/${this.driverDetails.driver_id}/`, data)
                .then((response) => {
                })
                .catch((error) => {
                })

            this.setLoadingStatus(false)
        },

        async sendReceivedMessage() {
            /**
             * Send a message to the customer that their package has been received
             * * TEMPLATE: PACKAGE_RECEIVED
             * @data receiver: orderDetails.received,
             *      invoice_id: orderDetails.invoice_id,
             *      parcel_description: orderDetails.parcel_description
             * 
             * @data phoneNumber: orderDetails.receiver_phone_number
             */

             this.setLoadingStatus(true)

            const messageTemplate = MESSAGE_TEMPLATES.PACKAGE_RECEIVED

            const messageData = {
                receiver: this.orderDetails.receiver,
                invoice_id: this.orderDetails.invoice_id,
                parcel_description: this.orderDetails.parcel_description
            }

            const phoneNumber = this.orderDetails.receiver_phone_number

            try {
                await sendMessage(phoneNumber, messageTemplate, messageData)
            } catch (error) {
                console.error("Something went wrong while sending message: ", error);
            }

            this.setLoadingStatus(false)
        }
    },
}
</script>