<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="grid gap-y-4 bg-gray-100 shadow-md rounded-md mx-auto container p-4 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">COD Customers Report</h1>

                <p class="text-gray-600">Total Customers: <span class="font-semibold">{{ customers.length }}</span></p>
            </div>

            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-2 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-2">Filters</h1>

                <!-- Name-->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Name:</label>
                    <input type="text" id="nameFilter" v-model="nameFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Customer Name" />
                </div>

                <!-- Account Type -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 text-center ">Account Type</label>
                    <select id="accountTypeFilter" v-model="accountTypeFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">All</option>
                        <option value="INDIVIDUAL">Individual</option>
                        <option value="BUSINESS">Business</option>
                    </select>
                </div>

            </div>

            <!-- Download report buttont button -->
            <div class="flex justify-between items-center mt-8 -mb-8">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="name">Name</option>
                        <option value="codParcels">COD Parcels</option>
                        <option value="codBalance">COD Balance</option>
                        <option value="codPaid">COD Earned</option>
                    </select>
                </div>
                
                <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading" >Download Report</button>

            </div>

            <!-- Customers table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="customersTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'name' }">
                                <span>Name</span>
                                <button v-if="sortBy == 'name'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                <span>Account Type</span>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'codParcels' }">
                                <span>COD Parcels</span>
                                <button v-if="sortBy == 'codParcels'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'codBalance' }">
                                <span>COD Balance</span>
                                <button v-if="sortBy == 'codBalance'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'codPaid' }">
                                <span>COD Earned</span>
                                <button v-if="sortBy == 'codPaid'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                        </tr>
                    </thead>

                    <!-- Show Collector details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredCustomers.length" v-for="(customer, index) in filteredCustomers" :key="customer.id"
                            class="bg-white divide-y divide-gray-100">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3 truncate hover:text-blue-500">
                                <router-link :to="'/admin/customers/' + customer.customer_id">{{ customer.name }}</router-link>
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ customer.account_type }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ customer.cod_packages }}
                            </td>
                            <td class="px-6 py-3">
                                <span v-if="customer.COD_balance">Kshs. {{ customer.COD_balance }}</span>
                                <span v-else> - </span>
                            </td>
                            <td class="px-6 py-3 truncate">
                                <span v-if="customer.Total_earned">Kshs. {{ customer.Total_earned }}</span>
                                <span v-else> - </span>
                            </td>
                        </tr>

                        <!-- No customers -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No customers
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />

                                </div>
                            </div>
                        </div>
                    </div>


                </table>
            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import { mapGetters, mapMutations } from 'vuex';

    // import logo
    import logo from '../../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'CustomersCodReport',
        data() {
            return {
                // customers: [],
                // totalBalance: 0,

                // filters
                idFilter: '',
                nameFilter: '',
                phoneFilter: '',
                accountTypeFilter: '',

                // sorting
                sortBy: '',
                sortAscending: false

            }
        },
        components: {
            LoadingIcon,
        },
        async created() {
            this.$store.dispatch('fetchCustomers')
        },

        computed: {
            ...mapGetters(['getCustomers']),
            
            customers() {
                return this.getCustomers.filter(customer => customer.cod_packages)
            },

            filteredCustomers() {
                let customersData = this.customers

                customersData = customersData.filter(customer => {
                    const nameMatch = this.nameFilter ? customer.name.toLowerCase().includes(this.nameFilter.toLowerCase()) : true;
                    const phoneMatch = this.phoneFilter ? customer.phone_number.toString().includes(this.phoneFilter.toString()) : true;
                    const accountTypeMatch = this.accountTypeFilter.trim() === '' || customer.account_type == this.accountTypeFilter;

                    return nameMatch && phoneMatch && accountTypeMatch;
                });

                if (this.sortBy == 'name') {

                    customersData = customersData.sort((a, b) => {
                        return this.sortAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
                    })

                } else if (this.sortBy == 'codParcels') {
                        
                    customersData = customersData.sort((a, b) => {
                        return this.sortAscending ? a.cod_packages - b.cod_packages : b.cod_packages - a.cod_packages
                    })

                } else if (this.sortBy == 'codBalance') {

                    customersData = customersData.sort((a, b) => {
                        return this.sortAscending ? a.COD_balance - b.COD_balance : b.COD_balance - a.COD_balance
                    })

                } else if (this.sortBy == 'codPaid') {

                    customersData = customersData.sort((a, b) => {
                        return this.sortAscending ? a.Total_earned - b.Total_earned : b.Total_earned - a.Total_earned
                    })
                }

                return customersData
            }
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true)
                
                const today = new Date().toLocaleDateString()

                const doc = new jsPDF({
                    orientation: "landscape"
                })

                const table = this.$refs.customersTable

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'COD Customers Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);
                
                // logo
                const logoWidth = 40
                const logoHeight = 40
                const logoX = 20
                const logoY = titleY + 10

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)

                // date and other information
                const reportInfo = `Date: ${today}\nTotal balance: Kshs. ${this.totalBalance}`
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10

                doc.autoTable({ 
                    html: table,
                    startY: tableStartY
                 })

                doc.save(`customers_cod_report-${today}.pdf`)

                this.setLoadingStatus(false)
            }
        }
    }
</script>