<template>
    <div class="flex flex-col items-center bg-white rounded-lg shadow md:flex-row">
        <div class="md:basis-2/5">
            <img src="../../assets/homepage/homepage2.jpg" alt="" class="basis-1/4 rounded-t-lg md:h-[525px] md:w-full md:rounded-none md:rounded-s-lg object-cover object-center">
        </div>

        
        <div class="basis-1 md:basis-3/5">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Form'
    }
</script>