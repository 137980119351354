<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">

            <!-- Title -->
            <div class="bg-gray-100 mx-auto container py-6 px-8 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Services</h1>
            </div>

            <!-- Services table -->
            <div class="relative overflow-x-auto my-12">
                <table class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Service
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total orders
                            </th>
                        </tr>
                    </thead>
                    
                    <!-- Show Agent details when details loaded -->
                    <tbody v-if="!$store.state.isLoading" class="divide-y divide-y-100">
                                                    
                        <tr v-if="services" v-for="(value, service, index) in services" :key="value">
                            <th class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap">{{ index + 1 }}</th>
                            <td class="px-6 py-3">{{ service }}</td>
                            <td class="px-6 py-3">{{ value }}</td>
                        </tr>
                        

                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-red-700 whitespace-nowrap ">
                                Error fetching services
                            </th>
                        </tr>
    
                    </tbody>
    
    
                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
    
                    
                </table>
            </div>

        </div>

    </div>
</template>

<script>
import LoadingIcon from '@/components/LoadingIcon.vue';
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: "Services",

        data() {
            return {
                
            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getServices', 'getPaidParcels']),

            services() {
                const services = { ...this.getServices, COD: 0 };

                for (const service of Object.keys(services)) {
                    services[service] = 0;
                }

                for (const parcel of this.getPaidParcels) {
                    const { route_type, parcel_type } = parcel;

                    if (!services[route_type]) {
                        services[route_type] = 1;
                    } else {
                        services[route_type]++;
                    }

                    if (parcel_type === "CASH ON DELIVERY") {
                        services["COD"]++;
                    }
                }

                return services;
            },

        },

    }
</script>