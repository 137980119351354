<template>
    <div class="container mx-auto bg-white">

        <div class="py-6 md:py-12 h-full md:h-screen">
            <!-- Title -->
            <div class="bg-gray-100 mx-auto container py-6 px-8 mb-3">
                <h1 class="font-bold text-4xl text-gray-900">Collectors</h1>
            </div>

            <!-- Filter fields -->
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 lg:grid-cols-4 py-4">
                <h1 class="font-semibold text-2xl text-gray-600 mb-3 col-span-1 lg:col-span-4">Filters</h1>

                <!-- ID -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">ID:</label>
                    <input type="text" id="route" v-model="idFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="ID" />
                </div>

                <!-- Collector Name -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Name:</label>
                    <input type="text" id="route" v-model="nameFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Collector Name" />
                </div>

                <!-- Phone -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Number:</label>
                    <input type="text" id="route" v-model="phoneFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Phone Number" />
                </div>
                
                <!-- Active/Inactive -->
                <div class="col-span-1 lg:col-span-1 items-center">
                    <label for="route" class="block mb-2 text-sm font-medium text-gray-900 ">Status:</label>
                    <select id="paymentFilter" v-model="activeFilter"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Payment Status">
                        <option value="">All</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                    </select>
                </div>
    
            </div>

            <!-- New Collector button -->
            <div class="flex justify-between items-center mt-8 -mb-8 gap-x-4">

                <!-- Sort By -->
                <div class="flex gap-x-4 items-center">
                    <label for="sortBy" class="block text-sm font-medium text-gray-900 ">Sort By:</label>
                    <select id="sortBy" v-model="sortBy"
                        class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="">
                        <option value="">None</option>
                        <option value="name">Name</option>
                        <option value="registrationDate">Registration Date</option>
                        <option value="totalOrders">Total Orders</option>
                        <option value="completedOrders">Completed Orders</option>
                    </select>
                </div>
                
                <div class="flex gap-x-4">
                    <router-link to="/admin/collectors/new"
                        class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                        type="button">New Collector</router-link>
                    <button class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 " type="button" @click="downloadReport()" :disabled="this.$store.state.isLoading">Download Report</button>
                </div>

            </div>

            <!-- collectors table -->
            <div class="relative overflow-x-auto my-12">
                <table ref="collectorsTable" class="w-full text-sm text-left text-gray-500 ">
                    <thead class="text-sm text-gray-700 uppercase bg-gray-50 ">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                #
                            </th>
                            <th scope="col" class="px-6 py-3">
                                ID
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'name' }">
                                <span>Name</span>
                                <button v-if="sortBy == 'name'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Phone
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'registrationDate' }">
                                <span>Registration Date</span>
                                <button v-if="sortBy == 'registrationDate'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'totalOrders' }">
                                <span>Total Orders</span>
                                <button v-if="sortBy == 'totalOrders'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3" :class="{'flex justify-between' : sortBy == 'completedOrders' }">
                                <span>Completed Orders</span>
                                <button v-if="sortBy == 'completedOrders'" type="button" v-on:click="sortAscending = !sortAscending" class="items-center">
                                    <span v-if="sortAscending" >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                    <span v-else >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                                        <path fill-rule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </button>
                            </th>
                            <th scope="col" class="px-6 py-3"></th>
                        </tr>
                    </thead>

                    <!-- Show Collector details when details loaded -->
                    <tbody v-if="!$store.state.isLoading">
                        <tr v-if="filteredCollectors.length" v-for="(collector, index) in filteredCollectors" :key="collector.collector_id"
                            class="bg-white border-b">
                            <th scope="row" class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                {{ index + +1 }}
                            </th>
                            <td class="px-6 py-3 hover:text-blue-500">
                                <router-link :to="'/admin/collectors/' + collector.collector_id">{{ collector.collector_code }}</router-link>
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ collector.name }}
                            </td>
                            <td class="px-6 py-3">
                                {{ collector.phone_number }}
                            </td>
                            <td class="px-6 py-3 truncate">
                                {{ collector.date_joined.split(' ')[0] }}
                            </td>
                            <td class="px-6 py-3 text-center">
                                {{ collector.total_packages }}
                            </td>
                            <td class="px-6 py-3 text-center">
                                {{ collector.parcels_collected }}
                            </td>
                            <td class="px-6 py-3">
                                <div v-if="collector.active">
                                    <span class="hidden">Active</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4.5" stroke="#0bd659" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                    </svg>
                                </div>
                                <div v-else>
                                    <span class="hidden">Inactive</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="4.5" stroke="#ed0e0e" class="size-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </td>
                        </tr>

                        <!-- No collectors -->
                        <tr v-else>
                            <th scope="row" class="text-xl px-6 py-3 font-medium text-gray-900 whitespace-nowrap ">
                                No collectors
                            </th>
                        </tr>

                    </tbody>


                    <!-- Loading indicator -->
                    <div v-else>
                        <div>
                            <div class="flex items-center justify-center px-6 py-3">
                                <div class="flex justify-center items-center">
                                    <div class="mr-2">Loading...</div>
                                    <LoadingIcon />

                                </div>
                            </div>
                        </div>
                    </div>


                </table>
            </div>

        </div>

    </div>

</template>

<script>
    import axios from 'axios';
    import { mapGetters, mapMutations } from 'vuex';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable'

    // import logo
    import logo from '../../../assets/logo.jpg'
import LoadingIcon from '@/components/LoadingIcon.vue';


    export default {
        name: 'Collectors',
        data() {
            return {
                // Filters
                idFilter: '',
                phoneFilter: '',
                nameFilter: '',
                activeFilter: '',

                // sort
                sortBy: '',
                sortAscending: true

            }
        },
        components: {
            LoadingIcon,
        },
        async created() {
            await this.$store.dispatch('fetchCollectors')

        },
        computed: {
            ...mapGetters(['getCollectors']),

            collectors() {
                return this.getCollectors
            },

            filteredCollectors() {
                let collectorsData = this.collectors

                collectorsData = collectorsData.filter(collector => {
                    const idMatch = this.idFilter ? collector.collector_code.toLowerCase().includes(this.idFilter.toLowerCase()) : true;
                    const nameMatch = this.nameFilter ? collector.name.toLowerCase().includes(this.nameFilter.toLowerCase()) : true;
                    const phoneMatch = this.phoneFilter ? collector.phone_number.toString().includes(this.phoneFilter.toString()) : true;
                    const activeMatch = this.activeFilter.trim() === '' || collector.active.toString() == this.activeFilter;

                    return idMatch && nameMatch && phoneMatch && activeMatch;
                });

                if (this.sortBy == 'name') {
                    collectorsData = collectorsData.sort((a, b) => {
                        return this.sortAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
                    })
                } else if (this.sortBy == 'registrationDate') {
                    collectorsData = collectorsData.sort((a, b) => {
                        return this.sortAscending ? a.date_joined.localeCompare(b.date_joined) : b.date_joined.localeCompare(a.date_joined)
                    })
                } else if (this.sortBy == 'totalOrders') {
                    collectorsData = collectorsData.sort((a, b) => {
                        return this.sortAscending ? a.total_packages - b.total_packages : b.total_packages - a.total_packages
                    })
                } else if (this.sortBy == 'completedOrders') {
                    collectorsData = collectorsData.sort((a, b) => {
                        return this.sortAscending ? a.parcels_collected - b.parcels_collected : b.parcels_collected - a.parcels_collected
                    })
                }


                return collectorsData
            }
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            // download report
            async downloadReport() {
                this.setLoadingStatus(true)

                const today = new Date().toLocaleDateString()

                const doc = new jsPDF({
                    orientation: "landscape"
                })

                const table = this.$refs.collectorsTable

                // title
                doc.setFont('helvetica', 'bold');
                doc.setFontSize(24);

                const titleText = 'Collectors Report';
                const titleX = 20;
                const titleY = 20;
                doc.text(titleText, titleX, titleY);
                
                // logo
                const logoWidth = 40
                const logoHeight = 40
                const logoX = 20
                const logoY = titleY + 10

                doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight)

                // date and other information
                const reportInfo = `Date: ${today}\n\nTotal Collectors: ${this.collectors.length}`
                const reportInfoX = doc.internal.pageSize.getWidth() - 20; // Adjust position
                const reportInfoY = logoY; // Align with logo
                doc.setFont('helvetica', 'normal');
                doc.setFontSize(12);
                doc.text(reportInfo, reportInfoX, reportInfoY, null, null, 'right');

                // add table
                const tableStartY = logoY + logoHeight + 10

                doc.autoTable({ 
                    html: table,
                    startY: tableStartY
                 })

                doc.save(`collectors-${today}.pdf`)

                this.setLoadingStatus(false)
            }
            
        }
    }
</script>