<template>
    <div class="pb-10">
        <div class="mx-5 md:mx-24 px-8 pt-6">
            <button @click="$router.push('/admin/riders')" class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">
                    
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z" fill="currentColor"/>
                </svg>

                <span>Back</span> 
            </button>

        </div>
        
        <div class="items-center pb-12">
            <h1 class="text-center text-2xl text-black font-bold">New Rider</h1>
        </div> 

        <form @submit.prevent="createRider" class="mb-8 md:mx-6 w-auto items-center items-center px-4 md:px-24">
            <div>
                <h2 class="font-bold text-2xl mb-5">Rider Profile</h2>
                                
                <!-- Zone -->
                <div class="flex gap-3 items-center mb-5">
                    <label for="zone" class="block mb-2 text-base font-medium text-gray-900 ">Zone:</label>
                    <select name="zone" id="zone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 " v-model="zone" required >
                        <option disabled value="">Select Zone</option>
                        <option v-for="(zone, index) in zones" :key="index" :value="index">{{ zone.split('-')[1] }}</option>
                    </select>
                </div>

                
                <!-- Name -->
                <div class="grid md:grid-cols-2 md:gap-6">
                    <!-- First Name -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="firstName" class="block mb-2 text-sm font-medium text-gray-900 ">First Name:</label>
                        <input type="text" id="firstName" class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="First Name" v-model="firstName" required />

                    </div>

                    <!-- Last Name -->
                    <div class="relative z-0 w-full mb-5 group">
                        <label for="lastName" class="block mb-2 text-sm font-medium text-gray-900 ">Last Name</label>
                        <input type="text" id="lastName" class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Last Name" v-model="lastName" required />
                    </div>

                </div>

                <!-- Email -->
                <div class="relative z-0 w-full mb-5 group">
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 ">Email:</label>
                    <input type="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Email" v-model="email" required />
                </div>

                 <!-- Password -->
                 <div class="relative z-0 w-full mb-5 group">
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 ">Password:</label>
                    <input type="password" id="password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        placeholder="******" v-model="password" required />
                </div>

                <!-- Confirm Password -->
                <div class="relative z-0 w-full mb-5 group">
                    <label for="confirmPassword" class="block mb-2 text-sm font-medium text-gray-900 ">Confirm Password:</label>
                    <input type="password" id="confirmPassword"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        placeholder="******" v-model="confirmPassword" required />

                    <p v-if="errors.length" class="text-red-500 text-base font-bold">Passwords do not match</p>
                </div>
                
                <!-- Phone Number -->
                <div class="relative z-0 w-full mb-5 group">
                    <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900 ">Phone Number:</label>
                    <input type="tel" id="phoneNumber" class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="Phone Number" v-model="phoneNumber" required />
                </div>
                                
                <!-- Driver's License -->
                <div class="relative z-0 w-full mb-5 group">
                    <label for="closeTime" class="block mb-2 text-base font-medium text-gray-900 ">Driver's License:</label>
                    
                    <div class="flex items-center justify-center">
                        <label for="licenseUpload" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                            <div v-if="driverLicense">
                                <!-- <img :src="driverLicense" alt="Driver license"> -->
                                {{ driverLicense.name }}
                            </div>
                            <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                </svg>
                                <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                <p class="text-xs text-gray-500">SVG, PNG, or JPG (MAX. 800x400px)</p>
                            </div>
                            <input id="licenseUpload" type="file" v-on:change="handleFilesUpload()" ref="license" class="hidden" />
                        </label>
                    </div>

                </div>

                <!-- ID Number -->
                <div class="relative z-0 w-full mb-5 group">
                    <label for="idNumber" class="block mb-2 text-sm font-medium text-gray-900 ">ID Number:</label>
                    <input type="number" id="idNumber" class="capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " placeholder="ID Number" v-model="idNumber" required />
                </div>

                <!-- ID Image -->
                <h2 class="font-semibold text-xl mb-5">ID Images</h2>

                <div class="mb-7 grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-7">
                    <!-- ID front image -->
                    <div>
                        <p class="block mb-2 text-base font-medium text-gray-900 ">Front:</p>
                        
                        <div class="flex items-center justify-center">
                            <label for="id_front_image" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                <div v-if="id_front_image">
                                    {{ id_front_image.name }}
                                </div>
                                <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <input id="id_front_image" type="file" v-on:change="handleFilesUpload()" ref="id_front_image" class="hidden" required>
                            </label>
                        </div>

                    </div>

                    <!-- ID back image -->
                    <div>
                        <label for="id_back_image" class="block mb-2 text-base font-medium text-gray-900 ">Back:</label>
                        
                        <div class="flex items-center justify-center">
                            <label for="id_back_image" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-200">
                                <div v-if="id_back_image">
                                    {{ id_back_image.name }}
                                </div>
                                <div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                                    </svg>
                                    <p class="mb-2 text-sm text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                    <p class="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                </div>
                                <input id="id_back_image" type="file" v-on:change="handleFilesUpload()" ref="id_back_image" class="hidden" required />
                            </label>
                        </div>

                    </div>

                </div>


            </div>

            <button type="submit" class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                <span v-if="!$store.state.isLoading">Complete</span>
                <span v-else ><LoadingIcon /></span>
            </button>


        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import Validations from '@/services';
import LoadingIcon from '@/components/LoadingIcon.vue';
import imageCompression from 'browser-image-compression';

    export default {
        name: 'NewRider',
        data() {
            return {
                zone: '',
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                phoneNumber: '',
                driverLicense: '',
                idNumber: '',
                id_front_image: '',
                id_back_image: '',
                errors: [],
                

            }
        },
        components: {
            LoadingIcon,
        },
        computed: {
            ...mapGetters(['getZones']),

            zones() {
                return this.getZones
            }
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            async createRider() {
                this.setLoadingStatus(true)

                this.errors = []

                let validPassword = new Validations(this.email, this.password)
                const isValid = validPassword.checkValidations()

                if (isValid !== true) {
                    for (const error in isValid) {
                        this.showErrorNotification(isValid[error])
                    }

                    this.setLoadingStatus(false)

                    return
                }

                if (this.password !== this.confirmPassword) {
                    this.errors.push("Passwords do not match")
                    this.showErrorNotification('Passwords do not match. Please try again!')

                    this.setLoadingStatus(false)
                    
                    return
                }
            
                // const data = {
                //     username: this.firstName + this.lastName,
                //     password: this.password,
                //     is_active: true,

                //     zone: this.zone,
                //     first_name: this.firstName,
                //     last_name: this.lastName,
                //     email: this.email,
                //     phone_number: this.phoneNumber,
                //     drivers_license: this.driverLicense,

                //     idcard_number: this.idNumber,
                //     idcard_front_image: this.id_front_image,
                //     idcard_back_image: this.id_back_image
                // }

                const data = new FormData();
                data.append('username', this.firstName + this.lastName);
                data.append('password', this.password);
                data.append('is_active', true);
                data.append('zone', this.zone);
                data.append('first_name', this.firstName);
                data.append('last_name', this.lastName);
                data.append('email', this.email);
                data.append('phone_number', this.phoneNumber);
                data.append('drivers_license', this.driverLicense);
                data.append('idcard_number', this.idNumber);
                data.append('idcard_front_image', this.id_front_image);
                data.append('idcard_back_image', this.id_back_image);

                await axios
                    .post('/driver/register/', data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {

                        this.showSuccessNotification("New Rider Created")

                        this.$store.state.driversLastFetched = null
                        this.$store.dispatch('fetchDrivers')

                        this.$router.push('/admin/riders/')
                    })
                    .catch((error) => {
                        this.showErrorNotification("Something went wrong. Please try again later!")
                    })


                this.setLoadingStatus(false)

            },

            async compressImage(file) {
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true
                }
                
                try {
                    const compressedFile = await imageCompression(file, options);
                    return compressedFile;
                } catch (error) {
                    console.error('Error compressing image:', error);
                    return file;  // Return original file if compression fails
                }
            },

            async handleFilesUpload() {
                const licenseFile = this.$refs.license.files[0];
                const frontFile = this.$refs.id_front_image.files[0];
                const backFile = this.$refs.id_back_image.files[0];

                if (licenseFile) {
                    const compressedLicense = await this.compressImage(licenseFile);
                    const licenseFileName = `${this.firstName}_${this.lastName}_drivers_license.${licenseFile.name.split('.').pop()}`;
                    this.driverLicense = new File([compressedLicense], licenseFileName, { type: compressedLicense.type });
                }

                if (frontFile) {
                    const compressedFront = await this.compressImage(frontFile);
                    const frontFileName = `${this.firstName}_${this.lastName}_ID_front.${frontFile.name.split('.').pop()}`;
                    this.id_front_image = new File([compressedFront], frontFileName, { type: compressedFront.type });
                }

                if (backFile) {
                    const compressedBack = await this.compressImage(backFile);
                    const backFileName = `${this.firstName}_${this.lastName}_ID_back.${backFile.name.split('.').pop()}`;
                    this.id_back_image = new File([compressedBack], backFileName, { type: compressedBack.type });
                }
            },

            showSuccessNotification(message){
                this.$toast.success(message,{
                    position: 'bottom-right',
                    duration:3000,
                });
            },
            showErrorNotification(message) {
                this.$toast.error(message, {
                    position: 'bottom-right',
                    duration: 3000,
                });

            },
        }
    }
</script>