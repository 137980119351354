<template>
    <!-- Invoice -->
    <div v-if="$store.state.isLoading" class="container mx-auto bg-white">
        <LoadingIcon />
    </div>
    <div class="container mx-auto bg-white">
        <div class="flex justify-between mx-5 md:mx-24 px-8 pt-12">
            <button @click="$router.push('/my-parcels')"
                class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                        fill="currentColor" />
                </svg>

                <span>My Parcels</span>
            </button>

            <button v-if="parcelDetails.status !== 'PACKAGE CANCELLED'" @click="cancelOrder" class="rounded-full bg-red-500 px-6 py-1 text-white font-semibold hover:shadow-lg">Cancel Order</button>
        </div>

        <div class="flex justify-between items-center py-12 px-8">
            <!-- Invoice Owner -->
            <div class="mx-5 md:mx-24">
                <img src="../assets/logo.jpg" alt="Timely Delivery" class="h-28 w-28 mb-3" />
                <p class="text-base font-semibold leading-6 text-gray-900">
                    {{ customerDetails.first_name }} {{ customerDetails.last_name }}
                </p>
                <p class="text-base font-semibold leading-6 text-gray-900">
                    0{{ customerDetails.phone_number }}
                </p>
            </div>

            <div class="mx-5 md:mx-24">
                <p class="text-xl font-bold leading-6 mb-3">
                    <span v-if="parcelDetails.payment_status === 'UNPAID'"
                        class="rounded-full bg-red-300 px-3 py-1 text-red-500">{{ parcelDetails.payment_status
                        }}</span>
                    <span v-if="parcelDetails.payment_status === 'PAID'"
                        class="rounded-full bg-green-300 text-green-500 px-3 py-1">{{ parcelDetails.payment_status
                        }}</span>
                </p>
                <p class="text-xl font-bold leading-6 text-gray-900">Invoice</p>
                <p class="text-base font-semibold leading-6 text-gray-900">
                    Invoice date: {{ parcelDetails.created.split('T')[0] }}
                </p>
                <p class="text-base font-semibold leading-6 text-gray-900">
                    Invoice No: {{ parcelDetails.invoice_id }}
                </p>
            </div>
        </div>

        <!-- Receiver Details -->
        <div class="pb-12 px-8">
            <div class="grid grid-cols-1 gap-y-4 items-center mx-5 md:mx-24 mb-5 " :class="[parcelDetails.dropoff_destination ? 'lg:grid-cols-4 gap-x-20' : 'lg:grid-cols-3 gap-x-36']">
                <div class="col-span-1 lg:col-span-1">
                    <h2 class="font-bold text-lg">Receiver</h2>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ parcelDetails.receiver }}
                    </p>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        0{{ parcelDetails.receiver_phone_number }}
                    </p>
                </div>
                
                <div class="col-span-1 lg:col-span-1">
                    <h2 class="font-bold text-lg">Service</h2>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ parcelDetails.route_type }}
                    </p>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ parcelDetails.parcel_type }}
                    </p>
                </div>
                
                <div v-if="parcelDetails.dropoff_destination" class="col-span-1 lg:col-span-1">
                    <h2 class="font-bold text-lg">Dropoff</h2>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ parcelDetails.dropoff_destination }}
                    </p>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ dropoffAgentDetails.collection_shop_name }}
                    </p>
                </div>

                <div class="col-span-1 lg:col-span-1">
                    <h2 class="font-bold text-lg">Destination</h2>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ parcelDetails.destination }}
                    </p>
                    <p class="text-base font-semibold leading-6 text-gray-900">
                        {{ agentDetails.collection_shop_name }}
                    </p>
                </div>
            </div>
        </div>

        <!-- Invoice Details -->
        <div class="pb-12 px-8">
            <div class="flex justify-between items-left mx-5 md:mx-24 text-left pr-12 mb-5">
                <p class="text-base font-semibold leading-6 text-gray-900">
                    Description
                </p>
                <p class="text-base font-semibold leading-6 text-gray-900">Value</p>
                <p class="text-base font-semibold leading-6 text-gray-900">Price</p>
            </div>

            <!-- details 1 -->
            <div class="flex justify-between bg-gray-200 mx-5 md:mx-24 pr-6 py-4 pl-2.5 mb-3">
                <p class="text-base font-medium leading-6 text-gray-900">
                    {{ parcelDetails.parcel_description }}
                </p>
                <p class="text-base font-medium leading-6 text-gray-900">
                    Kshs. {{ parcelDetails.parcel_value }}
                </p>
                <p class="text-base font-medium leading-6 text-gray-900">
                    Kshs. {{ invoicePrice }}
                </p>
            </div>

            <!-- details 2 -->
            <div v-if="parcelDetails.pickup_location"
                class="flex justify-between bg-gray-200 mx-5 md:mx-24 pr-6 py-4 pl-2.5 mb-3">
                <p class="text-base font-medium leading-6 text-gray-900">Collection</p>
                <!-- <p class="text-base font-medium leading-6 text-gray-900">Kshs. 50</p> -->
                <p class="text-base font-medium leading-6 text-gray-900">FREE</p>
            </div>

            <!-- Total -->
            <div class="flex justify-between items-left mx-5 md:mx-24 text-left pr-6 pl-2.5">
                <p class="text-xl font-bold leading-6 text-gray-900">Grand Total</p>
                <p class="text-xl font-bold leading-6 text-gray-900">
                    Kshs. {{ totalPrice }}
                </p>
            </div>
        </div>

        <!-- Payment -->
        <div v-if="parcelDetails.payment_status === 'UNPAID'" class="items-center mb-8">
            <h2 class="text-center text-3xl font-bold">Payment Method</h2>

            <div class="flex mx-5 md:mx-24 w-auto justify-around my-6">
                <ul class="flex pb-8 justify-between">
                    <li class="mx-2.5 my-2" v-for="(tab, index) in tabs" :key="index">
                        <button
                            class="text-base font-medium text-center inline-block px-4 pb-2.5 border-b-2 rounded-t-lg"
                            :class="{
                                'text-blue-600 border-blue-600': tab === activeTab,
                                'text-gray-500 hover:text-gray-800 hover:border-gray-500':
                                    tab !== activeTab,
                            }" @click="activeTab = tab">
                            {{ tab }}
                        </button>
                    </li>
                </ul>
            </div>

            <!-- Mpesa tab -->
            <div v-if="activeTab === 'Mpesa'">
                <form @submit.prevent="initiateSTKPush" class="mb-8 mx-5 md:mx-24 w-auto items-center px-8 md:px-52">
                    <!-- Mpesa phone number -->
                    <div class="mb-7">
                        <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Enter Mpesa Phone Number</label>
                        <input type="tel" id="mpesaPhoneNumber"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Enter Phone Number" v-model="mpesaPhoneNumber" required />
                    </div>

                    <button type="submit"
                        class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        <span v-if="!$store.state.isLoading">Pay Now</span>
                        <span v-else>
                            <LoadingIcon />
                        </span>
                    </button>
                </form>
            </div>

            <!-- Confirm tab -->
            <div v-if="activeTab === 'Confirm'">
                <form @submit.prevent="confirmCode()" class="mb-8 mx-5 md:mx-24 w-auto items-center px-8 md:px-52">
                    <!-- Confirm phone number -->
                    <div class="mb-7">
                        <label for="phoneNumber" class="block mb-2 text-sm font-medium text-gray-900">Enter Mpesa Code to confirm</label>
                        <p class="text-sm text-gray-500 mb-5">Use this for when you pay for your package and it doesn't update automatically.</p>
                        <input type="text" id="mpesaCode"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-4"
                            placeholder="Enter Mpesa Code" v-model="mpesaCode" required />
                    </div>

                    <button type="submit"
                        class="flex justify-center block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                        <span v-if="!$store.state.isLoading">Confirm Code</span>
                        <span v-else>
                            <LoadingIcon />
                        </span>
                    </button>
                </form>
            </div>

            <!-- Wallet -->
            <div v-if="activeTab === 'Wallet'">
                <form @submit.prevent="payViaWallet">
                    <div class="mb-8 mx-5 md:mx-24 w-auto px-8 md:px-52">
                        <p class="font-bold text-gray-800 text-2xl">
                            Wallet Balance : {{ customerDetails.wallet_amount }}
                        </p>
                        <br />
                        <button type="submit"
                            class="block w-full rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                            <span v-if="!$store.state.isLoading">Use Wallet to pay</span>
                            <span v-else>
                                <LoadingIcon />
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations, mapState } from "vuex";
import { stkPaymentValidation } from "@/services";
import LoadingIcon from "./LoadingIcon.vue";

export default {
    name: "Invoice",
    data() {
        return {
            id: this.$route.params.id,
            tabs: ["Mpesa", "Confirm", "Wallet"],
            activeTab: "Mpesa",
            mpesaCode: "",
            mpesaPhoneNumber: "",

            invoicePrice: null,

            mpesaSuccessful: false,
            mpesaFailed: false,
            mpesaResponses: [],
            mpesaPushResponse: [],

            errors: []
        };
    },
    components: {
        LoadingIcon,
    },
    computed: {
        ...mapGetters(['getParcelById', 'getCustomerDetails', 'getAgentById', 'getNewParcel', 'getParcelByTransactionId', 'getTransactionByReceiptNo']),

        customerDetails() {
            return this.getCustomerDetails
        },

        parcelDetails() {
            if (Object.keys(this.getNewParcel).length > 0) {
                return this.getNewParcel
            } else {
                return this.getParcelById(this.id)
            }
        },

        agentDetails() {
            return this.getAgentById(this.parcelDetails.agent);
        },

        dropoffAgentDetails() {
            return this.getAgentById(this.parcelDetails.dropoff_agent);
        },

        totalPrice() {
            if (this.parcelDetails.route_type === "CBD TO CBD") {
                this.invoicePrice = 80;
            } else if (this.parcelDetails.route_type === "CBD TO MTAANI") {
                this.invoicePrice = 100;
            } else if (this.parcelDetails.route_type === "MTAANI TO CBD") {
                this.invoicePrice = 100;
            } else if (this.parcelDetails.route_type === "MTAANI TO MTAANI") {
                this.invoicePrice = 180;
            } else if (this.parcelDetails.route_type === "MTAANI TO UPCOUNTRY") {
                this.invoicePrice = 280;
            } else if (this.parcelDetails.route_type === "CBD TO UPCOUNTRY") {
                this.invoicePrice = 280;
            }

            if (this.parcelDetails.pickup_location) {
                // return this.invoicePrice + 50;
                return this.invoicePrice + 0;
            } else {
                return this.invoicePrice;
            }
        },

        usedTransactionCode() {
            if (this.getParcelByTransactionId(this.mpesaCode).length) {
                return true
            } else {
                return false
            }
        },

        validTransaction() {
            const transaction = this.getTransactionByReceiptNo(this.mpesaCode)

            if (transaction && transaction.transaction_type == 'Payment for package') {
                return true
            } else {
                return false
            }
        },

        transaction() {
            return this.getTransactionByReceiptNo(this.mpesaCode)
        },
    },
    methods: {
        ...mapMutations(["setLoadingStatus"]),

        validatePhoneNumber() {
            const phoneNumber = this.mpesaPhoneNumber.trim();
            const isValidFormat = /^(01|07)\d{8}$/.test(phoneNumber);

            if (!isValidFormat) {
                this.showErrorNotification(
                    "Please enter a valid phone number starting with 01 or 07 and having 10 digits."
                );
                return false;
            }

            return true;
        },

        // Mpesa payment
        async initiateSTKPush() {
            this.setLoadingStatus(true);

            if (!this.validatePhoneNumber()) {
                this.setLoadingStatus(false);
                return;
            }

            try {
                const phoneNumber = "254" + this.mpesaPhoneNumber.slice(1);
                this.mpesaPushResponse = []

                const data = {
                    phone_number: phoneNumber,
                    amount: this.totalPrice,
                };

                await axios
                    .post("/mpesa/stk-push/", data)
                    .then((response) => {
                        const pushResponse = response.data;

                        if (response.data.success) {
                            this.showSuccessNotification("STK push success,Check phone");
                            this.showSuccessNotification(
                                "Transaction window will last 15 seconds"
                            );
                        } else {
                            this.showErrorNotification("STK push failed");
                        }

                        this.mpesaPushResponse.push({
                            MerchantRequestID: pushResponse.data.MerchantRequestID,
                            CheckoutRequestID: pushResponse.data.CheckoutRequestID,
                        });

                    })
                    .catch((error) => {
                        this.showErrorNotification(
                            "Error initiating STK push. Please check your connection and try again!"
                        );
                    });
            } catch (error) {

                this.$toast.error(
                    "Something went wrong. Please try again!",
                    {
                        position: "bottom-right",
                        duration: 3000,
                    }
                )
            }

            setTimeout(async () => {
                await this.verifyPayment();
            }, 15000);
        },

        addZero(num) {
            return num >= 0 && num < 10 ? "0" + num : num + "";
        },

        //payment via wallet
        async payViaWallet() {
            this.setLoadingStatus(true)

            const walletBalance = this.customerDetails.wallet_amount

            if (walletBalance >= this.totalPrice) {
                await axios
                    .put(`/packages/${this.parcelDetails.id}/`, {
                        payment_status: "PAID",
                        transaction_id: "Paid via wallet",
                    })
                    .then(async (response) => {
                        // const newWalletBalance = walletBalance - this.totalPrice;

                        if (this.parcelDetails.parcel_type == "CASH ON DELIVERY") {
                            const newCODBalance = this.customerDetails.COD_balance + +this.parcelDetails.parcel_value;

                            await this.updateCODBalance(newCODBalance);
                        }

                        await axios
                            .post(`/customerwallet/update/`, {
                                customer_id: this.customerDetails.customer_id,
                                total_price: this.totalPrice,
                            })
                            .then((response) => {
                                this.$store.state.customerLastFetched = null
                                this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)

                                this.showSuccessNotification("Payment successful!");

                            })
                            .catch((error) => {
                                this.showErrorNotification("Something went wrong!");
                            })
                        
                        this.$store.state.parcelsLastFetched = null
                        this.$store.dispatch('fetchParcels')

                        this.$router.push("/my-parcels");
                    })
                    .catch((error) => {
                        this.showErrorNotification("Error updating payment status");
                    });
            } else {
                this.showErrorNotification("Insufficient wallet balance");
            }

            this.setLoadingStatus(false)
        },

        // Payment verification (stk-push)
        async verifyPayment() {
            await axios
                .get("/mpesa/callback/")
                .then(async (responses) => {
                    const callbackResponses = responses.data.responses;
                    for (const x in callbackResponses) {
                        const confirmMerchantRequestID =
                            callbackResponses[x].body.Body.stkCallback.MerchantRequestID;
                        const confirmCheckoutRequestID =
                            callbackResponses[x].body.Body.stkCallback.CheckoutRequestID;

                        if (
                            confirmMerchantRequestID ===
                            this.mpesaPushResponse[0].MerchantRequestID &&
                            confirmCheckoutRequestID ===
                            this.mpesaPushResponse[0].CheckoutRequestID
                        ) {
                            const stkCallback = callbackResponses[x].body.Body.stkCallback;

                            if (stkCallback.ResultCode === 0) {
                                if (
                                    stkCallback.CallbackMetadata.Item[0].Value === this.totalPrice
                                ) {
                                    this.showSuccessNotification("Payment successful");
                                    
                                    await this.updatePackage(stkCallback.CallbackMetadata.Item[1].Value)
                                    
                                } else {
                                    this.showErrorNotification(
                                        "Payment amount does not match invoice amount"
                                    );
                                }
                            } else {
                                this.showErrorNotification(
                                    "Payment failed due to cancellation"
                                );
                            }
                        } else {
                            this.$store.state.transactionsLastFetched = null
                            this.$store.dispatch('fetchTransactions');
                        }
                    }

                    this.setLoadingStatus(false);
                })
                .catch((error) => {

                    this.showErrorNotification("Error verifying payment");
                    this.setLoadingStatus(false);
                });
        },

        // confirm Mpesa code
        async confirmCode() {
            this.setLoadingStatus(true);

            if (this.validTransaction) {
                if (this.usedTransactionCode) {
                    this.showErrorNotification("Code has already been used")
                } else {
                    
                    if (this.transaction.amount == this.totalPrice) {
                        await this.updatePackage(this.mpesaCode);
                    } else {
                        this.showErrorNotification("Amount paid does not match invoice amount");
                    }

                }
            } else {
                this.showErrorNotification("Invalid transaction code");
            }

            this.setLoadingStatus(false);
        },

        // Update package to paid
        async updatePackage(mpesaCode) {
            this.setLoadingStatus(true);

            await axios
                .put(`/packages/${this.parcelDetails.id}/`, {
                    payment_status: "PAID",
                    transaction_id: mpesaCode,
                })
                .then(async (response) => {

                    this.showSuccessNotification("Package successfully updated!")

                    this.$store.state.parcelsLastFetched = null
                    this.$store.dispatch('fetchParcels')

                    this.$router.push("/my-parcels");
                })
                .catch((error) => {
                    this.showErrorNotification("Error updating payment status");
                });

            this.setLoadingStatus(false);
        },

        async updateCODBalance(newBalance) {
            const data = {
                customer_id: this.customerDetails.customer_id,
                CODbalance: newBalance
            }

            await axios
                .put(`/customercodbalance/update/`, data)
                .then((response) => {
                    // this.showSuccessNotification("COD balance successfully updated")
                    this.$store.state.customerLastFetched = null
                    this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                })
                .catch((error) => {
                    this.showErrorNotification("Something went wrong. Please try again!")
                })
        },

        // cancel order
        async cancelOrder() {
            this.setLoadingStatus(true);

            let confirmMessage = ''

            /**
             * Check the message and adjust the message accordingly.
             * ? if parcel is still ready and unpaid:
             *      * message: Are you sure?
             * ? else if parcel ready and paid:
             *      * message: Are you sure? Money will be refunded to your wallet.
             * ? else if not received by recipient:
             *      * message: Are you sure? non-refundable process
             */

            if (this.parcelDetails.status === "PACKAGE CREATED" && this.parcelDetails.payment_status === "UNPAID") {
                confirmMessage = `Are you sure you want to cancel order ${this.parcelDetails.invoice_id}?`
            } else if (this.parcelDetails.status == 'PACKAGE CREATED' && this.parcelDetails.payment_status == 'PAID') {
                confirmMessage = `Are you sure you want to cancel order ${this.parcelDetails.invoice_id}? Money will be refunded to your wallet.`
            } else if (this.parcelDetails.status !== 'RECEIVED BY RECIPIENT' && this.parcelDetails.payment_status == 'PAID') {
                confirmMessage = `Are you sure you want to cancel order ${this.parcelDetails.invoice_id}? This is a non-refundable process.`
            }

            if (confirm(confirmMessage)) {
                await axios
                    .put(`/packages/${this.parcelDetails.id}/`, {
                        status: "PACKAGE CANCELLED",
                    })
                    .then(async (response) => {
                        if (this.parcelDetails.status == 'PACKAGE CREATED' && this.parcelDetails.payment_status == 'PAID') {
                            await this.refundWallet()
                        }

                        this.showSuccessNotification("Order successfully cancelled!");
    
                        this.$store.state.parcelsLastFetched = null
                        this.$store.dispatch('fetchParcels')
                        
                        this.$store.state.customerLastFetched = null
                        this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
    
                        this.$router.push("/my-parcels");
                    })
                    .catch((error) => {
                        this.showErrorNotification("Error cancelling order");
                    });
            }


            this.setLoadingStatus(false);
        },

        // refund to wallet
        async refundWallet() {
            this.setLoadingStatus(true)

            const data = {
                customer_id: this.customerDetails.customer_id,
                amount: this.totalPrice
            }

            await axios
                .post(`customerwithdraw/wallet/`, data)
                .then((response) => {
                })
                .catch((error) => {
                    this.$toast.error(
                        "Something went wrong. Please try again!",
                        {
                            duration: 3000,
                            position: 'bottom-right'
                        }
                    )
                })

            this.setLoadingStatus(false)
        },

        showSuccessNotification(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                duration: 4000,
            });
        },
        showErrorNotification(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                duration: 3000,
            });
        },
    },
};
</script>
