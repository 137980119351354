<template>
    <nav class="bg-white border-gray-200">
        <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
            <!-- Logo -->
            <router-link to="/users/driver/" class="flex">
                <span class="self-center text-2xl font-semibold whitespace-nowrap hover:text-orange-400">
                    <img src="../../../assets/long-logo.png" alt="Timely Delivery" class="h-16 w-auto p-2">
                </span>
            </router-link>


            <button data-collapse-toggle="navbar-default" type="button"
                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                @click="openMobileMenu()">
                <span class="sr-only">Open main menu</span>
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 17 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 1h15M1 7h15M1 13h15" />
                </svg>
            </button>

            <!-- mobile menu -->
            <div v-if="isMobileMenuOpen"
                class="right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <ul
                    class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
                    <li>
                        <router-link to="/users"
                            class="block py-2 px-3 text-gray-900 rounded md:bg-transparent md:text-blue-700 md:p-0"
                            :class="activeTab == '' || activeTab == 'driver' ? 'bg-blue-700 text-white' : ''">Dashboard</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/pending"
                            class="block py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'pending' ? 'bg-blue-700 text-white' : ''">Pending</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/in-transit"
                            class="block py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'completed' ? 'bg-blue-700 text-white' : ''">In Transit</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/completed"
                            class="block py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'completed' ? 'bg-blue-700 text-white' : ''">Completed</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/return"
                            class="block py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'return' ? 'bg-blue-700 text-white' : ''">Return</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver"
                            class="block py-2 px-3 text-gray-900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'myProfile' ? 'bg-blue-700 text-white' : ''">{{ driverDetails.first_name }} {{ driverDetails.last_name }}</router-link>
                    </li>
                </ul>
            </div>

            <!-- Menu -->
            <div class="hidden w-full md:block md:w-auto">
                <ul
                    class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
                    <li>
                        <router-link to="/users/driver"
                            class="block py-2 px-3 text-gray-900 bg-blue-700 rounded md:bg-transparent md:p-0 hover:text-blue-700"
                            :class="activeTab == '' ? 'text-blue-700' : ''">Dashboard</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/pending"
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'pending' ? 'text-blue-700' : ''">Pending</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/in-transit"
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'completed' ? 'text-blue-700' : ''">In Transit</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/completed"
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'completed' ? 'text-blue-700' : ''">Completed</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver/return"
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'return' ? 'text-blue-700' : ''">Return</router-link>
                    </li>
                    <li>
                        <router-link to="/users/driver"
                            class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
                            :class="activeTab == 'myProfile' ? 'text-blue-700' : ''">{{ driverDetails.first_name }} {{ driverDetails.last_name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "DriverNavbar",
    data() {
        return {
            isMobileMenuOpen: false,
            // activeTab: 'dashboard'
        }
    },
    computed: {
        ...mapGetters(['getDriverDetails']),

        driverDetails() {
            return this.getDriverDetails
        },

        activeTab() {
            const path = this.$route.path;
            const parts = path.split('/');
            return parts[parts.length - 1];
        }
    },
    methods: {
        openMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen
        },


    },
}
</script>
