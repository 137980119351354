import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
import App from '@/App.vue'

//* Admin dashboard
import AdminLogin from '@/admin/views/AdminLogin.vue'

import Admin from '@/admin/Admin.vue'
import adminLayout from "@/admin/components/AdminLayout.vue";
import AppLayout from '@/components/layout/AppLayout.vue'
import adminDashboard from '@/admin/views/Dashboard.vue'
import Transactions from '@/admin/views/Transactions.vue'

import Agents from '@/admin/views/agents/Agents.vue'
import NewAgent from '@/admin/views/agents/NewAgent.vue'
import AgentPage from '@/admin/views/agents/AgentPage.vue'
import AgentsReport from '@/admin/views/agents/AgentsReport.vue'

import Riders from '@/admin/views/drivers/Riders.vue'
import NewRider from '@/admin/views/drivers/NewRider.vue'
import RiderPage from '@/admin/views/drivers/RiderPage.vue'

import Collectors from '@/admin/views/collectors/Collectors.vue'
import NewCollector from '@/admin/views/collectors/NewCollector.vue'
import CollectorPage from '@/admin/views/collectors/CollectorPage.vue'

import Orders from '@/admin/views/orders/Orders.vue'
import OrderPage from '@/admin/views/orders/OrderPage.vue'
import CODOrders from '@/admin/views/orders/CODOrders.vue'

import Customers from '@/admin/views/customers/Customers.vue'
import CustomerPage from '@/admin/views/customers/CustomerPage.vue'
import CustomersCodReport from '@/admin/views/customers/CustomersCodReport.vue'

import Services from '@/admin/views/Services.vue'

//* Basic layout
import Homepage from '../views/Homepage.vue'
import Form from '../components/layout/Form.vue'

//* Login pages
import Login from '../views/Login.vue'
import ForgotPassword from '../views/ForgotPassword.vue'

//* Registration pages
import Register from '../views/Register.vue'
import PersonalInfo from '../views/register-individual/PersonalInfo.vue'
import ContactInfo from '../views/register-individual/ContactInfo.vue'
import CompleteProfile from '../views/register-individual/CompleteProfile.vue'
import CompanyInfo from '../views/register-business/CompanyInfo.vue'
import PasswordSet from '../views/register-business/PasswordSet.vue'
import EmailVerification from '@/views/EmailVerification.vue'

//* Services components
import ServiceForm from '@/components/layout/ServiceForm.vue'
import ModeSelection from '@/components/ModeSelection.vue'
import ParcelDetails from '../components/ParcelDetails.vue'
import PickUpDetails from '@/views/cbd-to-cbd/PickUpDetails.vue'
import ParcelDropOff from '@/components/ParcelDropOff.vue'
import Invoice from '@/components/Invoice.vue'

import PageNotFound from '@/views/PageNotFound.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
import RefundPolicy from '@/views/RefundPolicy.vue'
import FAQ from '@/views/FAQ.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import myParcels from '@/views/MyParcels.vue'
import MyProfile from '@/views/MyProfile.vue'


// * Users (Agents | Drivers | Collectors) portal
import Users from '@/users/Users.vue'
import UserLogin from '@/users/views/UserLogin.vue'

import AgentsLayout from '@/users/components/layout/AgentsLayout.vue'
import AgentsDashboard from '@/users/views/agent/Dashboard.vue'
import Incoming from '@/users/views/agent/Incoming.vue'
import Pending from '@/users/views/agent/Pending.vue'
import Completed from '@/users/views/agent/Completed.vue'
import Return from '@/users/views/agent/Return.vue'
import Report from '@/users/views/agent/Report.vue'

import CollectorLayout from '@/users/components/layout/CollectorLayout.vue'
import CollectorDashboard from '@/users/views/collector/Dashboard.vue'
import CollectorPending from '@/users/views/collector/Pending.vue'
import Collected from '@/users/views/collector/Collected.vue'
import CollectorCompleted from '@/users/views/collector/Completed.vue'

import DriverLayout from '@/users/components/layout/DriverLayout.vue'
import DriverDashboard from '@/users/views/driver/Dashboard.vue'
import DriverPending from '@/users/views/driver/Pending.vue'
import InTransit from '@/users/views/driver/InTransit.vue'
import DriverCompleted from '@/users/views/driver/Completed.vue'
import DriverReturn from '@/users/views/driver/Return.vue'

import Order from '@/users/components/Order.vue'
import CollectorOrder from '@/users/components/CollectorOrder.vue'
import DriverOrder from '@/users/components/DriverOrder.vue'
import MyWallet from '@/views/MyWallet.vue'
import Withdrawals from '@/admin/views/Withdrawals.vue'

import ResetPassword from '@/views/ResetPassword.vue';
import OurAgents from '@/views/OurAgents.vue';
import AboutUs from '@/views/AboutUs.vue'

const routes = [
  // * Customer side
  {
    path: '/',
    component: App,
    children: [
      {
        path: '',
        name: 'MainApp',
        component: AppLayout,
        children: [
          {
            path: '',
            name: 'Home',
            component: Homepage
          },
          {
            path: '/about-us',
            name: 'AboutUs',
            component: AboutUs
          },
          {
            path: '/register',
            name: 'Form',
            component: Form,
            children: [
              {
                path: '',
                name: 'Register',
                component: Register
              },
              {
                path: 'i/step-1',
                component: PersonalInfo
              },
              {
                path: 'i/step-2',
                component: ContactInfo
              },
              {
                path: 'i/step-3',
                component: CompleteProfile
              },
              {
                path: 'b/step-1',
                component: CompanyInfo
              },
              {
                path: 'b/step-2',
                component: PasswordSet
              },
              {
                path: '/register/email-verification',
                component: EmailVerification
              }
            ]
          },
          {
            path: '/login',
            component: Form,
            children: [
              {
                path: '',
                name: 'Login',
                component: Login
              },
              {
                path: 'forgot-password',
                name: 'forgot-password',
                component: ForgotPassword
              },
              {
                path: '/reset-password',
                name: 'ResetPassword',
                component: ResetPassword,
              },
            ]
          },
          {
            path: '/our-agents',
            name: 'OurAgents',
            component: OurAgents,
          },
          {
            path: '/cbd-to-cbd',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'CBD TO CBD'
            },
            children: [
              {
                path: '',
                name: 'cbd-to-cbd',
                component: PickUpDetails
              },
              {
                path: 'parcel-details',
                component: ParcelDetails,
                
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/cbd-to-mtaani',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'CBD TO MTAANI'
            },
            children: [
              {
                path: '',
                name: 'cbd-to-mtaani',
                component: ModeSelection
              },
              {
                path: 'parcel-details',
                component: ParcelDetails
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/mtaani-to-cbd',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'MTAANI TO CBD'
            },
            children: [
              {
                path: '',
                name: 'mtaani-to-cbd',
                component: ParcelDropOff
              },
              {
                path: 'parcel-Details',
                component: ParcelDetails
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
        
            ]
          },
          {
            path: '/mtaani-to-mtaani',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'MTAANI TO MTAANI'
            },
            children: [
              {
                path: '',
                name: 'mtaani-to-mtaani',
                component: ParcelDropOff
              },
              {
                path: 'parcel-Details',
                component: ParcelDetails
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/mtaani-to-upcountry',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'MTAANI TO UPCOUNTRY'
            },
            children: [
              {
                path: '',
                name: 'mtaani-to-upcountry',
                component: ParcelDropOff
              },
              {
                path: 'parcel-Details',
                component: ParcelDetails
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/cbd-to-upcountry',
            component: ServiceForm,
            meta: {
              requireLogin: true,
              service: 'CBD TO UPCOUNTRY'
            },
            children: [
              {
                path: '',
                name: 'cbd-to-upcountry',
                component: ModeSelection
              },
              {
                path: 'parcel-Details',
                component: ParcelDetails
              },
              {
                path: 'parcel-details/invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/terms-and-conditions',
            component: TermsAndConditions,
            name: 'terms-and-conditions'
          },
          {
            path: '/refundpolicy',
            component: RefundPolicy
          },
          {
            path: '/faq',
            component: FAQ
          },
          {
            path: '/privacy-policy',
            component: PrivacyPolicy
          },
          {
            path: '/create-agent',
            component: NewAgent
          },
          {
          path: '/:pathMatch(.*)*',
          name: 'PageNotFound',
          component: PageNotFound
          },
          {
            path: '/my-parcels',
            component: ServiceForm,
            meta: {
              requireLogin: true
            },
            children: [
              {
                path: '',
                name: 'my-parcels',
                component: myParcels
              },
              {
                path: 'invoice/:id',
                component: Invoice
              }
            ]
          },
          {
            path: '/my-profile',
            component: MyProfile,
            meta: {
              requireLogin: true
            }
          },
          {
            path: '/my-wallet',
            component: MyWallet,
            meta: {
              requireLogin: true
            }
          },

        ]
      },

    ]
  },

  // * Admin dashboard
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'adminLayout',
        component: adminLayout,
        meta: {
          requireAdminLogin: true
        },
        beforeEnter(to, from, next) {
          store.dispatch('fetchServices')
          store.dispatch('fetchDeliveryTimes')
          store.dispatch('fetchZones')
          store.dispatch('fetchAllParcels')
          store.dispatch('fetchTransactions')
          store.dispatch('fetchPackageStatuses').then(() => next())
        },
        children: [
          {
            path: '',
            name: 'adminDashboard',
            component: adminDashboard
          },
          {
            path: '/admin/agents',
            component: Agents,
          },
          {
            path: '/admin/agents/new',
            component: NewAgent,
          },
          {
            path: '/admin/agents/:id',
            component: AgentPage,
          },
          {
            path: '/admin/riders',
            component: Riders
          },
          {
            path: '/admin/riders/new',
            component: NewRider
          },
          {
            path: '/admin/riders/:id',
            component: RiderPage
          },
          {
            path: '/admin/collectors',
            component: Collectors
          },
          {
            path: '/admin/collectors/new',
            component: NewCollector
          },
          {
            path: '/admin/collectors/:id',
            component: CollectorPage
          },
          {
            path: '/admin/orders',
            component: Orders
          },
          {
            path: '/admin/cod-orders',
            component: CODOrders
          },
          {
            path: '/admin/orders/:id',
            name: OrderPage,
            component: OrderPage
          },
          {
            path: '/admin/transactions',
            component: Transactions
          },
          {
            path: '/admin/customers',
            component: Customers
          },
          {
            path: '/admin/customers/:id',
            component: CustomerPage
          },
          {
            path: '/admin/services',
            name: 'Services',
            component: Services
          },
          {
            path: '/admin/report/agents',
            name: 'AgentsReport',
            component: AgentsReport
          },
          {
            path: '/admin/report/cod-customers-report',
            name: 'COD-Customers',
            component: CustomersCodReport
          },
          {
            path: '/admin/requests',
            name: 'Withdrawal_requests',
            component: Withdrawals
          },
        ]
      },
      {
        path: '/adminLogin',
        component: AdminLogin
      }
    ]
  },

  // * Users portals (Agents | Drivers | Collectors)
  {
    path: '/users',
    component: Users,
    children: [
      {
        path: '',
        name: 'UserLogin',
        component: UserLogin
      },
      {
        path: '/users/agent',
        name: 'AgentsPortal',
        component: AgentsLayout,
        meta: {
          requireUserLogin: false
        },
        beforeEnter(to, from, next) {
          store.dispatch('fetchParcels').then(() => next())
        },
        children: [
          {
            path: '',
            name: 'AgentsDashboard',
            component: AgentsDashboard
          },
          {
            path: '/users/agent/incoming',
            name: 'AgentsIncoming',
            component: Incoming
          },
          {
            path: '/users/agent/incoming/:id',
            name: 'IncomingOrder',
            component: Order
          },
          {
            path: '/users/agent/pending',
            name: 'Pending',
            component: Pending
          },
          {
            path: '/users/agent/pending/:id',
            name: 'PendingOrder',
            component: Order
          },
          {
            path: '/users/agent/completed',
            name: 'Completed',
            component: Completed
          },
          {
            path: '/users/agent/completed/:id',
            name: 'CompletedOrder',
            component: Order
          },
          {
            path: '/users/agent/return',
            name: 'Return',
            component: Return
          },
          {
            path: '/users/agent/return/:id',
            name: 'ReturnOrder',
            component: Order
          },
          {
            path: '/users/agent/report',
            name: 'AgentReport',
            component: Report
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'PageNotFound',
            component: PageNotFound
          },
        ]
      },
      {
        path: '/users/collector',
        name: 'CollectorPortal',
        component: CollectorLayout,
        beforeEnter(to, from, next) {
          store.dispatch('fetchParcels').then(() => next())
        },
        children: [
          {
            path: '',
            name: 'CollectorDashboard',
            component: CollectorDashboard
          },
          {
            path: '/users/collector/pending',
            name: 'CollectorPending',
            component: CollectorPending
          },
          {
            path: '/users/collector/pending/:id',
            name: 'PendingCollectionOrder',
            component: CollectorOrder
          },
          {
            path: '/users/collector/collected',
            name: 'Collected',
            component: Collected
          },
          {
            path: '/users/collector/collected/:id',
            name: 'CollectedOrder',
            component: CollectorOrder
          },
          {
            path: '/users/collector/completed',
            name: 'CollectorCompleted',
            component: CollectorCompleted
          },
          {
            path: '/users/collector/completed/:id',
            name: 'CollectorCompletedOrder',
            component: CollectorOrder
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'PageNotFound',
            component: PageNotFound
          },
        ]
      },
      {
        path: '/users/driver',
        name: 'DriverPortal',
        component: DriverLayout,
        beforeEnter(to, from, next) {
          store.dispatch('fetchParcels').then(() => next())
        },
        children: [
          {
            path: '',
            name: 'DriverDashboard',
            component: DriverDashboard
          },
          {
            path: '/users/driver/pending',
            name: 'DriverPending',
            component: DriverPending
          },
          {
            path: '/users/driver/pending/:id',
            name: 'PendingDriverOrder',
            component: DriverOrder
          },
          {
            path: '/users/driver/in-transit',
            name: 'InTransit',
            component: InTransit
          },
          {
            path: '/users/driver/in-transit/:id',
            name: 'InTransitOrder',
            component: DriverOrder
          },
          {
            path: '/users/driver/completed',
            name: 'DriverCompleted',
            component: DriverCompleted
          },
          {
            path: '/users/driver/completed/:id',
            name: 'DriverCompletedOrder',
            component: DriverOrder
          },
          {
            path: '/users/driver/return',
            name: 'DriverReturn',
            component: DriverReturn
          },
          {
            path: '/users/driver/return/:id',
            name: 'DriverReturnOrder',
            component: DriverOrder
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'PageNotFound',
            component: PageNotFound
          },
        ]
      },
    ]
  },

  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: 'smooth'
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.path.startsWith('/admin')) && to.matched.some(record => record.meta.requireAdminLogin) && !store.state.isAdminAuthenticated ) {
    next('/adminLogin')
  } else if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/login')
  } else if (to.matched.some(record => record.path.startsWith('/users')) && to.matched.some(record => record.meta.requireUserLogin) && !store.state.isUserAuthenticated ) {
    next('/users')
  } else {
    if (to.matched.some(record => record.meta.service)) {
      const route_type = to.meta.service
  
      store.commit('setNewParcelDetails', {route_type})

      localStorage.setItem('route_type', to.meta.service)

    }

    next()
  }
})

export default router
