import axios from "axios";

import { populateTemplate } from "./utils";

const API_URL = process.env.VUE_APP_SMS_URL
const API_KEY = process.env.VUE_APP_SMS_API_KEY
const SHORTCODE = process.env.VUE_APP_SMS_SHORTCODE

export const sendMessage = async (phoneNumber, messageTemplate, templateData) => {
    try {
        const populatedMessage = populateTemplate(messageTemplate, templateData)
        
        await axios
            .post(API_URL, 
                {
                    shortCode: SHORTCODE,
                    message: populatedMessage,
                    to: "+254" + phoneNumber
                },
                {
                    headers: {
                        apiKey: API_KEY
                    }
                }
            )
            .then((response) => {
            })
            .catch((error) => {
                throw error
            })


    } catch (error) {
    }
}