<template>
    <div class=" my-3 mx-8 md:mx-24 items-center">
        <form @submit.prevent="updateDetails" class="mb-8 mx-8 md:mx-24 w-auto">
            <div class="mb-12">
                <h1 class="text-base font-bold leading-relaxed tracking-light text-gray-900 md:text-2xl">My Profile</h1>
            </div>

            <!-- Name -->
            <div>
                <div v-if="customerDetails.account_type === 'INDIVIDUAL'" class="grid md:grid-cols-2 md:gap-6">
                    <!-- First Name -->
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="first_name" id="first_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                            placeholder=" " v-model="firstName" disabled />

                        <label for="first_name"
                            class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First
                            name</label>

                    </div>

                    <!-- Last Name -->
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="lastName" id="lastName"
                            class="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                            placeholder=" " v-model="lastName" disabled />
                        <label for="lastName"
                            class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last
                            name</label>
                    </div>

                </div>

                <div v-else>
                    <div class="relative z-0 w-full mb-5 group">
                        <input type="text" name="first_name" id="first_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                            placeholder=" " v-model="name" disabled required />

                        <label for="first_name"
                            class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Organization
                            name</label>

                    </div>
                </div>

            </div>

            <!-- Email -->
            <div class="relative z-0 w-full mb-5 group">
                <input type="email" name="email" id="email"
                    class="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model="email" disabled required />
                <label for="email"
                    class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
            </div>

            <!-- Phone Number -->
            <div class="relative z-0 w-full mb-5 group">
                <input type="tel" name="phoneNumber" id="phoneNumber"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model="phoneNumber" required />
                <label for="phoneNumber"
                    class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone
                    Number</label>
            </div>

            <!-- Location -->
            <div class="relative z-0 w-full mb-5 group">
                <input type="text" name="location" id="location"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model="location" required />
                <label for="location"
                    class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Location</label>
            </div>

            <!-- Building -->
            <div class="relative z-0 w-full mb-5 group">
                <input type="text" name="building" id="building"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model="building" required />
                <label for="building"
                    class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Building</label>
            </div>

            <!-- Shop Number -->
            <div class="relative z-0 w-full mb-5 group">
                <input type="text" name="shop_no" id="shop_no"
                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                    placeholder=" " v-model="shop_no" required />
                <label for="shop_no"
                    class="peer-focus:font-medium absolute text-md text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Shop
                    Number</label>
            </div>

            <!-- Submit button -->
            <button type="submit"
                class="block rounded-md bg-blue-500 px-3.5 py-2.5 text-center text-md font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600">
                <span v-if="!$store.state.isLoading">Update</span>
                <span v-else><svg aria-hidden="true" class="mx-3 w-5 h-5 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor" />
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill" />
                    </svg></span>

            </button>

        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'MyProfile',
    data() {
        return {
            customerID: localStorage.getItem('userid'),
            userDetails: [],
            name: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            location: '',
            account_type: '',
            building: '',
            shop_no: '',
            messages: []
        }
    },
    async created() {

        await this.getDetails()

    },
    computed: {
        ...mapGetters(['getCustomerDetails']),

        customerDetails() {
            return this.getCustomerDetails
        }
    },
    methods: {
        ...mapMutations(['setLoadingStatus']),

        async getDetails() {
            this.setLoadingStatus(true)

            await axios
                .get('/customers/' + this.customerDetails.customer_id)
                .then((response) => {
                    const user = response.data

                    this.userDetails = user

                    this.account_type = user.account_type

                    if (user.account_type === 'INDIVIDUAL') {
                        this.firstName = user.first_name
                        this.lastName = user.last_name
                    } else {
                        this.name = user.first_name + ' ' + user.last_name
                    }

                    this.email = user.email
                    this.phoneNumber = user.phone_number
                    this.location = user.location
                    this.building = user.building
                    this.shop_no = user.shop_number

                })
                .catch((error) => {
                    // console.log("Error fetching user details: ", error)
                    this.showErrorNotification("Error fetching details. Please check your internet connection and try again!")
                })

            this.setLoadingStatus(false)
        },

        async updateDetails() {
            this.setLoadingStatus(true)

            // update phone number
            if (this.phoneNumber != this.customerDetails.phone_number) {
                const data = {
                    customer_id: this.customerDetails.customer_id,
                    phone_number: this.phoneNumber
                }

                await axios
                    .put('/customerphonenumber/update/ ', data)
                    .then((response) => {

                        if (response.status === 200) {
                            this.showSuccessNotification("Phone number successfully updated.")
                        } else {
                            this.showErrorNotification("Something went wrong. Please try again!")
                        }

                        this.$store.state.customerLastFetched = null
                        this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                    })
                    .catch(error => {
                        this.showErrorNotification("Error updating phone number. Please try again later!")

                    })
            }

            // update location
            if (this.location != this.customerDetails.location) {
                const data = {
                    customer_id: this.customerDetails.customer_id,
                    location: this.location
                }

                await axios
                    .put(`/customerlocation/update/`, data)
                    .then((response) => {

                        if (response.status === 200) {
                            this.showSuccessNotification("Location successfully updated.")
                        } else {
                            this.showErrorNotification("Something went wrong. Please try again!")
                        }

                        this.$store.state.customerLastFetched = null
                        this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                    })
                    .catch(error => {
                        this.showErrorNotification("Error updating location. Please try again later!")

                    })
            }

            // update building
            if (this.building != this.customerDetails.building) {
                const data = {
                    customer_id: this.customerDetails.customer_id,
                    building: this.building
                }

                await axios
                    .put(`/customerbuilding/update/`, data)
                    .then((response) => {

                        if (response.status === 200) {
                            this.showSuccessNotification("Building successfully updated.")
                        } else {
                            this.showErrorNotification("Something went wrong. Please try again!")
                        }

                        this.$store.state.customerLastFetched = null
                        this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                    })
                    .catch(error => {
                        this.showErrorNotification("Error updating building. Please try again later!")

                    })
            }

            // update shop number
            if (this.shop_no != this.customerDetails.shop_number) {
                const data = {
                    customer_id: this.customerDetails.customer_id,
                    shop_number: this.shop_no
                }

                await axios
                    .put(`/customershopnumber/update/`, data)
                    .then((response) => {
                        
                        if (response.status === 200) {
                            this.showSuccessNotification("Shop number successfully updated.")
                        } else {
                            this.showErrorNotification("Something went wrong. Please try again!")
                        }

                        this.$store.state.customerLastFetched = null
                        this.$store.dispatch('fetchCustomerDetails', this.customerDetails.customer_id)
                    })
                    .catch(error => {
                        this.showErrorNotification("Error updating shop number. Please try again later!")
                    })
            }

            this.$router.push('/my-profile')
            this.setLoadingStatus(false)
        },
        showSuccessNotification(message) {
            this.$toast.success(message, {
                position: 'bottom-right',
                duration: 3000,
            });
        },
        showErrorNotification(message) {
            this.$toast.error(message, {
                position: 'bottom-right',
                duration: 3000,
            });


        }
    }
}

</script>