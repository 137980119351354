<template>
    <div class="container mx-auto py-8">
        <!-- header (Back button & Order ID) -->
        <div class="flex justify-between my-3 mx-8 md:mx-24 items-center">
            <div>
                <button @click="$router.back()"
                    class="flex gap-1 text-gray-500 text-sm font-medium tracking-normal items-center hover:text-md hover:text-blue-500">

                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.956 19.7361C13.2392 20.0152 13.6951 20.0118 13.9742 19.7285C14.2533 19.4453 14.2499 18.9894 13.9667 18.7103L10.0999 14.9004H19.9433C20.341 14.9004 20.6633 14.5781 20.6633 14.1804C20.6633 13.7828 20.341 13.4604 19.9433 13.4604H10.1047L13.9667 9.65525C14.2499 9.37616 14.2533 8.92029 13.9742 8.63703C13.6951 8.35378 13.2392 8.3504 12.956 8.62949L7.97049 13.5417C7.6127 13.8942 7.6127 14.4714 7.97049 14.8239L12.956 19.7361Z"
                            fill="currentColor" />
                    </svg>

                    <span>Back</span>
                </button>

            </div>

            <div>
                <div class="text-gray-500 font-semibold tracking-normal text-right text-base">Customer ID:</div>
                <div class="text-gray-600 font-bold tracking-normal text-right text-base">{{ id }}</div>
            </div>

        </div>

        <!-- customer details -->

        <!-- Loading indicator -->
        <div v-if="$store.state.isLoading">
            <div>
                <div>
                    <div class="flex items-center justify-center px-6 py-3">
                        <div class="flex justify-center items-center">
                            <div class="mr-2">Loading...</div>
                            <LoadingIcon />

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="customer-details" v-else-if="customerDetails">
            <!-- customer details -->
            <div>
                <h2 class="font-bold text-2xl leading-7 mx-24 mb-5">Customer Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- Name -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Name</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.first_name }} {{ customerDetails.last_name }}
                            </dd>
                        </div>

                        <!-- Email -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Email</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.email }}
                            </dd>
                        </div>

                        <!-- Phone Number -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                customerDetails.phone_number }}</dd>
                        </div>

                        <div class="py-2"></div>

                        <!-- Account Type -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Account Type</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                customerDetails.account_type }}</dd>
                        </div>

                        <!-- Registered Date -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Registered Date</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                customerDetails.date_joined.split(' ')[0] }}</dd>
                        </div>

                        <div class="py-2"></div>

                        <!-- Location -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Location</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.location
                                }}</dd>
                        </div>

                        <!-- Building -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Building</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ customerDetails.building
                                }}</dd>
                        </div>

                        <!-- Shop Number -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Shop Number</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                customerDetails.shop_number }}</dd>
                        </div>

                        <div class="py-2"></div>

                        <!-- Wallet Amount -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Wallet Amount</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{
                                customerDetails.wallet_amount }}</dd>
                        </div>

                    </dl>
                </div>

            </div>

            <!-- Order details (stats) -->
            <div>
                <h2 class="font-bold text-2xl leading-7 mx-24 mt-12">Order Details:</h2>

                <div class="mt-6 border-y border-gray-100 mx-5 md:mx-24">
                    <dl class="divide-y divide-gray-100">
                        <!-- pending payment -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Pending Payment</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ pendingOrders.length }}</dd>
                        </div> 
                        
                        <!-- paid -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Paid</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{
                                paidOrders.length }}</dd>
                        </div>

                        <div class="py-2"></div>

                        <!-- Pending delivery -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Pending Delivery</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ pendingDeliveryOrders.length }}</dd>
                        </div> 
                        
                        <!-- Successful -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Delivered</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ deliveredOrders.length }}</dd>
                        </div> 
                        
                        <!-- COD orders-->
                        <div v-if="customerDetails.cod_orders" class="divide-y divide-gray-100">
                            <h2 class="font-bold text-2xl leading-7 pt-8 pb-5">COD Details:</h2>

                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Total Orders</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ codOrders.length }}</dd>
                            </div> 
    
                            <div class="py-2"></div>
                            
                            <!-- Pending COD Orders -->
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Pending Orders</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ pendingCodOrders.length }}</dd>
                            </div>
                            
                            <!-- Pending COD Orders value-->
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Pending value</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ customerDetails.COD_balance }}</dd>
                            </div>
    
                            <div class="py-2"></div>
                            
                            <!-- Paid COD Orders -->
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Paid COD Orders</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{ paidCodOrders.length }}</dd>
                            </div> 
                            <!-- Paid COD Orders value -->
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Paid COD Value</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ customerDetails.Total_earned }}</dd>
                            </div> 
    
                            <div class="py-2"></div>
                            
                            <!-- COD Value -->
                            <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt class="text-sm font-medium leading-6 text-gray-900">Total COD Value</dt>
                                <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ customerDetails.Total_earned + customerDetails.COD_balance }}</dd>
                            </div>
    
                            
                        </div>
                        
                        <div class="py-2"></div>

                        <!-- Total Orders -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Total Orders</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 font-bold">{{ customerParcels.length }}</dd>
                        </div>

                        <!-- Revenue -->
                        <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt class="text-sm font-medium leading-6 text-gray-900">Revenue</dt>
                            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Kshs. {{ totalRevenue }}</dd>
                        </div>

                    </dl>
                </div>

            </div>

        </div>

        <div v-else>
            <p class="items-center text-center font-semibold text-lg text-red-500">Error fetching customer details. Please reload page again!</p>
        </div>


        <div class="flex justify-end items-center mt-8 -mb-8">
            <div class="bg-blue-500 text-white text-sm font-medium rounded-lg px-4 py-2.5 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 "
                type="button" @click="downloadPDF">Download PDF</div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex';
import html2pdf from 'html2pdf.js'
import LoadingIcon from '@/components/LoadingIcon.vue';

    export default {
        name: 'CustomerPage',
        data() {
            return {
                id: this.$route.params.id,
            }
        },

        computed: {
            ...mapGetters(['getCustomerById', 'getParcelsByCustomerId']),

            customerDetails() {
                return this.getCustomerById(this.id)
            },

            customerParcels() {
                return this.getParcelsByCustomerId(this.id)
            },

            pendingOrders() {
                return this.customerParcels.filter(parcel => parcel.payment_status === 'UNPAID')
            },

            paidOrders() {
                return this.customerParcels.filter(parcel => parcel.payment_status === 'PAID')
            },

            pendingDeliveryOrders() {
                return this.paidOrders.filter(parcel => parcel.status !== 'RECEIVED BY RECIPIENT')
            },

            deliveredOrders() {
                return this.paidOrders.filter(parcel => parcel.status === 'RECEIVED BY RECIPIENT')
            },

            codOrders() {
                return this.paidOrders.filter(parcel => parcel.parcel_type === 'CASH ON DELIVERY')
            },

            pendingCodOrders() {
                return this.codOrders.filter(parcel => !parcel.COD_Confirmation)
            },
            
            paidCodOrders() {
                return this.codOrders.filter(parcel => parcel.COD_Confirmation)
            },

            totalRevenue() {
                let revenue = 0

                this.paidOrders.forEach(parcel => {
                    if (parcel.pickup_location) {
                        revenue += 50
                    }

                    if (parcel.route_type === "CBD TO CBD") {
                        revenue += 50
                    } else if (parcel.route_type === "CBD TO MTAANI") {
                        revenue += 100
                    } else if (parcel.route_type === "MTAANI TO CBD") {
                        revenue += 100
                    } else if (parcel.route_type === "MTAANI TO MTAANI") {
                        revenue += 150
                    } else if (parcel.route_type === "MTAANI TO UPCOUNTRY") {
                        revenue += 310
                    } else if (parcel.route_type === "CBD TO UPCOUNTRY") {
                        revenue += 280
                    }
                })

                return revenue
            }
        },

        components: {
            LoadingIcon,
        },
        methods: {
            ...mapMutations(['setLoadingStatus']),

            downloadPDF() {
                const element = document.getElementById('customer-details');
                const opt = {
                    margin: 1,
                    filename: `customer-${this.id}.pdf`,
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
                };

                html2pdf().set(opt).from(element).save();
            }
        }
    }
</script>